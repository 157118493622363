export const checkIfGameIsFinished = (gameResult: {
  team1?: number;
  team2?: number;
}) => {
  if (!gameResult.team1 || !gameResult.team2) {
    if (gameResult.team1 === 0 && gameResult.team2 === 0) {
      return true;
    }

    if (gameResult.team1 === 0 && gameResult.team2) {
      return true;
    }

    if (gameResult.team2 === 0 && gameResult.team1) {
      return true;
    }

    return false;
  } else return true;
};
