import {
  CalendarOutlined,
  DribbbleOutlined,
  EyeOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { DatePicker, Divider, Flex, Select, Tooltip } from 'antd';
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContext } from '../../../context/auth-context';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { SelectOptionsProps } from '../../../types/props.types';
import ButtonComponent from '../../../components/buttons/button.component';
import SelectGameType from '../../../components/select-game-type/select-game-type.component';

interface OpenGameFilterProps {
  filter: {
    date: string;
    gameTypeId: string;
    groupView: boolean;
    selectedGroup: string;
  };
  setFilter: Dispatch<
    SetStateAction<{
      date: string;
      gameTypeId: string;
      groupView: boolean;
      selectedGroup: string;
    }>
  >;
}

const OpenGameFilter: FC<OpenGameFilterProps> = (props) => {
  const { groupId, user } = useContext(AuthContext);
  const { t } = useTranslation('open-games');
  const { filter, setFilter } = props;
  const [groupOptions, setGroupOptions] = useState<SelectOptionsProps[]>([]);

  useEffect(() => {
    const options = user?.userGroups.map((ug) => {
      return { label: ug.groupName, value: ug.groupId };
    });
    if (options) {
      setGroupOptions(options);
    }
  }, [user]);

  const resetFilter = () => {
    props.setFilter({
      date: '',
      gameTypeId: '',
      groupView: false,
      selectedGroup: '',
    });
  };

  return (
    <>
      <Flex
        justify={groupId ? 'space-around' : 'start'}
        className='game-filter-container'
      >
        <div className='mt-1 game-filter-item'>
          <Flex gap={8}>
            <CalendarOutlined style={{ fontSize: '18px' }} />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              placeholder={t('common:date')}
              value={filter.date ? dayjs(filter.date, 'DD-MM-YYYY') : null}
              className='w-100'
              onChange={(date) =>
                date &&
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  date: date.format('DD-MM-YYYY'),
                }))
              }
              format={'DD.MM.YYYY'}
            />
          </Flex>
          <Flex gap={8} className='mt-1'>
            <DribbbleOutlined style={{ fontSize: '18px' }} />
            <SelectGameType
              value={filter.gameTypeId ? filter.gameTypeId : null}
              className='w-100'
              onChange={(selectedType) => {
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  gameTypeId: selectedType,
                }));
              }}
            />
          </Flex>
        </div>
        {groupId && (
          <div className='game-filter-item'>
            <Flex gap={8} className='mt-1'>
              <EyeOutlined style={{ fontSize: '18px' }} />
              <Select
                value={filter.groupView}
                className='w-100'
                options={[
                  { label: t('filter.group_ads').toUpperCase(), value: true },
                  { label: t('filter.global_ads').toUpperCase(), value: false },
                ]}
                onChange={(value) => {
                  if (!value) {
                    setFilter((prevFilterData) => ({
                      ...prevFilterData,
                      groupView: value,
                    }));
                  } else {
                    setFilter((prevFilterData) => ({
                      ...prevFilterData,
                      groupView: value,
                      selectedGroup: groupId,
                    }));
                  }
                }}
              />
            </Flex>
            <Flex gap={8} className='mt-1'>
              <TeamOutlined style={{ fontSize: '18px' }} />
              <Tooltip
                title={!filter.groupView ? t('filter.disabled_group') : ''}
              >
                <div className='w-100'>
                  {' '}
                  <Select
                    defaultValue={
                      user?.userGroups.find((ug) => ug.groupId === groupId)
                        ?.groupName
                    }
                    value={filter.groupView ? filter.selectedGroup : ''}
                    className='w-100'
                    options={groupOptions}
                    disabled={!filter.groupView}
                    onChange={(value) =>
                      setFilter((prevFilterData) => ({
                        ...prevFilterData,
                        selectedGroup: value,
                      }))
                    }
                  />
                </div>
              </Tooltip>
            </Flex>
          </div>
        )}
      </Flex>
      <Flex justify={groupId ? 'end' : 'start'} className='mt-2' gap={'12px'}>
        <ButtonComponent
          className='secondary-button'
          text={t('common:reset_filter')}
          onClick={resetFilter}
        />
      </Flex>
      <Divider />
    </>
  );
};

export default OpenGameFilter;
