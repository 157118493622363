import {
  Avatar,
  Card,
  Skeleton,
  Divider,
  Flex,
  Empty,
  Select,
  Row,
  Col,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { StatsOverviewData, UserDto } from '../../../types/dto.types';
import { useTranslation } from 'react-i18next';
import { countGames } from '../../../utilities/data/count-games';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Server } from '../../../server/server';
import PieChartComponent from '../../../components/pie-chart/pie-chart.component';
import Title from '../../../components/title/title.component';
import Initials from '../../../components/initials/initials.component';
import { EyeInvisibleOutlined, TeamOutlined } from '@ant-design/icons';

const gameTypeColors = {
  gamesWonOfType: 'var(--green)',
  gamesLostOfType: 'var(--red)',
  gamesDrawOfType: 'var(--gray)',
};

interface StatsOverviewProps {
  user: UserDto;
}

const StatsOverview: FC<StatsOverviewProps> = (props) => {
  const { user } = props;

  const { t } = useTranslation('dashboard');
  const [statsOverview, setStatsOverview] = useState<StatsOverviewData>();
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getUserStats = async () => {
      setLoading(true);
      if (user) {
        const stats = await Server.user.getUserStatsByType(
          user.id,
          selectedGroupId
        );
        setStatsOverview(stats?.gameStats);
      }
      setLoading(false);
    };
    getUserStats();
  }, [selectedGroupId, user]);

  return !loading ? (
    statsOverview && Object.keys(statsOverview).length > 0 ? (
      <>
        <Title main={t('stats.title')} />
        <Card className={`card-cta-secondary dashboard-card-fixed p-0 mt-1`}>
          <Row justify='space-between' gutter={[0, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Flex align='center' gap={12}>
                {user.profileImage ? (
                  <Avatar src={user?.profileImage} size={56} />
                ) : (
                  <Initials
                    firstName={user.firstName}
                    lastName={user.lastName}
                    size={56}
                  />
                )}
                <Flex vertical>
                  <h4 className='m-0 bold'>{`${user?.firstName} ${user?.lastName}`}</h4>
                  <Flex align='center' gap={6}>
                    {t('stats.games_played')}:{' '}
                    <h3 className='m-0 bold'>{countGames(statsOverview)}</h3>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Flex align='center' gap={8}>
                <TeamOutlined />
                <Select
                  value={selectedGroupId}
                  className='w-100'
                  options={[
                    { value: '', label: t('stats.all_groups') },
                    ...user?.userGroups.map((userGroup) => {
                      return {
                        value: userGroup.groupId,
                        label: userGroup.groupName,
                      };
                    }),
                  ]}
                  onChange={(value) => setSelectedGroupId(value)}
                />
              </Flex>
            </Col>
          </Row>
          <Divider>{t('pie_chart.title').toUpperCase()}</Divider>
          <Swiper
            className='mt-2'
            spaceBetween={50}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              1450: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
            }}
            modules={[Autoplay]}
            slidesPerView={2}
          >
            {Object.entries(statsOverview).map(([gameType, gameTypeStats]) => {
              const data = (
                Object.keys(gameTypeStats) as Array<keyof typeof gameTypeStats>
              )
                .filter((x) => x !== 'gamesPlayedOfType')
                .map((y) => {
                  return {
                    name: t(`pie_chart.${y}`),
                    value: gameTypeStats[y],
                    fill: gameTypeColors[y as keyof typeof gameTypeColors],
                  };
                });
              return (
                <SwiperSlide key={gameType} style={{ paddingBottom: '6px' }}>
                  <div className='text-small bold text-uppercase text-center'>
                    {t(`common:game_types.${gameType}`)} (
                    {gameTypeStats.gamesPlayedOfType})
                  </div>
                  <PieChartComponent data={data} animationDuration={900} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Card>
      </>
    ) : (
      <>
        <Title main={t('stats.title')} />
        <Card className={`card-cta-secondary dashboard-card-fixed p-0 mt-1`}>
          <Row justify='space-between' gutter={[0, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Flex align='center' gap={12}>
                {user.profileImage ? (
                  <Avatar src={user?.profileImage} size={56} />
                ) : (
                  <Initials
                    firstName={user.firstName}
                    lastName={user.lastName}
                    size={56}
                  />
                )}
                <Flex vertical>
                  <h4 className='m-0 bold'>{`${user?.firstName} ${user?.lastName}`}</h4>
                </Flex>
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Flex align='center' gap={8}>
                <TeamOutlined />
                <Select
                  value={selectedGroupId}
                  className='w-100'
                  options={[
                    { value: '', label: t('stats.all_groups') },
                    ...user?.userGroups.map((userGroup) => {
                      return {
                        value: userGroup.groupId,
                        label: userGroup.groupName,
                      };
                    }),
                  ]}
                  onChange={(value) => setSelectedGroupId(value)}
                />
              </Flex>
            </Col>
          </Row>
          <Flex
            vertical
            justify='center'
            align='center'
            style={{ height: '380px', width: '100%' }}
          >
            <Empty
              image={<EyeInvisibleOutlined />}
              imageStyle={{ fontSize: '42px' }}
              description={t('pie_chart.no_stats')}
            />
          </Flex>
        </Card>
      </>
    )
  ) : (
    <>
      <Skeleton.Input active size='small' />
      <Card className={`card-cta-secondary dashboard-card-fixed p-0 mt-1`}>
        <Skeleton active />
        <Skeleton active />
      </Card>
    </>
  );
};

export default StatsOverview;
