import { Col, Flex, Row, Tooltip, notification } from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import { Server } from '../../../../server/server';
import { UserRole } from '../../../../types/enums.types';
import ProfileCard from '../../../../components/profile-card/profile-card.component';
import Search, { SearchProps } from 'antd/es/input/Search';

const AddExistingPlayer = () => {
  const { t } = useTranslation('players');
  const { groupId } = useContext(AuthContext);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('');
  const bottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedPlayerId && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPlayerId]);

  const handlePlayerSelect = async (id: string) => {
    try {
      if (groupId) {
        await Server.userGroup.createUserGroup({
          userId: id,
          groupId: groupId,
          role: UserRole.user,
        });
        notification.success({ message: t('new_player.existing.success') });
      }
    } catch (err) {}
  };

  const onSearch: SearchProps['onSearch'] = async (value) => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    if (isEmailValid) {
      const response = await Server.user.getUserByEmail(value);
      if (response) {
        if (response.userGroups.some((ug) => ug.groupId === groupId)) {
          setSelectedPlayerId('');
          notification.warning({ message: t('new_player.existing.already') });
        } else {
          setSelectedPlayerId(response.id);
        }
      } else {
        setSelectedPlayerId('');
      }
    } else {
      notification.warning({ message: t('new_player.existing.invalid_email') });
    }
  };

  return (
    <>
      <Flex gap={'12px'} justify='center'>
        <h3>{t('new_player.existing.title').toUpperCase()}</h3>
        <Tooltip title={t('new_player.existing.tip')} placement='right'>
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Row justify='center'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <Flex vertical>
            <Search
              placeholder={t('new_player.existing.email')}
              onSearch={onSearch}
              enterButton
            />

            {selectedPlayerId ? (
              <>
                <ProfileCard userId={selectedPlayerId} recentStats={false} />
                <ButtonComponent
                  className='primary-button mt-1'
                  text={t('new_player.submit')}
                  style={{ width: '100%' }}
                  onClick={() => handlePlayerSelect(selectedPlayerId)}
                />
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Col>
      </Row>
      <div ref={bottomRef}></div>
    </>
  );
};

export default AddExistingPlayer;
