import { GetLocationsDto, LocationDto } from '../../types/dto.types';

import { serverUrl } from '../server.constants';
import axios from 'axios';

export class LocationServer {
  private readonly basePath = `${serverUrl}location`;

  // GET ALL LOCATIONS (GLOBAL OR GROUP)
  async getLocations(
    getLocationsDto: GetLocationsDto
  ): Promise<{ locations: LocationDto[]; count: number } | null> {
    const params = new URLSearchParams();
    if (getLocationsDto.pagination) {
      params.append('take', getLocationsDto.pagination.take.toString());
      params.append('offset', getLocationsDto.pagination.offset.toString());
    }

    try {
      const response = await axios({
        method: 'GET',
        url: this.basePath,
        responseType: 'json',
        params: params,
      });

      return response.data as { locations: LocationDto[]; count: number };
    } catch (error) {
      return null;
    }
  }
}
