export const setFilterPath = (filter: any, page?: number) => {
  const params = new URLSearchParams();

  if (page) {
    params.set('page', page.toString());
  }

  Object.keys(filter).forEach((key) => {
    if (Array.isArray(filter[key]) && filter[key].length === 0) {
      return;
    }
    if (filter[key]) {
      params.set(key, filter[key]);
    }
  });

  return params;
};
