import { FC, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import { SelectOptionsProps } from '../../../../types/props.types';
import { ComparisonFilterDto } from '../../../../types/dto.types';
import { Server } from '../../../../server/server';
import { Col, DatePicker, Flex, Row } from 'antd';
import ChoosePlayers from '../../../../components/choose-players/choose-players.component';
import { TeamEnum } from '../../../../types/enums.types';
import ButtonComponent from '../../../../components/buttons/button.component';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../components/spinner/spinner.component';
import { CalendarOutlined, DribbbleOutlined } from '@ant-design/icons';
import SelectGameType from '../../../../components/select-game-type/select-game-type.component';

interface ComparePlayersFilterProps {
  gameFetch: (filterData: ComparisonFilterDto) => Promise<void>;
  resetResults: () => void;
}

const ComparePlayersFilter: FC<ComparePlayersFilterProps> = (props) => {
  const { groupId } = useContext(AuthContext);
  const { t } = useTranslation('stats');
  const [userOptions, setUserOptions] = useState<SelectOptionsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterData, setFilterData] = useState<ComparisonFilterDto>({
    date: dayjs().subtract(3, 'year').toISOString(),
    dateTo: dayjs().toISOString(),
    gameTypeId: '',
    team1: [],
    team2: [],
  });

  useEffect(() => {
    const fetchUserOptions = async () => {
      setLoading(true);
      try {
        if (groupId) {
          const usersResponse = await Server.user.getAllUsers({ groupId });
          const options = usersResponse?.users.map((user) => {
            return {
              value: user.id,
              label: user.firstName + ' ' + user.lastName,
            };
          });

          if (options) {
            setUserOptions(options);
          }
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    fetchUserOptions();
  }, [groupId]);

  const resetFilter = () => {
    setFilterData({
      date: '',
      dateTo: '',
      gameTypeId: '',
      team1: [],
      team2: [],
    });
    props.resetResults();
  };

  const handleSelectPlayers = (
    selectedOption: { value: string; label: string },
    team: TeamEnum
  ) => {
    if (team && team !== TeamEnum.none) {
      setFilterData((prevState) => {
        const currentTeam = prevState[team];
        const playerSelected = currentTeam.some(
          (option) => option.id === selectedOption.value
        );
        let updatedTeam;
        if (playerSelected) {
          updatedTeam = currentTeam.filter(
            (option) => option.id !== selectedOption.value
          );
        } else {
          updatedTeam = [
            ...currentTeam,
            { name: selectedOption.label, id: selectedOption.value },
          ];
        }

        return { ...prevState, [team]: updatedTeam };
      });
    }
  };

  return !loading ? (
    <>
      <Flex justify='space-around' className='game-filter-container'>
        <div className='mt-1 game-filter-item'>
          <Flex gap={8}>
            <CalendarOutlined style={{ fontSize: '18px' }} />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              format={'DD.MM.YYYY.'}
              className='w-100'
              placeholder={t('common:from')}
              value={filterData.date ? dayjs(filterData.date) : null}
              onChange={(date) => {
                if (date) {
                  setFilterData((prevFilterData) => ({
                    ...prevFilterData,
                    date: date.toISOString(),
                  }));
                }
              }}
            />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              format={'DD.MM.YYYY.'}
              className='w-100'
              placeholder={t('common:to')}
              value={filterData.dateTo ? dayjs(filterData.dateTo) : null}
              onChange={(dateTo) => {
                if (dateTo) {
                  setFilterData((prevFilterData) => ({
                    ...prevFilterData,
                    dateTo: dateTo.toISOString(),
                  }));
                }
              }}
            />
          </Flex>
        </div>
        <div className='mt-1 game-filter-item'>
          <Flex gap={8}>
            <DribbbleOutlined style={{ fontSize: '18px' }} />
            <SelectGameType
              className='w-100'
              value={filterData.gameTypeId ? filterData.gameTypeId : null}
              onChange={(selectedType) => {
                setFilterData((prevFilterData) => ({
                  ...prevFilterData,
                  gameTypeId: selectedType,
                }));
              }}
            />
          </Flex>
        </div>
      </Flex>
      <Row
        className='align-items-center space-between mt-1'
        gutter={{ xs: 12, sm: 12, md: 24 }}
      >
        <Col xs={12} sm={12} md={12}>
          <ChoosePlayers
            title={t('common:team1').toUpperCase()}
            options={userOptions}
            team={TeamEnum.team1}
            teamIds={filterData.team1.map((player) => player.id)}
            otherTeamIds={filterData.team2.map((player) => player.id)}
            handleSelectPlayers={handleSelectPlayers}
          />
        </Col>
        <Col xs={12} sm={12} md={12}>
          <ChoosePlayers
            title={t('common:team2').toUpperCase()}
            options={userOptions}
            team={TeamEnum.team2}
            teamIds={filterData.team2.map((player) => player.id)}
            otherTeamIds={filterData.team1.map((player) => player.id)}
            handleSelectPlayers={handleSelectPlayers}
            className='text-end'
          />
        </Col>
      </Row>
      <Flex justify='end' className='mt-1' gap={'12px'}>
        <ButtonComponent
          text={t('common:reset_filter')}
          className='secondary-button'
          onClick={resetFilter}
        />
        <ButtonComponent
          text={t('common:confirm')}
          className='primary-button'
          onClick={() => props.gameFetch(filterData)}
        />
      </Flex>
    </>
  ) : (
    <Spinner />
  );
};

export default ComparePlayersFilter;
