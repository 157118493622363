import {
  BidGameDto,
  BidGameResultsDto,
  CreateBidGameDto,
} from "../../types/dto.types";
import { serverUrl } from "../server.constants";
import axios from "axios";

export class BidGameServer {
  private readonly basePath = `${serverUrl}bid-game`;

  // GET BID GAME BY IDS
  async getBidGameByIds(
    userId: string,
    gameId: string
  ): Promise<BidGameDto | null> {
    const params = new URLSearchParams();
    params.append("userId", userId.toString());
    params.append("gameId", gameId.toString());

    try {
      const response = await axios({
        method: "GET",
        url: this.basePath,
        responseType: "json",
        params: params,
      });
      return response.data as BidGameDto;
    } catch (error) {
      return null;
    }
  }

  // CREATE BID GAME
  async createBidGame(newBidGame: CreateBidGameDto) {
    try {
      const response = await axios({
        method: "POST",
        url: this.basePath,
        responseType: "json",
        data: newBidGame,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }

  // CALCULATE BID GAME RESULTS
  async calculateBidGame(gameId: string): Promise<BidGameResultsDto | null> {
    const params = new URLSearchParams();
    params.append("gameId", gameId);

    try {
      const response = await axios({
        method: "GET",
        url: `${this.basePath}/calculate`,
        responseType: "json",
        params: params,
      });

      return response.data as BidGameResultsDto;
    } catch (error) {
      return null;
    }
  }
}
