import { FC, useCallback, useEffect, useState } from 'react';
import { GameDto } from '../../types/dto.types';
import { Server } from '../../server/server';
import { useTranslation } from 'react-i18next';

interface BettingResultsProps {
  game: GameDto;
}

const BettingResults: FC<BettingResultsProps> = (props) => {
  const { t } = useTranslation('betting');
  const [results, setResults] = useState({
    firstTeam: 0,
    secondTeam: 0,
    draw: 0,
  });
  const [totalBets, setTotalBets] = useState<number>(0);

  const getResults = useCallback(async () => {
    const result = await Server.bidGame.calculateBidGame(props.game.id);
    if (result) {
      const sum = result.team1 + result.team2 + result.draw;
      setTotalBets(sum);
      if (sum === 0) return;
      setResults((prevState) => ({
        ...prevState,
        firstTeam: parseFloat(((100 * result.team1) / sum).toFixed(1)),
        secondTeam: parseFloat(((100 * result.team2) / sum).toFixed(1)),
        draw: parseFloat(((100 * result.draw) / sum).toFixed(1)),
      }));
    }
  }, [props.game.id]);

  useEffect(() => {
    getResults();
  }, [getResults]);

  return (
    <>
      <div className='text-center'>
        <p className='m-0 text-small text-uppercase'>{`${t(
          'total_bets'
        )}: ${totalBets}`}</p>
      </div>
      <div className='d-flex align-items-center space-evenly'>
        <div className='w-100'>
          <p className='m-0 text-center'>{results.firstTeam}%</p>
        </div>
        <div className='w-100'>
          <p className='m-0 text-center'>{results.draw}%</p>
        </div>
        <div className='w-100'>
          <p className='m-0 text-center'>{results.secondTeam}%</p>
        </div>
      </div>
    </>
  );
};

export default BettingResults;
