import { Flex, Spin } from 'antd';
import React from 'react';

const Spinner = () => {
  return (
    <Flex justify='center' align='center' style={{ height: '70vh' }}>
      <Spin size='large' />
    </Flex>
  );
};

export default Spinner;
