import { Dispatch, FC, SetStateAction } from 'react';
import { LockUserDto, UsersWithStatsDto } from '../../../../../types/dto.types';
import Title from '../../../../../components/title/title.component';
import { Flex, InputNumber, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { LockReason } from '../../../../../types/enums.types';
import ButtonComponent from '../../../../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';

interface LockFormProps {
  player: UsersWithStatsDto;
  setLockData: Dispatch<SetStateAction<LockUserDto>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  lockUser: () => Promise<void>;
}

const LockForm: FC<LockFormProps> = (props) => {
  const { t } = useTranslation('players');

  const reasonOptions = [
    {
      value: LockReason.SHOW,
      label: t('table.group_owner_actions.lock_reason.show'),
    },
    {
      value: LockReason.RULES,
      label: t('table.group_owner_actions.lock_reason.rules'),
    },
    {
      value: LockReason.OTHER,
      label: t('table.group_owner_actions.lock_reason.other'),
    },
    //{value: LockReason.PAY, label: t('table.group_owner_actions.lock_reason.pay')}
  ];
  return (
    <div>
      <Title
        main={t('table.group_owner_actions.lock')}
        desc1={props.player.firstName + ' ' + props.player.lastName}
      />
      <Flex vertical className='mt-1' gap={12}>
        <InputNumber
          min={1}
          placeholder={t('table.group_owner_actions.duration')}
          addonAfter={t('table.group_owner_actions.days')}
          style={{ width: '200px' }}
          onChange={(value) =>
            props.setLockData((prevData) => ({
              ...prevData,
              lockDuration: Number(value),
            }))
          }
        />
        <Select
          placeholder={t('table.group_owner_actions.lock_reason.title')}
          options={reasonOptions}
          onChange={(value) =>
            props.setLockData((prevData) => ({
              ...prevData,
              lockReason: value,
            }))
          }
        />
        <TextArea
          placeholder={t('table.group_owner_actions.desc')}
          rows={4}
          maxLength={140}
          showCount
          onChange={(e) =>
            props.setLockData((prevData) => ({
              ...prevData,
              lockDescription: e.target.value,
            }))
          }
        />
      </Flex>
      <Flex justify='end' className='mt-2'>
        <ButtonComponent
          className='primary-button'
          text={t('table.group_owner_actions.lock')}
          onClick={() => {
            props.lockUser();
            props.setIsModalOpen(false);
          }}
        />
      </Flex>
    </div>
  );
};

export default LockForm;
