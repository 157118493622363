import { GameDto } from '../../types/dto.types';

export const calculateDates = (games: GameDto[]) => {
  const dates = games.map((game) => new Date(game.date).getTime());
  const firstDate = new Date(Math.min(...dates));
  const lastDate = new Date(Math.max(...dates));

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const firstDateString = firstDate
    .toLocaleDateString('en-GB', options)
    .replace(/\//g, '.');
  const lastDateString = lastDate
    .toLocaleDateString('en-GB', options)
    .replace(/\//g, '.');

  return [firstDateString, lastDateString];
};
