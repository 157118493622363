import { FC } from 'react';
import { ComparisonStatsDto } from '../../../../types/dto.types';
import { Card, Col, Divider, Empty, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined } from '@ant-design/icons';

interface CompareMainInfoProps {
  baseStats: {
    data: ComparisonStatsDto;
    team1: string[];
    team2: string[];
  };
  dates: string[];
}

const CompareMainInfo: FC<CompareMainInfoProps> = (props) => {
  const { t } = useTranslation('stats');

  return props.baseStats.data.gamesPlayed > 0 ? (
    <Card className={`card-main mt-1`}>
      <h3 className='text-center'>
        {t('compare_players.main_info.games_played')}:{' '}
        {props.baseStats.data.gamesPlayed}
      </h3>
      {props.baseStats.data.gamesPlayed !== 0 && (
        <h4 className='text-center'>{`${props.dates[0]} - ${props.dates[1]}`}</h4>
      )}
      <Divider />
      <Row className='text-center'>
        <Col span={8}>
          <p>{props.baseStats.team1.toString()}</p>
        </Col>
        <Col span={8}>
          <p>{t('compare_players.main_info.draw')} </p>
        </Col>
        <Col span={8}>
          <p>{props.baseStats.team2.toString()}</p>
        </Col>
      </Row>
      <Row className='text-center'>
        <Col
          span={8}
          style={{
            color:
              props.baseStats.data.gamesWon > props.baseStats.data.gamesLost
                ? 'var(--green)'
                : 'var(--red)',
          }}
        >
          <h4>{props.baseStats.data.gamesWon}</h4>
        </Col>
        <Col span={8} style={{ color: 'var(--gray)' }}>
          <h4>{props.baseStats.data.gamesDraw}</h4>
        </Col>
        <Col
          span={8}
          style={{
            color:
              props.baseStats.data.gamesWon < props.baseStats.data.gamesLost
                ? 'var(--green)'
                : 'var(--red)',
          }}
        >
          <h4>{props.baseStats.data.gamesLost}</h4>
        </Col>
      </Row>
      {(props.baseStats.data.mvpCountLeft > 0 ||
        props.baseStats.data.mvpCountRight > 0) && (
        <>
          <Divider>{t('compare_players.main_info.MVP')}: </Divider>
          <Row className='text-center'>
            <Col span={8}>
              <p>{props.baseStats.data.mvpCountLeft}</p>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <p>{props.baseStats.data.mvpCountRight}</p>
            </Col>
          </Row>
        </>
      )}
    </Card>
  ) : (
    <Card className='card-main mt-1'>
      <Empty
        image={<EyeInvisibleOutlined />}
        imageStyle={{ fontSize: '42px' }}
        style={{ marginTop: '24px' }}
        description={
          props.baseStats.team1.length === 0 ||
          props.baseStats.team2.length === 0
            ? t('compare_players.main_info.no_res_1')
            : t('compare_players.main_info.no_res_m')
        }
      />
    </Card>
  );
};

export default CompareMainInfo;
