import { Avatar, Card, Empty, Flex, List } from 'antd';
import { UserDto } from '../../../../../types/dto.types';
import Initials from '../../../../../components/initials/initials.component';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined } from '@ant-design/icons';

interface Props {
  receivers: UserDto[];
}

const SeenNotificationReceivers = ({ receivers }: Props) => {
  const { t } = useTranslation('open-games');

  return (
    <Card
      className={`card-main p-0`}
      style={{
        background: 'var(--cta-main-bg2)',
        border: '1px solid var(--gray)',
        width: '240px',
      }}
    >
      <h4 className='m-0'>{t('game_card.seen')}</h4>
      {receivers.length > 0 ? (
        <List className='scrollable-160'>
          {receivers.map((receiver) => (
            <List.Item key={receiver.id}>
              <Flex align='center' gap={8}>
                {receiver.profileImage ? (
                  <Avatar src={receiver.profileImage} size={32} />
                ) : (
                  <Initials
                    firstName={receiver.firstName}
                    lastName={receiver.lastName}
                    size={32}
                  />
                )}
                <div className={`d-flex align-items-center`}>
                  <div>{receiver.firstName}&nbsp;</div>
                  <div className='first-letter'>{receiver.lastName}</div>
                </div>
              </Flex>
            </List.Item>
          ))}
        </List>
      ) : (
        <Empty
          image={<EyeInvisibleOutlined />}
          imageStyle={{ fontSize: '48px' }}
          description={t('no_views')}
        />
      )}
    </Card>
  );
};

export default SeenNotificationReceivers;
