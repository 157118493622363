import { Card, Empty, Flex, Skeleton } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Server } from '../../../server/server';
import { GameDto } from '../../../types/dto.types';
import { AuthContext } from '../../../context/auth-context';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/title/title.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import NewestOpenGame from './newest-open-game/newest-open-game.component';

const NewestOpenGames = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('dashboard');
  const [openGames, setOpenGames] = useState<GameDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOpenGames = async () => {
      setLoading(true);
      if (user) {
        const response = await Server.game.getOpenGames({ userId: user.id });
        if (response) {
          setOpenGames(response);
        }
      }
      setLoading(false);
    };
    fetchOpenGames();
  }, [user]);

  return !loading ? (
    <>
      {openGames.length > 0 ? (
        <>
          <Title main={t('open.newest_open')} />
          <Card className={`card-main dashboard-card p-0 mt-1`}>
            <Flex vertical gap={24} className='scrollable-500 p-05'>
              {openGames.map((openGame, index) => {
                return (
                  <NewestOpenGame
                    key={index}
                    openGame={openGame}
                    index={index}
                  />
                );
              })}
            </Flex>
          </Card>
        </>
      ) : (
        <>
          <Title main={t('open.newest_open')} />
          <Card className={`card-main empty-dashboard-card p-0 mt-1`}>
            <Flex
              vertical
              justify='center'
              align='center'
              style={{
                height: '230px',
                width: '100%',
              }}
            >
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '42px' }}
                description={t('open.no_open')}
              />
            </Flex>
          </Card>
        </>
      )}
    </>
  ) : (
    <>
      <Skeleton.Input active size='small' />
      <Card className={`card-main empty-dashboard-card p-0 mt-1`}>
        <Skeleton active />
      </Card>
    </>
  );
};

export default NewestOpenGames;
