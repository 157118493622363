import React, { Dispatch, SetStateAction } from 'react';
import { GameDto } from '../../../../types/dto.types';
import { Flex, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../../components/buttons/button.component';
import { GameStatus } from '../../../../types/enums.types';
import { EditOutlined, GlobalOutlined, TeamOutlined } from '@ant-design/icons';

interface Props {
  game: GameDto;
  className: string;
  setEditingGame: Dispatch<
    SetStateAction<{
      id: string;
      status: GameStatus;
    }>
  >;
}

const EditGameHeader = ({ game, className, setEditingGame }: Props) => {
  const { t } = useTranslation('games');

  const renderOpenIcon = () => {
    if (game.status === GameStatus.STATUS_OPEN_GLOBAL) {
      return (
        <h4>
          <Tooltip
            title={t('edit_game.tooltips.open_global')}
            destroyTooltipOnHide
          >
            <GlobalOutlined />
          </Tooltip>
        </h4>
      );
    } else if (game.status === GameStatus.STATUS_OPEN_GROUP) {
      return (
        <h4>
          <Tooltip
            title={t('edit_game.tooltips.open_group')}
            destroyTooltipOnHide
          >
            <TeamOutlined />
          </Tooltip>
        </h4>
      );
    } else return <h4>&nbsp;</h4>;
  };

  return (
    <Flex justify='space-between' align='center' className={className}>
      <Flex align='center' gap={16}>
        {renderOpenIcon()}
        <Flex vertical>
          <div>
            <span className='bold'>
              {dayjs(game.date).format('DD.MM.YYYY.')}{' '}
            </span>
            <span>{dayjs(game.date).format('HH:mm')}</span>
          </div>
          <div>
            <i>{t(`common:game_types.${game.type}`).toUpperCase()}</i>
          </div>
        </Flex>
      </Flex>
      <ButtonComponent
        icon={<EditOutlined />}
        className='secondary-button'
        text={t('common:edit')}
        onClick={() => setEditingGame({ id: game.id, status: game.status })}
      />
    </Flex>
  );
};

export default EditGameHeader;
