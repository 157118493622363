import dayjs from 'dayjs';
import { ProfileCardDto, ProfileDto } from '../../types/dto.types';

export const calculateProfileCardStats = (profile: ProfileDto) => {
  const name = profile.firstName + ' ' + profile.lastName;
  const memberSince = dayjs(profile.memberSince).format('DD.MM.YYYY.');

  const stats: ProfileCardDto = {
    name,
    profileImage: profile.profileImage,
    memberSince,
    gamesPlayed: profile.gamesPlayed,
    gamesWon: profile.gamesWon,
    gamesLost: profile.gamesLost,
    gamesDraw: profile.gamesDraw,
  };

  return stats;
};
