import { InvitedUserDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class AuthServer {
  private readonly basePath = `${serverUrl}auth`;

  // LOGIN
  async login(email: string, password: string) {
    const body = { email: email.toLowerCase().trim(), password: password };
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/sign-in`,
        responseType: 'json',
        data: body,
      });
      window.localStorage.setItem('BearerToken', response.data.accessToken);
      return response.data;
    } catch (error) {}
  }

  // LOGIN WITH GOOGLE
  async loginWithGoogle(token: string) {
    const body = { idToken: token };
    try {
      const response = await axios({
        method: 'POST',
        url: `${serverUrl}auth/login-google`,
        responseType: 'json',
        data: body,
      });
      if (response.data.accessToken) {
        window.localStorage.setItem('BearerToken', response.data.accessToken);

        return response.data;
      }
    } catch (error) {
      return null;
    }
  }

  // VERIFY EMAIL
  async verifyEmail(token: string) {
    const params = new URLSearchParams();
    params.append('verifyToken', token.toString());
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/verify-email`,
        responseType: 'json',
        params: params,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  // SET PASSWORD AND INFO
  async setPasswordAndInfo(invitedUserDto: InvitedUserDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/set-password-and-info`,
        responseType: 'json',
        data: invitedUserDto,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  // SEND RESET PASSWORD LINK
  async sendResetPasswordLink(email: string) {
    const params = new URLSearchParams();
    params.append('email', email.toLowerCase().trim());

    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/send-reset-password-link`,
        responseType: 'json',
        params,
      });

      return response.data;
    } catch (error) {}
  }
}
