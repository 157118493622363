import { Card, Divider, Empty, Flex, List, Rate, Skeleton } from 'antd';
import Title from '../../../components/title/title.component';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Server } from '../../../server/server';
import { LeaderboardDto } from '../../../types/dto.types';
import { DribbbleOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SelectGameType from '../../../components/select-game-type/select-game-type.component';

const Leaderboard = () => {
  const { t } = useTranslation('dashboard');

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [leaderboard, setLeaderboard] = useState<LeaderboardDto[]>();
  const [selectedGameTypeId, setSelectedGameTypeId] = useState<string>('');

  const fetchLeaderboard = useCallback(async () => {
    if (selectedGameTypeId) {
      const response = await Server.rating.getLeaderboard(selectedGameTypeId);
      setLeaderboard(response);
    }
  }, [selectedGameTypeId]);

  useEffect(() => {
    setLoading(true);
    fetchLeaderboard();
    setLoading(false);
  }, [fetchLeaderboard]);

  return !loading ? (
    <>
      <Title main={t('leaderboard.title')} />
      <Card className={`card-cta-main dashboard-card-fixed p-0 mt-1`}>
        <Flex gap={'8px'}>
          <DribbbleOutlined style={{ fontSize: '18px' }} />
          <SelectGameType
            value={selectedGameTypeId}
            className='w-100'
            onChange={(value) => setSelectedGameTypeId(value)}
            setSelectedGameTypeId={setSelectedGameTypeId}
          />
        </Flex>
        <Divider />
        {leaderboard && leaderboard?.length > 0 ? (
          <List
            dataSource={leaderboard}
            renderItem={(item, index) => {
              let indexColor: string = ``;
              switch (index) {
                case 0:
                  indexColor = `gold-color`;
                  break;
                case 1:
                  indexColor = `silver-color`;
                  break;
                case 2:
                  indexColor = `bronze-color`;
                  break;
                default:
                  break;
              }
              return (
                <List.Item key={index}>
                  <Flex
                    className='w-100'
                    align='center'
                    justify='space-between'
                  >
                    <Flex gap={16}>
                      <p className={`${indexColor} leaderboard-badge`}>
                        {index + 1}
                      </p>
                      <div
                        onClick={() => navigate(`/players/${item.userId}`)}
                        className={`d-flex align-items-center teams-username`}
                      >
                        <div>{item.firstName}&nbsp;</div>
                        <div className='first-letter'>{item.lastName}</div>
                      </div>
                    </Flex>
                    <Rate
                      className='avg-rate-component'
                      disabled
                      count={10}
                      value={item.averageRating}
                    />
                  </Flex>
                </List.Item>
              );
            }}
          />
        ) : (
          <Flex
            vertical
            justify='center'
            align='center'
            style={{ height: '300px', width: '100%' }}
          >
            <Empty
              image={<EyeInvisibleOutlined />}
              imageStyle={{ fontSize: '42px' }}
              description={t('leaderboard.no_data')}
            />
          </Flex>
        )}
      </Card>
    </>
  ) : (
    <>
      <Skeleton.Input active size='small' />
      <Card className={`card-cta-main dashboard-card-fixed p-0 mt-1`}>
        <Skeleton active style={{ height: '400px', width: '100%' }} />
      </Card>
    </>
  );
};

export default Leaderboard;
