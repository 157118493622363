import {
  GetUsersDto,
  GetUsersWithStatsDto,
  OrganizerStatsDto,
  ProfileDto,
  ResendInviteDto,
  SendInviteDto,
  SignUpDto,
  StatsByTypeDto,
  UpdateUserEmailDto,
  UpdateUserInfoDto,
  UpdateUserPasswordDto,
  UserDto,
  UsersResponseDto,
  UsersWithStatsDto,
} from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserServer {
  private readonly basePath = `${serverUrl}user`;

  // GET USER
  async getUser(): Promise<UserDto | null> {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/logged`,
        responseType: 'json',
      });
      return response.data as UserDto;
    } catch (err) {
      return null;
    }
  }

  // GET USER BY ID
  async getUserById(userId: string): Promise<UserDto | null> {
    const params = new URLSearchParams();
    params.append('id', userId.toString());
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/user-by-id`,
        responseType: 'json',
        params: params,
      });
      return response.data as UserDto;
    } catch (err) {
      return null;
    }
  }

  // GET USER BY EMAIL
  async getUserByEmail(email: string): Promise<UserDto | null> {
    const params = new URLSearchParams();
    params.append('email', email.toLowerCase().trim());
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/user-by-email`,
        responseType: 'json',
        params: params,
      });
      return response.data as UserDto;
    } catch (err) {
      return null;
    }
  }

  // GET ALL USERS
  async getAllUsers(
    getUsersDto: GetUsersDto
  ): Promise<UsersResponseDto | null> {
    const params = new URLSearchParams();
    if (getUsersDto.pagination) {
      params.append('take', getUsersDto.pagination.take.toString());
      params.append('offset', getUsersDto.pagination.offset.toString());
    }
    if (getUsersDto.groupId) {
      params.append('groupId', getUsersDto.groupId.toString());
    }
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}`,
        responseType: 'json',
        params: params,
      });
      return response.data as UsersResponseDto;
    } catch (err) {
      return null;
    }
  }

  // GET USERS WITH STATS
  async getUsersWithStats(
    getUsersWithStatsDto: GetUsersWithStatsDto
  ): Promise<UsersWithStatsDto[] | null> {
    const params = new URLSearchParams();
    if (getUsersWithStatsDto.date) {
      params.append('date', getUsersWithStatsDto.date.toString());
    }
    if (getUsersWithStatsDto.dateTo) {
      params.append('dateTo', getUsersWithStatsDto.dateTo.toString());
    }
    if (getUsersWithStatsDto.groupId) {
      params.append('groupId', getUsersWithStatsDto.groupId.toString());
    }
    if (getUsersWithStatsDto.gameTypeId) {
      params.append('gameTypeId', getUsersWithStatsDto.gameTypeId.toString());
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/users-with-stats`,
        responseType: 'json',
        params: params,
      });
      return response.data as UsersWithStatsDto[];
    } catch (err) {
      return null;
    }
  }

  // GET PROFILE
  async getProfile(
    userId: string,
    recentStats: boolean
  ): Promise<ProfileDto | null> {
    const params = new URLSearchParams();
    params.append('id', userId.toString());

    if (recentStats) {
      const dateTo = new Date();
      const date = new Date(dateTo);
      date.setMonth(dateTo.getMonth() - 1);

      params.append('date', date.toISOString());
      params.append('dateTo', dateTo.toISOString());
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/profile`,
        responseType: 'json',
        params: params,
      });
      return response.data as ProfileDto;
    } catch (err) {
      return null;
    }
  }

  // GET USER STATS BY TYPE
  async getUserStatsByType(
    userId: string,
    groupId?: string
  ): Promise<StatsByTypeDto> {
    const params = new URLSearchParams();
    params.append('userId', userId);

    if (groupId) {
      params.append('groupId', groupId);
    }

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/user-stats-by-type`,
      responseType: 'json',
      params: params,
    });
    return response.data as StatsByTypeDto;
  }

  // CREATE USER (REG)
  async createUser(signUpInfo: SignUpDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${serverUrl}auth/sign-up`,
        responseType: 'json',
        data: signUpInfo,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  // UPDATE USER INFO
  async updateInfo(updateInfo: UpdateUserInfoDto) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/update-info`,
        responseType: 'json',
        data: updateInfo,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  // UPDATE USER EMAIL
  async updateEmail(updateEmail: UpdateUserEmailDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/update-email`,
      responseType: 'json',
      data: updateEmail,
    });

    return response.data;
  }

  // UPDATE USER PASSSWORD
  async updatePassword(updatePassword: UpdateUserPasswordDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/update-password`,
      responseType: 'json',
      data: updatePassword,
    });

    return response.data;
  }

  // INVITE USER
  async sendInvite(sendInviteData: SendInviteDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/send-invitation`,
        responseType: 'json',
        data: sendInviteData,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }

  // RESEND INVITE
  async resendInvite(resendInviteData: ResendInviteDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/resend-invitation`,
        responseType: 'json',
        data: resendInviteData,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }

  // GET ORGANIZER STATS
  async getOrganizerStats(organizerId: string) {
    const params = new URLSearchParams();
    params.append('organizerId', organizerId);

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/organizer-stats`,
        responseType: 'json',
        params,
      });

      return response.data as OrganizerStatsDto;
    } catch (error) {
      return null;
    }
  }
}
