import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/auth-context';
import LoginRouter from './login.router';
import { Layout } from 'antd';
import HeaderComponent from '../components/header/header.component';
import Sidebar from '../components/sidebar/sidebar.component';
import PagesRouter from './pages.router';
import ChangeEmail from '../pages/auth/change-email/change-email.component';

const AppRouter = () => {
  const { isLoggedIn, checkIfLoggedIn, isLoading, user } =
    useContext(AuthContext);

  useEffect(() => {
    checkIfLoggedIn();
  }, [checkIfLoggedIn]);

  return !isLoading ? (
    <>
      {!isLoggedIn ? (
        <LoginRouter />
      ) : (
        <>
          {user?.userSettings.emailVerified ? (
            <Layout style={{ backgroundColor: 'transparent' }}>
              <HeaderComponent />
              <Layout style={{ backgroundColor: 'transparent' }}>
                <Sidebar />
                <Layout style={{ backgroundColor: 'transparent' }}>
                  <div className='main-content'>
                    <PagesRouter />
                  </div>
                </Layout>
              </Layout>
            </Layout>
          ) : (
            <ChangeEmail />
          )}
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default AppRouter;
