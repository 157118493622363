import { Avatar, Button, Flex, Popconfirm, Radio, notification } from 'antd';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { GameDto } from '../../../../types/dto.types';
import { useContext, useEffect, useState } from 'react';
import { PlayerRole } from '../../../../types/enums.types';
import { Server } from '../../../../server/server';
import ButtonComponent from '../../../../components/buttons/button.component';
import { AuthContext } from '../../../../context/auth-context';
import { GameTypeImages } from '../../../../common/game-type-images';
import { dayName } from '../../../../utilities/data/day-name';

interface Props {
  game: GameDto;
}

const OpenGamePopup = ({ game }: Props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('open-games');
  const [playerRole, setPlayerRole] = useState<PlayerRole>();
  const [alreadyPlaying, setAlreadyPlaying] = useState<boolean>(false);
  const [alreadyApplied, setAlreadyApplied] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const checkIfUserApplied = async () => {
      const response = await Server.openGameApplication.checkIfUserApplied(
        game.id
      );
      setAlreadyApplied(response);
    };

    if (game.userGames.some((ug) => ug.userId === user?.id)) {
      setAlreadyPlaying(true);
    }

    checkIfUserApplied();
    setReload(false);
  }, [game.id, game.userGames, reload, user?.id]);

  const applyForOpenGame = async () => {
    if (playerRole) {
      try {
        await Server.openGameApplication.createApplication({
          gameId: game.id,
          playerRole: playerRole,
        });
        notification.success({ message: t('game_card.apply.success') });
        setReload(true);
      } catch (error) {}
    }
  };

  const deleteApplication = async () => {
    try {
      await Server.openGameApplication.deleteApplication(game.id);
      notification.success({
        message: t('game_card.cancel_application.success'),
      });
      setReload(true);
    } catch (error) {}
  };

  const checkApplicationsCloseTo = async () => {
    try {
      const response = await Server.openGameApplication.getApplicationsCloseTo(
        game.id
      );
      if (response === 1) {
        notification.warning({
          message: t('game_card.apply.warning'),
          description: t('game_card.apply.warning_one'),
          duration: 20,
        });
      } else if (response > 1) {
        notification.warning({
          message: t('game_card.apply.warning'),
          description: t('game_card.apply.warning_multiple', {
            number: response,
          }),
          duration: 20,
        });
      }
    } catch (error) {}
  };

  return (
    <div>
      <Flex gap={12} align='center'>
        <Avatar
          size={64}
          src={
            <img
              src={GameTypeImages[game.type as keyof typeof GameTypeImages]}
              alt='avatar'
            />
          }
        />
        <div>
          <h4 className='m-0 text-uppercase text-italic'>
            {t(`common:game_types.${game.type}`)}
          </h4>
          <Flex vertical>
            <p className='m-0'>
              {t(`common:days.${dayName(game.date)}`)},&nbsp;
            </p>
            <p className='m-0'>{dayjs(game.date).format('DD.MM.YYYY.')}</p>
          </Flex>
          <h3 className='m-0 bold'>{dayjs(game.date).format('HH:mm')}</h3>
        </div>
      </Flex>
      <Flex gap={2} className='mt-1 green-color bold'>
        {game.openPlayerPositions > 0 ? (
          <span>
            {`${game.openPlayerPositions}
                ${
                  game.openPlayerPositions > 1
                    ? t('game_card.players')
                    : t('game_card.player')
                }`}
          </span>
        ) : (
          <></>
        )}
        {game.openPlayerPositions > 0 && game.openGoalkeeperPositions > 0 ? (
          <p className='m-0'>+</p>
        ) : (
          <></>
        )}
        {game.openGoalkeeperPositions > 0 ? (
          <span>
            {`${game.openGoalkeeperPositions}
                ${
                  game.openGoalkeeperPositions > 1
                    ? t('game_card.goalkeepers')
                    : t('game_card.goalkeeper')
                }`}
          </span>
        ) : (
          <></>
        )}
      </Flex>
      <Flex justify='start' className='mt-1 open-game-request-button'>
        {!alreadyApplied ? (
          <Popconfirm
            title={t('game_card.apply.title')}
            description={
              <Flex justify='center' className='text-uppercase'>
                <Radio.Group
                  className='mt-1 mb-1'
                  value={playerRole}
                  onChange={(e) => setPlayerRole(e.target.value)}
                >
                  <Radio.Button
                    value={PlayerRole.player}
                    disabled={game.openPlayerPositions < 1}
                  >
                    {t('game_card.player')}
                  </Radio.Button>
                  <Radio.Button
                    value={PlayerRole.goalkeeper}
                    disabled={game.openGoalkeeperPositions < 1}
                  >
                    {t('game_card.goalkeeper')}
                  </Radio.Button>
                </Radio.Group>
              </Flex>
            }
            onConfirm={applyForOpenGame}
            okButtonProps={{ disabled: !playerRole }}
            okText={t('common:confirm')}
            cancelText={t('common:cancel')}
            overlayStyle={{
              backgroundColor: `var(--cta-secondary-bg)`,
              border: '1px solid var(--gray)',
              borderRadius: '8px',
            }}
          >
            <Button
              disabled={alreadyPlaying}
              className='success-button w-100'
              onClick={() => {
                setPlayerRole(undefined);
                checkApplicationsCloseTo();
              }}
            >
              {t('game_card.send_request')}
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={t('game_card.cancel_application.title')}
            onConfirm={deleteApplication}
            okText={t('common:confirm')}
            cancelText={t('common:cancel')}
            overlayStyle={{
              backgroundColor: `var(--cta-secondary-bg)`,
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '8px',
            }}
          >
            <ButtonComponent
              disabled={alreadyPlaying}
              className='danger-button'
              text={t('game_card.cancel_request')}
            />
          </Popconfirm>
        )}
      </Flex>
    </div>
  );
};

export default OpenGamePopup;
