import { Collapse, Empty, Flex, Pagination, Skeleton } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GameDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import GameHeader from './game-header/game-header.component';
import GameBody from './game-body/game-body.component';
import { useTranslation } from 'react-i18next';
import GamesFilter from './filter/games-filter.component';
import { AuthContext } from '../../../context/auth-context';
import Spinner from '../../../components/spinner/spinner.component';
import Title from '../../../components/title/title.component';
import Filter from '../../../components/filter/filter.component';
import { useSearchParams } from 'react-router-dom';
import { setFilterPath } from '../../../utilities/data/set-filter-path';
import dayjs from 'dayjs';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { GameStatus } from '../../../types/enums.types';

const Games = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { groupId, user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gamesResponse, setGamesResponse] = useState<{
    games: GameDto[];
    count: number;
  }>();
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const { t } = useTranslation(['games', 'dashboard', 'betting']);
  const [filter, setFilter] = useState<{
    date: string;
    dateTo: string;
    gameTypeId: string;
    playerIds: string[];
    playerRelation: string;
    gameId?: string;
    status?: GameStatus;
  }>({
    date: searchParams.get('date') ?? '',
    dateTo: searchParams.get('dateTo') ?? '',
    gameTypeId: searchParams.get('gameTypeId') ?? '',
    playerIds: searchParams.get('playerIds')
      ? (searchParams.get('playerIds')?.split(',') || []).map(
          (playerId) => playerId
        )
      : [],
    playerRelation: searchParams.get('playerRelation') ?? '',
    gameId: searchParams.get('gameId') ?? '',
    status: (searchParams.get('status') as GameStatus) ?? null,
  });
  const [filterCount, setFilterCount] = useState<number>(0);

  const countFilters = useCallback(() => {
    let count = 0;
    if (filter.date) {
      count++;
    }
    if (filter.dateTo) {
      count++;
    }
    if (filter.gameTypeId) {
      count++;
    }
    if (filter.playerIds.length > 0) {
      count++;
    }

    setFilterCount(count);
  }, [filter]);

  useEffect(() => {
    const pagination = { take: 10, offset: page - 1 };
    const fetchGames = async () => {
      if (groupId) {
        setIsLoading(true);
        const response = await Server.game.getGamesWithFilter({
          pagination: pagination,
          groupId: groupId,
          date: filter.date
            ? dayjs(filter.date, 'DD-MM-YYYY').toISOString()
            : undefined,
          dateTo: filter.dateTo
            ? dayjs(filter.dateTo, 'DD-MM-YYYY').endOf('day').toISOString()
            : undefined,
          gameTypeId: filter.gameTypeId,
          playerIds: filter.playerIds,
          playerRelation: filter.playerRelation,
        });

        if (response) {
          setGamesResponse(response);
        }
      }

      setIsLoading(false);
    };

    fetchGames();
    countFilters();
    setSearchParams(setFilterPath(filter, page));
  }, [groupId, page, filter, setSearchParams, countFilters]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <>
      {groupId ? (
        <>
          {!isLoading ? (
            <Title
              main={
                user?.userGroups.find((ug) => ug.groupId === groupId)?.groupName
              }
              desc1={`${t('no_of_games')}: ${gamesResponse?.count} `}
            />
          ) : (
            <Flex vertical gap={2}>
              <Skeleton.Button active size='small' />
              <Skeleton.Button active size='small' />
            </Flex>
          )}
          <div className='mt-1'>
            <Filter
              children={
                <GamesFilter
                  filter={filter}
                  setFilter={setFilter}
                  groupId={groupId}
                />
              }
              tip={t('filter.tooltips.filter')}
              defaultOpen={false}
              count={filterCount}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {!isLoading ? (
        <>
          {gamesResponse && gamesResponse?.count > 0 ? (
            <Flex vertical gap={'6px'} className='mt-1'>
              <Collapse
                accordion
                style={{ width: '100%' }}
                destroyInactivePanel
                items={gamesResponse?.games.map((game) => ({
                  key: game.id,
                  label: <GameHeader game={game} />,
                  className: 'collapse-main',
                  children: <GameBody game={game} />,
                }))}
              />
              <Flex justify='space-around' className='mt-1'>
                <Pagination
                  current={page}
                  total={gamesResponse?.count}
                  pageSize={10}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  hideOnSinglePage
                  simple
                ></Pagination>
              </Flex>
            </Flex>
          ) : (
            <Flex justify='center' align='center' style={{ minHeight: '30vh' }}>
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '42px' }}
                description={t('no_data')}
              />
            </Flex>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Games;
