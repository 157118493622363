import { theme } from 'antd';

export const darkConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    fontFamily: 'Montserrat',
    fontSize: 14,
  },
  components: {
    Table: {
      headerBg: 'var(--collapse-header)',
      borderColor: 'var(--cta-secondary-bg)',
      borderRadius: 24,
      bodySortBg: 'transparent',
      colorBgContainer: 'transparent',
      headerSplitColor: 'var(--gray)',
    },
    Tabs: {
      inkBarColor: 'var(--light-blue)',
      itemActiveColor: 'var(--light-blue)',
      itemSelectedColor: 'var(--light-blue)',
      itemHoverColor: 'var(--light-blue)',
      colorBorderSecondary: 'rgba(253, 253, 253, 0.12)',
    },
    Tooltip: {
      colorBgSpotlight: 'var(--dark-gray)',
    },
    Modal: {
      contentBg: 'rgb(0 0 0);',
      borderRadiusLG: 24,
    },
    Pagination: {
      borderRadius: 24,
      colorBgContainer: 'var(--cta-secondary-bg)',
      colorBorder: 'var(--gray)',
      colorPrimaryHover: 'var(--light-blue)',
      colorText: 'var(--white)',
      controlOutline: '#000',
      colorPrimary: 'var(--gray)',
    },
    Button: {
      borderRadius: 24,
      contentFontSize: 10,
      size: 45,
      colorPrimaryHover: 'var(--light-blue)',
      colorPrimary: 'var(--blue)',
    },
    Input: {
      colorBgContainer: 'transparent',
      colorBorder: 'var(--gray)',
      borderRadius: 24,
      lineWidth: 1,
      controlHeight: 45,
      colorPrimary: 'var(--gray)',
      colorPrimaryHover: 'var(--gray)',
      colorTextPlaceholder: 'var(--gray)',
    },
    InputNumber: {
      colorBgContainer: 'transparent',
      colorBorder: 'var(--gray)',
      borderRadius: 24,
      lineWidth: 1,
      controlHeight: 45,
      colorPrimary: 'var(--gray)',
      colorPrimaryHover: 'var(--gray)',
    },
    Dropdown: {
      colorBgElevated: 'var(--cta-secondary-bg)',
      colorPrimary: 'var(--white)',
      controlItemBgActiveHover: 'var(--gray)',
      controlItemBgActive: 'var(--cta-main-bg1)',
    },
    Select: {
      colorBgContainer: 'transparent',
      colorBorder: 'var(--gray)',
      colorBgElevated: 'var(--dark-gray)',
      borderRadius: 24,
      lineWidth: 1,
      controlHeight: 45,
      colorPrimary: 'var(--gray)',
      colorPrimaryHover: 'var(--gray)',
      optionSelectedBg: 'var(--cta-main-bg1)',
      colorTextPlaceholder: 'var(--gray)',
    },
    DatePicker: {
      colorBgContainer: 'transparent',
      colorBorder: 'var(--gray)',
      colorTextPlaceholder: 'var(--gray)',
      borderRadius: 24,
      lineWidth: 1,
      controlHeight: 45,
      colorBgElevated: 'var(--dark-gray)',
      colorPrimary: 'var(--light-blue)',
      activeBorderColor: 'var(--light-blue)',
      hoverBorderColor: 'var(--light-blue)',
      cellWidth: 28,
      cellHeight: 18,
      timeColumnWidth: 34,
      timeCellHeight: 32,
    },
    Collapse: {
      headerBg: 'var(--collapse-header)',
      contentBg: 'var(--collapse-body)',
      borderRadiusLG: 24,
      colorBorder: '#ffffff1a',
    },
    Popover: {
      colorBgElevated: 'rgba(255, 255, 255, 0)',
      boxShadowSecondary: '',
    },
    Switch: {
      colorPrimary: 'var(--light-blue)',
      colorPrimaryHover: 'var(--light-blue)',
    },
    Radio: {
      colorPrimary: 'var(--light-blue)',
      itemSelectedColor: 'var(--light-blue)',
      colorPrimaryHover: 'var(--gray)',
    },
    Empty: {
      controlHeightLG: 24,
    },

    Rate: {
      colorFillContent: 'var(--dark-gray)',
    },
  },
};
