import {
  HomeOutlined,
  NotificationOutlined,
  PieChartOutlined,
  PlayCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import Dashboard from '../../pages/dashboard/dashboard.component';
import GroupRules from '../../pages/group-rules/group-rules.component';
import OpenGamePanel from '../../pages/open-games-panel/open-games-panel.component';
import Stats from '../../pages/stats/stats.component';
import PlayersPanel from '../../pages/players-panel/players-panel.component';
import Profile from '../../pages/profile/profile.component';
import GamesPanel from '../../pages/games-panel/games-panel.component';

export const RoutesDefinitions = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    groupNeeded: false,
  },
  {
    path: '/open-games',
    element: <OpenGamePanel />,
    groupNeeded: false,
  },
  {
    path: '/games',
    element: <GamesPanel />,
    groupNeeded: true,
  },
  {
    path: '/players',
    element: <PlayersPanel />,
    groupNeeded: true,
  },

  {
    path: '/stats',
    element: <Stats />,
    groupNeeded: true,
  },
  {
    path: '/profile',
    element: <Profile />,
    groupNeeded: false,
  },
  {
    path: '/group-rules',
    element: <GroupRules />,
    groupNeeded: true,
  },
];

export const SidebarLinks = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: <HomeOutlined />,
    element: <Dashboard />,
    groupNeeded: false,
    isMainMenu: true,
  },
  {
    title: 'open-games',
    path: '/open-games',
    icon: <NotificationOutlined />,
    element: <OpenGamePanel />,
    groupNeeded: false,
    isMainMenu: true,
  },
  {
    title: 'games',
    path: '/games',
    icon: <PlayCircleOutlined />,
    element: <GamesPanel />,
    groupNeeded: true,
    isMainMenu: true,
  },
  {
    title: 'players',
    path: '/players',
    icon: <TeamOutlined />,
    element: <PlayersPanel />,
    groupNeeded: true,
    isMainMenu: true,
  },
  {
    title: 'stats',
    path: '/stats',
    icon: <PieChartOutlined />,
    element: <Stats />,
    groupNeeded: true,
    isMainMenu: true,
  },
];
