import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  GameDto,
  OpenGameApplicationDto,
  SelectedPlayerDto,
} from '../../../../types/dto.types';
import {
  Card,
  Divider,
  Flex,
  Popconfirm,
  Switch,
  Tooltip,
  notification,
} from 'antd';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  GlobalOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { SelectOptionsProps } from '../../../../types/props.types';
import { Server } from '../../../../server/server';
import {
  GameState,
  GameStatus,
  PlayerRole,
  TeamEnum,
  UserGameAction,
} from '../../../../types/enums.types';
import ButtonComponent from '../../../../components/buttons/button.component';
import { AuthContext } from '../../../../context/auth-context';
import { useTranslation } from 'react-i18next';
import EditDateLocation from '../edit-date-location/edit-date-location.component';
import EditPlayers from '../edit-players/edit-players.component';
import Applications from '../applications/applications.component';
import Spinner from '../../../../components/spinner/spinner.component';
import dayjs from 'dayjs';

interface EditOpenGameProps {
  gameId: string;
  reload: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
  setEditingGame: Dispatch<
    SetStateAction<{
      id: string;
      status: GameStatus;
    }>
  >;
}

interface ChangedUserGames {
  gameId: string;
  userId: string;
  action: UserGameAction;
  state?: GameState;
  team?: TeamEnum;
  role?: PlayerRole;
}

const EditOpenGame: FC<EditOpenGameProps> = (props) => {
  const { groupId, user } = useContext(AuthContext);
  const { t } = useTranslation(['games', 'players']);
  const [options, setOptions] = useState<{
    users: SelectOptionsProps[] | null;
    locations: SelectOptionsProps[] | null;
  }>();
  const [game, setGame] = useState<GameDto>();
  const [players, setPlayers] = useState<SelectedPlayerDto[]>();
  const [userGames, setUserGames] = useState<ChangedUserGames[]>([]);
  const [mvpVotingStatus, setMvpVotingStatus] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [openStatus, setOpenStatus] = useState<boolean>(true);
  const [isGlobal, setIsGlobal] = useState<boolean>();
  const [applications, setApplications] = useState<OpenGameApplicationDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const playersTeam1 = players?.filter(
    (player) => player.team === TeamEnum.team1
  );

  const playersTeam2 = players?.filter(
    (player) => player.team === TeamEnum.team2
  );

  const fetchGame = useCallback(async () => {
    setLoading(true);
    const gameResponse = await Server.game.getGameById(props.gameId);
    setGame(gameResponse);

    const players = gameResponse.userGames.map((userGame) => {
      return {
        name: userGame.firstName + ' ' + userGame.lastName,
        id: userGame.userId,
        team: userGame.team,
        role: userGame.role,
      };
    });

    setPlayers(players);
    setMvpVotingStatus(gameResponse.statusVoting);

    if (gameResponse.status === GameStatus.STATUS_OPEN_GLOBAL) {
      setIsGlobal(true);
    } else {
      setIsGlobal(false);
    }

    setLoading(false);
  }, [props.gameId]);

  const fetchOptions = useCallback(async () => {
    if (groupId) {
      const users = await Server.user.getAllUsers({
        groupId,
      });
      const locations = await Server.location.getLocations({});

      const userOptions = users?.users.map((user) => {
        return {
          value: user.id,
          label: user.firstName + ' ' + user.lastName,
          userLocks: user.userLocks,
        };
      });

      const locationOptions = locations?.locations.map((location) => {
        return { value: location.id, label: location.name };
      });

      setOptions({
        users: userOptions ?? null,
        locations: locationOptions ?? null,
      });
    }
  }, [groupId]);

  const fetchApplications = useCallback(async () => {
    const response = await Server.openGameApplication.getAllApplications(
      props.gameId
    );
    setApplications(response);
  }, [props.gameId]);

  useEffect(() => {
    fetchGame();
    fetchOptions();
    fetchApplications();
  }, [fetchApplications, fetchOptions, fetchGame, props.reload]);

  const handleSelectGoalkeepers = (playerId: string, team: TeamEnum) => {
    const updatedPlayers = players?.map((player) => {
      if (player.id === playerId && player.role === PlayerRole.player) {
        return { ...player, role: PlayerRole.goalkeeper };
      } else if (player.team === team) {
        return { ...player, role: PlayerRole.player };
      } else return player;
    });
    setPlayers(updatedPlayers);

    const updatedUserGames = userGames.map((userGame) => {
      if (userGame.userId === playerId) {
        return { ...userGame, role: PlayerRole.goalkeeper };
      } else if (userGame.team === team) {
        return { ...userGame, role: PlayerRole.player };
      } else return userGame;
    });
    setUserGames(updatedUserGames);
  };

  const userGameHandler = (
    userId: string,
    action: UserGameAction,
    playerTeam?: TeamEnum,
    playerRole?: PlayerRole
  ) => {
    const userGameRaw: ChangedUserGames = {
      userId: userId,
      gameId: props.gameId,
      team: playerTeam,
      role: playerRole,
      action: action,
    };

    setUserGames([...userGames, userGameRaw]);
  };

  const removePlayer = (userId: string) => {
    userGameHandler(userId, UserGameAction.DELETE);
    const newTeam = players?.filter((player) => player.id !== userId);
    setPlayers(newTeam);
  };

  const handleEditGame = async () => {
    const currentUserGames = players?.map((player) => {
      return {
        userId: player.id,
        gameId: props.gameId,
        team: player.team,
        role: player.role,
      };
    });

    const openGameEditData = {
      id: props.gameId,
      date: date !== '' ? date : game?.date,
      locationId: locationId !== '' ? locationId : game?.location.id,
      userGames: userGames,
      groupId: game?.groupId,
      isGameFinished: false,
      status: openStatus
        ? isGlobal
          ? GameStatus.STATUS_OPEN_GLOBAL
          : GameStatus.STATUS_OPEN_GROUP
        : GameStatus.STATUS_PENDING,
      statusVoting: mvpVotingStatus ?? false,
    };

    try {
      await Server.game.editGame(openGameEditData);
      currentUserGames?.map(
        async (userGame) => await Server.userGame.updateUserGame(userGame)
      );
      props.setReload(true);
      notification.success({
        message: t('edit_game.notifications.success_edit'),
      });
      props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING });
    } catch (error) {}
  };

  const handleDeleteGame = async () => {
    try {
      await Server.game.deleteGame(props.gameId);
      props.setReload(true);
      notification.success({
        message: t('edit_game.notifications.success_delete'),
      });
      props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING });
    } catch (error) {}
  };

  const handleCancelGame = async () => {
    try {
      await Server.game.cancelGame(props.gameId);
      props.setReload(true);
      notification.success({
        message: t('edit_game.notifications.success_cancel'),
      });
      props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING });
    } catch (error) {}
  };

  const handleSelectPlayers = (
    selectedOption: SelectOptionsProps,
    team: TeamEnum
  ) => {
    let allTeamIds;
    if (team === TeamEnum.team1) {
      allTeamIds = playersTeam1?.map((player) => player.id);
    } else {
      allTeamIds = playersTeam2?.map((player) => player.id);
    }

    if (!allTeamIds?.includes(selectedOption.value)) {
      const option = {
        id: selectedOption.value,
        name: selectedOption.label,
        team: team,
        role: PlayerRole.player,
      };

      players && setPlayers([...players, option]);
      userGameHandler(
        selectedOption.value,
        UserGameAction.CREATE,
        team,
        option.role
      );
    } else {
      const newSelectedPlayers = players?.filter(
        (player) => player.id !== selectedOption.value
      );
      removePlayer(selectedOption.value);
      setPlayers(newSelectedPlayers);
    }
  };

  const renderDeleteButton = () => {
    const now = dayjs();
    const oneDayBeforeGame = dayjs(game?.date).subtract(1, 'day');

    return now < oneDayBeforeGame ? (
      <Popconfirm
        title={t('edit_game.delete_title')}
        description={t('edit_game.delete_desc')}
        overlayStyle={{
          backgroundColor: `var(--cta-secondary-bg)`,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
        }}
        onConfirm={() => handleDeleteGame()}
        onCancel={() => {}}
        okText={t('common:confirm')}
        cancelText={t('common:cancel')}
      >
        <ButtonComponent className='danger-button' icon={<DeleteOutlined />} />
      </Popconfirm>
    ) : (
      <Popconfirm
        title={t('edit_game.cancel_title')}
        description={t('edit_game.cancel_desc')}
        overlayStyle={{
          backgroundColor: `var(--cta-secondary-bg)`,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
        }}
        onConfirm={() => handleCancelGame()}
        onCancel={() => {}}
        okText={t('common:confirm')}
        cancelText={t('common:cancel')}
      >
        <ButtonComponent className='danger-button' icon={<DeleteOutlined />} />
      </Popconfirm>
    );
  };

  return (
    <>
      <ButtonComponent
        className='secondary-button'
        icon={<ArrowLeftOutlined />}
        text={t('common:back')}
        onClick={() =>
          props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING })
        }
      />
      {!loading ? (
        <Card className={`card-cta-main p-0 mt-1`}>
          <Flex vertical gap={'10px'}>
            <Flex justify='space-between' align='center'>
              <Flex gap={'12px'}>
                {game?.status === GameStatus.STATUS_OPEN_GLOBAL ? (
                  <h3>
                    <Tooltip
                      title={t('edit_game.tooltips.open_global')}
                      destroyTooltipOnHide
                    >
                      <GlobalOutlined />
                    </Tooltip>
                  </h3>
                ) : (
                  <h3>
                    <Tooltip
                      title={t('edit_game.tooltips.open_group')}
                      destroyTooltipOnHide
                    >
                      <TeamOutlined />
                    </Tooltip>
                  </h3>
                )}
                <h3>{t(`common:game_types.${game?.type}`)}</h3>
              </Flex>
              {user?.id === game?.organizer?.id ? renderDeleteButton() : <></>}
            </Flex>
            {game ? (
              <EditDateLocation
                game={game}
                locations={options?.locations}
                setDate={setDate}
                setLocationId={setLocationId}
              />
            ) : (
              <></>
            )}
            {playersTeam1 && playersTeam2 && players && (
              <EditPlayers
                playersTeam1={playersTeam1}
                playersTeam2={playersTeam2}
                players={players}
                users={options?.users}
                handleSelectPlayers={handleSelectPlayers}
                handleSelectGoalkeepers={handleSelectGoalkeepers}
              />
            )}
            <Divider />
            <Flex
              justify='space-between'
              className='mt-1 edit-open-cards-container'
            >
              {applications && game ? (
                <Applications
                  applications={applications}
                  setReload={props.setReload}
                  game={game}
                />
              ) : (
                <></>
              )}
              <Card className={`card-main edit-open-card`}>
                <Divider>{t('edit_game.options').toUpperCase()}</Divider>
                <Flex justify='space-between' align='center' gap={'10px'}>
                  <p>{t('edit_game.mvp')}</p>
                  <Switch
                    onChange={() => setMvpVotingStatus(!mvpVotingStatus)}
                    checked={mvpVotingStatus}
                  />
                </Flex>
                <Flex justify='space-between' align='center' gap={'10px'}>
                  <p>{t('edit_game.close_ad')}</p>
                  <Switch
                    onChange={() => setOpenStatus(!openStatus)}
                    checked={!openStatus}
                  />
                </Flex>
                {game?.status === GameStatus.STATUS_OPEN_GROUP ? (
                  <Flex justify='space-between' align='center' gap={'10px'}>
                    <p>{t('edit_game.post_global')}</p>
                    <Switch
                      onChange={() => setIsGlobal(!isGlobal)}
                      disabled={!openStatus}
                    />
                  </Flex>
                ) : (
                  <></>
                )}
              </Card>
            </Flex>
            <ButtonComponent
              style={{ alignSelf: 'flex-end' }}
              className='primary-button mt-1'
              text={t('common:confirm')}
              onClick={() => handleEditGame()}
            />
          </Flex>
        </Card>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default EditOpenGame;
