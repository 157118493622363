import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutesDefinitions } from '../constants/routes/routes.constants';
import Profile from '../pages/profile/profile.component';
import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';

const PagesRouter = () => {
  const { groupId } = useContext(AuthContext);

  return (
    <Routes>
      {RoutesDefinitions.map((x) => {
        if (groupId || !x.groupNeeded) {
          return <Route key={x.path} path={x.path} element={x.element} />;
        } else return <></>;
      })}
      <Route path='/players/:id' element={<Profile />} />
      <Route path='*' element={<Navigate to='/dashboard' />} />
    </Routes>
  );
};

export default PagesRouter;
