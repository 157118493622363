import { LanguageEnum, ThemeEnum } from '../../types/enums.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserSettingsServer {
  private readonly basePath = `${serverUrl}user-settings`;

  // CHANGE LANGUAGE
  async changeLanguage(id: string, language: LanguageEnum) {
    const params = new URLSearchParams();
    params.append('id', id.toString());
    params.append('language', language.toString());

    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/language`,
        responseType: 'json',
        params: params,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  }

  // CHANGE THEME
  async changeTheme(id: string, theme: ThemeEnum) {
    const params = new URLSearchParams();
    params.append('id', id.toString());
    params.append('theme', theme.toString());

    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/theme`,
        responseType: 'json',
        params: params,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  }
}
