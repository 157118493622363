import Sider from 'antd/es/layout/Sider';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SidebarLinks } from '../../constants/routes/routes.constants';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import { Flex } from 'antd';

const Sidebar = () => {
  const { t } = useTranslation('sidebar');
  const { groupId } = useContext(AuthContext);

  return (
    <>
      <Sider
        style={{ backgroundColor: 'transparent' }}
        width={250}
        breakpoint='md'
        collapsedWidth='0'
        trigger={null}
      >
        <Flex vertical style={{ padding: '2rem' }}>
          {SidebarLinks.map((item, index) => {
            if (groupId || !item.groupNeeded) {
              return (
                <NavLink
                  to={item.path}
                  key={item.path}
                  style={{ fontSize: '1rem' }}
                >
                  <span className='gray-color me-s-1'>{item.icon} </span>
                  <span>{t(`${item.title}`)}</span>
                </NavLink>
              );
            } else return <></>;
          })}
        </Flex>
      </Sider>
      <div className={`mobile-nav`}>
        <nav className={`nav nav--icons`}>
          <ul>
            {SidebarLinks.filter((x) => x.isMainMenu).map((item, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={item.path}
                    key={index}
                    style={{ fontSize: '1rem' }}
                  >
                    <span className='gray-color'>{item.icon}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
