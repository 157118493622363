import { Col } from 'antd';
import { UserDto, VotingGameDto } from '../../../types/dto.types';
import { FC, useEffect, useState } from 'react';
import { Server } from '../../../server/server';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import VotingGame from './voting-game.component';
import { GameStatus } from '../../../types/enums.types';

interface VotingGamesProps {
  user: UserDto;
}

enum VotingMessage {
  FINISHED = 'Voting is finished',
}

const VotingGames: FC<VotingGamesProps> = (props) => {
  const { user } = props;
  const [votingGames, setVotingGames] = useState<VotingGameDto[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const calculateMvp = async (votingFinishedGames: VotingGameDto[]) => {
    for (const game of votingFinishedGames) {
      if (game.game.gameMVP === GameStatus.STATUS_PENDING) {
        await Server.voteMvp.calculateMvp(game.game.id);
      }
    }
  };

  useEffect(() => {
    const getVotingGames = async () => {
      setLoading(true);
      const votingResponse = await Server.game.getVotingGames({
        userId: user.id,
      });
      if (votingResponse) {
        const games = votingResponse.filter(
          (game) => game.votingMessage.status
        );
        setVotingGames(games);
      }

      const votingFinishedGames = votingResponse?.filter((game) => {
        return (
          game.game.statusVoting &&
          (game.votingMessage?.message === VotingMessage.FINISHED ||
            game.mvpEntries.length === game.game.userGames.length)
        );
      });

      if (votingFinishedGames?.length) {
        calculateMvp(votingFinishedGames);
      }

      setLoading(false);
    };

    getVotingGames();
  }, [user]);

  return !loading ? (
    <>
      {votingGames && votingGames.length > 0 ? (
        <Col span={24}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true, type: 'fraction' }}
            modules={[Pagination]}
          >
            {votingGames?.map((game) => (
              <SwiperSlide>
                <VotingGame votingGame={game} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default VotingGames;
