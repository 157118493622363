import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GameDto } from '../../types/dto.types';
import { BiddingChoice } from '../../types/enums.types';
import { Server } from '../../server/server';
import * as io from 'socket.io-client';
import { wsUrl } from '../../server/server.constants';
import { Divider, Flex, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';

interface BettingProps {
  game: GameDto;
}

const Betting: FC<BettingProps> = (props) => {
  const overlayStyle = useRef({
    backgroundColor: `var(--cta-secondary-bg)`,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
  });
  const [results, setResults] = useState({
    firstTeam: 0,
    secondTeam: 0,
    draw: 0,
  });
  const [totalBets, setTotalBets] = useState<number>(0);
  const [bidded, setBidded] = useState(false);
  const [expired, setExpired] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('betting');

  const didYouBid = useCallback(async () => {
    if (user) {
      const response = await Server.bidGame.getBidGameByIds(
        user.id,
        props.game.id
      );

      if (response && response.userChoice) {
        setBidded(true);
      }
    }
  }, [props.game.id, user]);

  const getResults = useCallback(async () => {
    const result = await Server.bidGame.calculateBidGame(props.game.id);
    if (result) {
      const sum = result.team1 + result.team2 + result.draw;
      setTotalBets(sum);
      if (sum === 0) return;
      setResults((prevState) => ({
        ...prevState,
        firstTeam: parseFloat(((100 * result.team1) / sum).toFixed(1)),
        secondTeam: parseFloat(((100 * result.team2) / sum).toFixed(1)),
        draw: parseFloat(((100 * result.draw) / sum).toFixed(1)),
      }));
    }
  }, [props.game.id]);

  const isExpired = useCallback(async () => {
    if ((new Date().getTime() - Date.parse(props.game.date)) / 1000 / 60 >= 0) {
      setExpired(true);
    }
  }, [props.game.date]);

  useEffect(() => {
    didYouBid();
    getResults();
    isExpired();
    const socket = io.connect(`${wsUrl}`, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: localStorage.getItem('BearerToken'),
          },
        },
      },
    });
    socket.emit('join', '');
    socket.on('refresh', () => {
      didYouBid();
      getResults();
    });
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [bidded, props.game, didYouBid, getResults, isExpired]);

  const handleVote = async (userChoice: BiddingChoice) => {
    if (user) {
      setIsLoading(true);

      const bidGame = {
        gameId: String(props.game.id),
        userChoice: userChoice,
        userId: user.id,
      };

      await Server.bidGame.createBidGame(bidGame);
      setBidded(true);
      setIsLoading(false);
    }
  };

  return !isLoading ? (
    <>
      <Divider>
        {bidded || expired ? (
          <h4 className='text-center m-0'>{`${t(
            'total_bets'
          ).toUpperCase()} (${totalBets})`}</h4>
        ) : (
          <h4 className='m-0'>{t('betting').toUpperCase()}</h4>
        )}
      </Divider>
      {bidded || expired ? (
        <div className='mt-1'>
          <div className='mt-1'>
            <Flex justify='space-between'>
              <div className='bet-button'>{`${results.firstTeam}%`}</div>
              <div className='bet-button'>{`${results.draw}%`}</div>
              <div className='bet-button'>{`${results.secondTeam}%`}</div>
            </Flex>
          </div>
        </div>
      ) : (
        <div className='mt-1'>
          <Flex justify='space-between'>
            <Popconfirm
              title={t('betting')}
              description={t('bet_confirm1')}
              overlayStyle={overlayStyle.current}
              onConfirm={() => handleVote(BiddingChoice.FIRST)}
              okText={t('common:confirm')}
              cancelText={t('common:cancel')}
            >
              <div className='bet-button'>{BiddingChoice.FIRST}</div>
            </Popconfirm>
            <Popconfirm
              title={t('betting')}
              description={t('bet_confirm_draw')}
              overlayStyle={overlayStyle.current}
              onConfirm={() => handleVote(BiddingChoice.DRAW)}
              okText={t('common:confirm')}
              cancelText={t('common:cancel')}
            >
              <div className='bet-button'>{BiddingChoice.DRAW}</div>
            </Popconfirm>
            <Popconfirm
              title={t('betting')}
              description={t('bet_confirm2')}
              overlayStyle={overlayStyle.current}
              onConfirm={() => handleVote(BiddingChoice.SECOND)}
              okText={t('common:confirm')}
              cancelText={t('common:cancel')}
            >
              <div className='bet-button'>{BiddingChoice.SECOND}</div>
            </Popconfirm>
          </Flex>
        </div>
      )}
    </>
  ) : (
    <Flex justify='space-between'>
      <Skeleton.Button active />
      <Skeleton.Button active />
      <Skeleton.Button active />
    </Flex>
  );
};

export default Betting;
