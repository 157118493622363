import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';
import { useContext } from 'react';
import { LanguageEnum } from '../../types/enums.types';
import { Server } from '../../server/server';

const LanguageSwitcher = () => {
  const { t } = useTranslation('profile');
  const { user, refreshUser } = useContext(AuthContext);
  const currentLanguage = localStorage.getItem('i18nextLng');

  const changeLanguage = async (language: LanguageEnum) => {
    if (currentLanguage === language) {
      return;
    }
    if (user) {
      try {
        await Server.userSettings.changeLanguage(
          user?.userSettings.id,
          language
        );
      } catch (e) {
      } finally {
        refreshUser();
      }
    }
  };

  return (
    <Switch
      checkedChildren={t('language.english')}
      unCheckedChildren={t('language.croatian')}
      defaultChecked={user?.userSettings.language === LanguageEnum.EN}
      onChange={(checked) => {
        checked
          ? changeLanguage(LanguageEnum.EN)
          : changeLanguage(LanguageEnum.HR);
      }}
    />
  );
};

export default LanguageSwitcher;
