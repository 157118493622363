import { Col, Empty, Flex, Table, TableProps } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UsersWithStatsDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { Link, useSearchParams } from 'react-router-dom';
import PlayersFilter from './filter/players-filter.component';
import GroupOwnerActions from './group-owner-actions/group-owner-actions.component';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/auth-context';
import Spinner from '../../../components/spinner/spinner.component';
import Title from '../../../components/title/title.component';
import Filter from '../../../components/filter/filter.component';
import dayjs from 'dayjs';
import { setFilterPath } from '../../../utilities/data/set-filter-path';
import { SortingOptions } from '../../../types/enums.types';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const Players = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { groupId, isGroupOwner, user } = useContext(AuthContext);
  const { t } = useTranslation('players');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [players, setPlayers] = useState<UsersWithStatsDto[]>([]);
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [filter, setFilter] = useState<{
    date: string;
    dateTo: string;
    gameTypeId: string;
    name: string;
    sorting?: SortingOptions;
  }>({
    date:
      searchParams.get('date') ??
      dayjs().subtract(1, 'month').format('DD-MM-YYYY'),
    dateTo: searchParams.get('dateTo') ?? dayjs().format('DD-MM-YYYY'),
    gameTypeId: searchParams.get('gameTypeId') ?? '',
    name: searchParams.get('name') ?? '',
    sorting:
      (searchParams.get('sorting') as SortingOptions) ??
      SortingOptions.PERCENTAGE,
  });
  const [filterCount, setFilterCount] = useState<number>(0);

  const countFilters = useCallback(() => {
    let count = 0;
    if (filter.date) {
      count++;
    }
    if (filter.dateTo) {
      count++;
    }
    if (filter.gameTypeId) {
      count++;
    }
    if (filter.name) {
      count++;
    }

    setFilterCount(count);
  }, [filter]);

  useEffect(() => {
    const fetchPlayers = async () => {
      if (groupId) {
        setIsLoading(true);
        let response = await Server.user.getUsersWithStats({
          date: filter.date
            ? dayjs(filter.date, 'DD-MM-YYYY').toISOString()
            : undefined,
          dateTo: filter.dateTo
            ? dayjs(filter.dateTo, 'DD-MM-YYYY').endOf('day').toISOString()
            : undefined,
          gameTypeId: filter.gameTypeId,
          groupId,
        });

        if (response) {
          if (filter.name) {
            response = response.filter((player) => {
              const fullName = `${player.firstName} ${player.lastName}`;
              return fullName.toLowerCase().includes(filter.name.toLowerCase());
            });
          }
          setPlayers(response);
        }
      }

      setIsLoading(false);
    };

    fetchPlayers();
    setSearchParams(setFilterPath(filter, page));
    countFilters();
    setIsLoading(false);
  }, [reload, filter, groupId, page, setSearchParams, countFilters]);

  const columnData: TableProps<UsersWithStatsDto>['columns'] = [
    {
      key: 'player',
      title: t('table.player').toUpperCase(),
      dataIndex: 'id',
      width: '30%',
      render: (id, record) => (
        <Link to={`/players/${id}`}>
          <span
            className={'teams-username'}
          >{`${record.firstName} ${record.lastName}`}</span>
        </Link>
      ),
    },
    {
      key: 'gamesPlayed',
      title: t('table.wins').toUpperCase(),
      dataIndex: 'gamesPlayed',
      width: '25%',
      render: (gamesPlayed, record) => `${record.gamesWon}% (${gamesPlayed})`,
      sorter: {
        compare: (a, b) => {
          return filter.sorting === SortingOptions.PERCENTAGE
            ? a.gamesWon - b.gamesWon
            : a.gamesPlayed - b.gamesPlayed;
        },
        multiple: 3,
      },
    },
    {
      key: 'betting',
      title: t('table.betting_results').toUpperCase(),
      dataIndex: 'betting',
      width: '25%',
      render: (betting, record) => `${record.bettingWon}% (${betting})`,
      sorter: {
        compare: (a, b) => {
          return filter.sorting === SortingOptions.PERCENTAGE
            ? a.bettingWon - b.bettingWon
            : a.betting - b.betting;
        },
        multiple: 3,
      },
    },
  ];

  if (isGroupOwner) {
    columnData.push({
      key: 'go_actions',
      title: t('table.group_owner_actions.label').toUpperCase(),
      dataIndex: 'id',
      render: (id, record) => {
        return isGroupOwner ? (
          <Flex justify='end'>
            <GroupOwnerActions
              player={record}
              reload={reload}
              setReload={setReload}
            />
          </Flex>
        ) : (
          <></>
        );
      },
      align: 'end',
    });
  }

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className='mb-2'>
      {groupId ? (
        <>
          <Title
            main={
              user?.userGroups.find((ug) => ug.groupId === groupId)?.groupName
            }
            desc1={t('player_stats')}
          />
          <div className='mt-1'>
            <Filter
              children={
                <Col span={24}>
                  <PlayersFilter
                    filter={filter}
                    setFilter={setFilter}
                    isLoading={isLoading}
                    groupId={groupId}
                  />
                </Col>
              }
              tip={t('filter.tooltip')}
              defaultOpen={false}
              count={filterCount}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {!isLoading ? (
        <>
          <div className='mt-1'></div>
          <Col span={24}>
            {players.length > 0 ? (
              <Table
                rowKey='id'
                columns={columnData}
                dataSource={players}
                className='players-table mt-1'
                style={{ background: 'transparent' }}
                pagination={{
                  position: ['bottomCenter'],
                  simple: true,
                  onChange: handlePageChange,
                  current: page,
                }}
                scroll={{ x: 1000 }}
              />
            ) : (
              <Flex
                justify='center'
                align='center'
                style={{ minHeight: '30vh' }}
              >
                <Empty
                  image={<EyeInvisibleOutlined />}
                  imageStyle={{ fontSize: '48px' }}
                  description={t('table.no_data')}
                />
              </Flex>
            )}
          </Col>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Players;
