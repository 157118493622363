import { FC, useContext, useRef, useState } from 'react';
import { SelectOptionsProps } from '../../types/props.types';
import { Input, InputRef, List } from 'antd';
import { TeamEnum } from '../../types/enums.types';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';

interface ChoosePlayersProps {
  title?: string;
  options?: SelectOptionsProps[] | null;
  team: TeamEnum.team1 | TeamEnum.team2;
  teamIds: string[];
  otherTeamIds: string[];
  className?: string;
  handleSelectPlayers: (
    selectedOption: SelectOptionsProps,
    team: TeamEnum
  ) => void;
}

const ChoosePlayers: FC<ChoosePlayersProps> = (props) => {
  const { t } = useTranslation('games');
  const { groupId } = useContext(AuthContext);
  const [searchPhrase, setSearchPhrase] = useState('');
  const inputRef = useRef<InputRef>(null);

  const isLockedInGroup = (option: SelectOptionsProps) => {
    if (
      option.userLocks?.some(
        (lock) =>
          lock.groupId === groupId &&
          new Date(lock.lockExpiration) >= new Date()
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isSelectedInTeam = (id: string) => {
    if (props.teamIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  const isSelectedInOtherTeam = (id: string) => {
    if (props.otherTeamIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='w-100'>
      <h3 style={{ alignSelf: 'center' }} className={`m-0 ${props.className}`}>
        {props.title}
      </h3>
      <Input
        placeholder={`${t('common:search')}`}
        value={searchPhrase}
        className='mt-1'
        onChange={(e) => setSearchPhrase(e.target.value)}
      />
      <List className={`scrollable-200 w-100 mt-1`}>
        {props.options?.map((option) => {
          return option.label
            .toLowerCase()
            .includes(searchPhrase.toLowerCase()) ? (
            <List.Item
              key={option.value}
              style={{ padding: '12px' }}
              onClick={() => {
                if (
                  isSelectedInTeam(option.value) ||
                  (!isSelectedInOtherTeam(option.value) &&
                    !isLockedInGroup(option))
                ) {
                  props.handleSelectPlayers(option, props.team);
                  setSearchPhrase('');
                  inputRef.current?.focus({ cursor: 'end' });
                }
              }}
            >
              <div
                className={
                  isSelectedInOtherTeam(option.value) ||
                  (isLockedInGroup(option) && !isSelectedInTeam(option.value))
                    ? 'disabled-player-select-item'
                    : ''
                }
              >
                {option.label}
              </div>
              {isLockedInGroup(option) ? (
                <StopOutlined style={{ color: 'var(--red)' }} />
              ) : (
                <>
                  {isSelectedInTeam(option.value) ? (
                    <CheckCircleOutlined />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </List.Item>
          ) : (
            <></>
          );
        })}
      </List>
    </div>
  );
};

export default ChoosePlayers;
