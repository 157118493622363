import {
  AddNewPlayerToGroupDto,
  GroupDto,
  GroupStatsDto,
  UpdateGroupDto,
} from '../../types/dto.types';
import axios from 'axios';
import { serverUrl } from '../server.constants';

export class GroupServer {
  private readonly basePath = `${serverUrl}group`;

  // GET GROUP BY ID
  async getGroupById(id: string): Promise<GroupDto | null> {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/group-by-id`,
        responseType: 'json',
        params: params,
      });

      return response.data as GroupDto;
    } catch (err) {
      return null;
    }
  }

  // ADD NEW PLAYER TO GROUP
  async addNewPlayer(newPlayer: AddNewPlayerToGroupDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/new-player`,
        responseType: 'json',
        data: newPlayer,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  // UPDATE GROUP (NAME OR GROUP RULES)
  async updateGroup(updatedGroup: UpdateGroupDto) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/update`,
        responseType: 'json',
        data: updatedGroup,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  // GET GROUP STATS
  async getGroupStats(id: string): Promise<GroupStatsDto | null> {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/get-group-stats`,
        responseType: 'json',
        params: params,
      });
      return response.data as GroupStatsDto;
    } catch (error) {
      return null;
    }
  }
}
