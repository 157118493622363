import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Card,
  Col,
  Flex,
  Modal,
  Popover,
  Row,
  Skeleton,
  Upload,
  message,
  notification,
} from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import { FC, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/auth-context';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { serverUrl } from '../../../server/server.constants';
import dayjs from 'dayjs';
import { Level } from '../../../types/enums.types';
import LevelsInfo from './levels-info/levels-info.component';

interface ProfileHeaderProps {
  name: string;
  ownProfile: boolean;
  profileImage?: string;
  memberSince: string;
  loading: boolean;
  gamesPlayed: number;
}

const ProfileHeader: FC<ProfileHeaderProps> = (props) => {
  const { user, refreshUser } = useContext(AuthContext);
  const { t } = useTranslation('profile');
  const [loading, setLoading] = useState<boolean>(false);
  const token = window.localStorage.getItem('BearerToken');
  const [level, setLevel] = useState<{ number: number; level: Level }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const calculateLevel = () => {
      const { gamesPlayed } = props;

      switch (true) {
        case gamesPlayed < 5:
          setLevel({ number: 1, level: Level.RECRUIT });
          break;
        case gamesPlayed < 15:
          setLevel({ number: 2, level: Level.CADET });
          break;
        case gamesPlayed < 30:
          setLevel({ number: 3, level: Level.SERGEANT });
          break;
        case gamesPlayed < 75:
          setLevel({ number: 4, level: Level.LIEUTENANT });
          break;
        case gamesPlayed < 150:
          setLevel({ number: 5, level: Level.CAPTAIN });
          break;
        case gamesPlayed < 250:
          setLevel({ number: 6, level: Level.MAJOR });
          break;
        case gamesPlayed < 400:
          setLevel({ number: 7, level: Level.COLONEL });
          break;
        default:
          setLevel({ number: 8, level: Level.GENERAL });
      }
    };

    calculateLevel();
  }, [props]);

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t('header.notifications.jpg_png'));
    }

    const compressedFile = await compressImage(file);
    if (compressedFile) {
      file = compressedFile;
    }

    return isJpgOrPng;
  };

  const handleChange: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      notification.success({
        key: user?.id,
        message: t('header.notifications.success'),
      });

      setLoading(false);
      refreshUser();
    }
    if (info.file.status === 'error') {
      notification.error({
        key: user?.id,
        message: t('header.notifications.error'),
      });
      setLoading(false);
    }
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 0.08, // max 80MB
      maxWidthOrHeight: 400,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile as RcFile;
    } catch (error) {
      message.error(t('header.notifications.comp_erro'));
    }
  };

  return (
    <Row style={{ marginTop: '1rem' }} gutter={16}>
      <Col span={24}>
        <Card className={`profile-header user-card`}>
          <Flex justify='space-between' align='end'>
            <div className='user-info d-flex align-items-center'>
              {!props.loading ? (
                props.ownProfile ? (
                  <div className='me-1'>
                    <Upload
                      name='avatar'
                      listType='picture-circle'
                      className='text-center'
                      method='PATCH'
                      action={`${serverUrl}user/update-profile-image`}
                      headers={{ Authorization: `Bearer ${token}` }}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      <Badge
                        count={<EditOutlined className='edit-badge' />}
                        offset={[-6, 10]}
                      >
                        {!loading ? (
                          <Avatar
                            src={
                              user?.profileImage ?? (
                                <h1 className='m-0'>{`${user?.firstName.charAt(
                                  0
                                )}${user?.lastName.charAt(0)}`}</h1>
                              )
                            }
                            size={100}
                            className='profile-avatar'
                          />
                        ) : (
                          <Avatar src={<LoadingOutlined />} size={100} />
                        )}
                      </Badge>
                    </Upload>
                  </div>
                ) : (
                  <div
                    className='me-1'
                    onClick={() => {
                      if (props.profileImage) setIsModalOpen(true);
                    }}
                  >
                    <Avatar
                      src={
                        props.profileImage ?? (
                          <h1 className='m-0'>{`${props.name
                            .split(' ')[0]
                            .charAt(0)}${props.name
                            .split(' ')
                            .slice(1)
                            .join(' ')
                            .charAt(0)}`}</h1>
                        )
                      }
                      size={100}
                      className='profile-avatar'
                    />
                  </div>
                )
              ) : (
                <div className='me-1'>
                  <Skeleton.Avatar
                    active
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              )}
              <Row>
                {!props.loading ? (
                  <Col span={24}>
                    <h1 className='m-0'>{props.name}</h1>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Skeleton.Input active size='large' />
                  </Col>
                )}
                {!props.loading ? (
                  <>
                    <Col span={24}>
                      <p className='text-small text-uppercase  m-0'>
                        {t('header.joined')}:{' '}
                        {dayjs(props.memberSince).format('DD.MM.YYYY.')}
                      </p>
                    </Col>
                    <Col span={24} className='levels-mobile'>
                      <Flex gap={8}>
                        <Popover
                          title={<LevelsInfo level={level} />}
                          placement='bottom'
                          trigger={'click'}
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                        <span className='text-uppercase m-0'>
                          {t('header.level.title')}:{' '}
                          {t(`header.level.${level?.level}`)}
                        </span>
                      </Flex>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <Skeleton.Input active />
                  </Col>
                )}
              </Row>
            </div>
            <div className='levels-desktop'>
              <LevelsInfo level={level} />
            </div>
          </Flex>
        </Card>
      </Col>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
      >
        <img
          alt='profile-avatar'
          src={props.profileImage}
          className='profile-avatar-large'
        ></img>
      </Modal>
    </Row>
  );
};

export default ProfileHeader;
