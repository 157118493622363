import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../context/auth-context';
import BarChartComponent from '../../../../components/bar-chart/bar-chart.component';
import { BarChartData } from '../../../../types/dto.types';
import { Server } from '../../../../server/server';
import { Card, Col, Flex, Row, Select, Tooltip } from 'antd';
import { GameState, TeamEnum } from '../../../../types/enums.types';
import ButtonComponent from '../../../../components/buttons/button.component';
import SelectGameType from '../../../../components/select-game-type/select-game-type.component';
import { InfoCircleOutlined } from '@ant-design/icons';

const StatsByTeam = () => {
  const { t } = useTranslation('stats');
  const { groupId } = useContext(AuthContext);
  const [chartData, setChartData] = useState<BarChartData[]>([]);
  const [filter, setFilter] = useState<{
    team: TeamEnum;
    result: GameState | 'ALL';
    gameTypeId: string;
  }>({ team: TeamEnum.none, result: 'ALL', gameTypeId: '' });

  const fetchGroupActivity = useCallback(async () => {
    if (groupId) {
      const response = await Server.game.getActivityForGroup(
        groupId,
        filter.team,
        filter.result,
        filter.gameTypeId
      );
      if (response) {
        setChartData(response);
      }
    }
  }, [filter, groupId]);

  useEffect(() => {
    fetchGroupActivity();
  }, [fetchGroupActivity]);

  return (
    <Card className='card-cta-secondary p-0 group-stats-card'>
      <Flex gap={12}>
        <h3>{t('general_stats.group_activity.title')}</h3>
        <Tooltip title={t('general_stats.group_activity.tooltip')}>
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Row align='top' gutter={[{ xs: 0, sm: 0, md: 0, lg: 16, xl: 16 }, 12]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Select
            className='w-100'
            value={filter.team}
            placeholder={t('general_stats.team')}
            options={Object.values(TeamEnum).map((o) => ({
              value: o,
              label: t(`common:${o}`),
            }))}
            onChange={(value) => {
              if (value === TeamEnum.none) {
                setFilter((prevFilter) => ({ ...prevFilter, result: 'ALL' }));
              }
              setFilter((prevFilter) => ({ ...prevFilter, team: value }));
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Select
            disabled={filter.team === TeamEnum.none}
            className='w-100'
            value={filter.result}
            placeholder={t('general_stats.result')}
            options={[
              ...Object.values(GameState).map((o) => ({
                value: o,
                label: t(`general_stats.${o}`),
              })),
              { value: 'ALL', label: t('general_stats.ALL') },
            ]}
            onChange={(value) =>
              setFilter((prevFilter) => ({ ...prevFilter, result: value }))
            }
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <SelectGameType
            className='w-100'
            value={filter.gameTypeId ? filter.gameTypeId : null}
            onChange={(value) =>
              setFilter((prevFilter) => ({ ...prevFilter, gameTypeId: value }))
            }
          />
        </Col>
      </Row>
      <Flex justify='end' className='mt-1'>
        <ButtonComponent
          className='secondary-button'
          text={t('common:reset_filter')}
          onClick={() =>
            setFilter({ team: TeamEnum.none, result: 'ALL', gameTypeId: '' })
          }
        />
      </Flex>
      <div className='mt-2'>
        <BarChartComponent
          data={chartData}
          unit={t('general_stats.bar_chart_label')}
          height={190}
        />
      </div>
    </Card>
  );
};

export default StatsByTeam;
