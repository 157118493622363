export const GameTypeImages = {
  Futsal: `assets/futsal.jpg`,
  CageBall: `assets/cageball.jpg`,
  Tennis: `assets/tennis.jpg`,
  Basketball: `assets/basketball.jpg`,
  TableTennis: `assets/table-tennis.jpg`,
  Padel: `assets/padel.jpg`,
  Volleyball: `assets/volleyball.jpg`,
  Basket: `assets/basket.jpg`,
};
