import { BellOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Empty,
  Flex,
  List,
  Row,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { NotificationDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { NotificationStatus } from '../../../types/enums.types';
import { calculateNotifDate } from '../../../utilities/data/notif-date';
import * as io from 'socket.io-client';
import { wsUrl } from '../../../server/server.constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Notifications = () => {
  const { t } = useTranslation('header');

  const [notifications, setNotifications] = useState<NotificationDto[]>();
  const [newCount, setNewCount] = useState<number>(0);

  const fetchNotifications = useCallback(async () => {
    const response = await Server.notification.getNotificationsForUser();
    setNewCount(
      response?.filter(
        (notification) => notification.status === NotificationStatus.CREATED
      ).length
    );
    setNotifications(response);
  }, []);

  useEffect(() => {
    fetchNotifications();
    const socket = io.connect(`${wsUrl}`, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: localStorage.getItem('BearerToken'),
          },
        },
      },
    });
    socket.emit('join', '');
    socket.on('refresh', () => {
      fetchNotifications();
    });
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [fetchNotifications]);

  const seeNotifications = useCallback(async () => {
    if (newCount > 0) {
      try {
        await Server.notification.seeNotifications();
        fetchNotifications();
      } catch (error) {}
    }
  }, [fetchNotifications, newCount]);

  const NotificationItems = () => {
    return (
      <Flex vertical className={`notification-dropdown`}>
        <List
          split={true}
          locale={{
            emptyText: (
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '48px' }}
                description={t('notifications.no_data')}
              />
            ),
          }}
          dataSource={notifications}
          renderItem={(item) =>
            item.url ? (
              <Link to={item.url}>
                <Row
                  key={item.id}
                  className={`notification notif-hl`}
                  gutter={{ xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }}
                >
                  <Col span={4}>
                    {item.profileImage ? (
                      <Avatar src={item.profileImage} size={46} />
                    ) : (
                      <Avatar size={46} className='default-notif-icon'>
                        <BellOutlined style={{ fontSize: '18px' }} />
                      </Avatar>
                    )}
                  </Col>
                  <Col span={20}>
                    <Flex vertical>
                      <div>{item.message}</div>
                      <div className='gray-color'>
                        {calculateNotifDate(item.createdDate)}
                      </div>
                    </Flex>
                  </Col>
                </Row>
              </Link>
            ) : (
              <Row
                key={item.id}
                className={`notification`}
                gutter={{ xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }}
              >
                <Col span={4}>
                  {item.profileImage ? (
                    <Avatar src={item.profileImage} size={46} />
                  ) : (
                    <Avatar size={46} className='default-notif-icon'>
                      <BellOutlined style={{ fontSize: '18px' }} />
                    </Avatar>
                  )}
                </Col>
                <Col span={20}>
                  <Flex vertical>
                    <div>{item.message}</div>
                    <div className='gray-color'>
                      {calculateNotifDate(item.createdDate)}
                    </div>
                  </Flex>
                </Col>
              </Row>
            )
          }
        />
      </Flex>
    );
  };

  return (
    <Dropdown
      dropdownRender={() => <NotificationItems />}
      trigger={['click']}
      destroyPopupOnHide
      arrow
      onOpenChange={seeNotifications}
    >
      <Badge count={newCount} style={{ color: '#fff' }} offset={[-2, 2]}>
        <Button className={`header-notif-button`} icon={<BellOutlined />} />
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
