import { StatsOverviewData } from '../../types/dto.types';

export const countGames = (stats: StatsOverviewData): number => {
  let gameCount: number = 0;
  Object.entries(stats).map(([gameType, gameTypeStats]) => {
    return (gameCount += gameTypeStats.gamesPlayedOfType);
  });

  return gameCount;
};
