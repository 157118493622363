import { useContext, useState } from 'react';
import { Server } from '../../../../server/server';
import { Col, Flex, Form, Input, Row, Tooltip, notification } from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../context/auth-context';

const CreateNewPlayer = () => {
  const { groupId, user } = useContext(AuthContext);
  const { t } = useTranslation('players');
  const [newPlayerData, setNewPlayerData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
  }>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const submitNewPlayer = async () => {
    if (groupId && user) {
      const sendInviteData = {
        ...newPlayerData,
        groupId: groupId,
        adminFirstName: user.firstName,
        adminLastName: user.lastName,
      };
      try {
        await Server.user.sendInvite(sendInviteData);
        setNewPlayerData({ firstName: '', lastName: '', email: '' });
        notification.success({ message: t('new_player.create.success') });
      } catch (error) {}
    }
  };

  const handleFormValuesChange = (changedValues: any) => {
    setNewPlayerData((prevNewPlayerData) => ({
      ...prevNewPlayerData,
      ...changedValues,
    }));
  };
  return (
    <>
      <Flex gap={'12px'} justify='center'>
        <h3>{t('new_player.create.title').toUpperCase()}</h3>
        <Tooltip title={t('new_player.create.tip')} placement='right'>
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Row justify='center'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <Form
            name='addNewPlayer'
            onFinish={submitNewPlayer}
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item
              name='firstName'
              rules={[
                { required: true, message: t('new_player.create.required') },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='middle'
                className='mt-3'
                placeholder={t('new_player.create.firstName')}
              />
            </Form.Item>
            <Form.Item
              name='lastName'
              rules={[
                { required: true, message: t('new_player.create.required') },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='middle'
                className='mt-3'
                placeholder={t('new_player.create.lastName')}
              />
            </Form.Item>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: t('new_player.create.required') },
                {
                  type: 'email',
                  message: t('new_player.create.email_invalid'),
                },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='middle'
                className='mt-3'
                placeholder={t('new_player.create.email')}
              />
            </Form.Item>
            <Form.Item>
              <ButtonComponent
                className='primary-button'
                style={{ width: '100%' }}
                text={t('new_player.submit')}
                htmlType='submit'
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateNewPlayer;
