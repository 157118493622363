import { useState } from 'react';
import { Server } from '../../../server/server';
import { SignUpDto } from '../../../types/dto.types';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  notification,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('auth');
  const [registerData, setRegisterData] = useState<SignUpDto>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    height: '',
    weight: '',
    adress: '',
    city: '',
    country: '',
    phone: '',
    dateOfBirth: '',
    gender: '',
  });

  const handleFormValuesChange = (changedValues: any) => {
    if ('dateOfBirth' in changedValues) {
      if (changedValues.dateOfBirth) {
        setRegisterData((prevRegisterData) => ({
          ...prevRegisterData,
          dateOfBirth: changedValues.dateOfBirth.format('YYYY-MM-DD'),
        }));
      }
    } else {
      setRegisterData((prevRegisterData) => ({
        ...prevRegisterData,
        ...changedValues,
      }));
    }
  };

  const submitRegister = async () => {
    try {
      setLoading(true);
      await Server.user.createUser({
        ...registerData,
        email: registerData.email.toLowerCase().trim(),
      });
      notification.success({
        message: t('register.success_msg'),
        description: t('register.success_dsc'),
        duration: 10,
      });
      navigate('/login');
    } catch (err) {
      setLoading(false);
      return err;
    }
    setLoading(false);
  };

  return (
    <Flex justify='center' align='center' className='min-vh-100'>
      <Card className='container login-wrap card-main p-0 mt-1'>
        <Row gutter={{ xs: 0, sm: 0, md: 16 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            className='bg-dark-img d-flex align-items-center space-around p-1'
          >
            <div>
              <h3 className='logo-temp m-0'>Balunko</h3>
              <p className='text-center'>Pronađi termin i igraj.</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} className='py-lg-5'>
            <Form
              name='register'
              className='register-form p-1'
              onFinish={submitRegister}
              onValuesChange={handleFormValuesChange}
            >
              <h1 className='m-0 bold text-center'>
                {t('register.sign_up_title')}
              </h1>
              <Row justify='space-between' className='mt-1'>
                <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
                  <Form.Item
                    name='firstName'
                    rules={[{ required: true, message: t('field_required') }]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      size='middle'
                      className='w-100 mt-3'
                      placeholder={t('register.first_name')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
                  <Form.Item
                    name='lastName'
                    rules={[{ required: true, message: t('field_required') }]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      size='middle'
                      className=' w-100 mt-3'
                      placeholder={t('register.last_name')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name='email'
                rules={[
                  { required: true, message: t('field_required') },
                  { type: 'email', message: t('email_invalid') },
                ]}
                validateTrigger='onBlur'
              >
                <Input
                  size='middle'
                  className='w-100 mt-3'
                  placeholder={t('e_mail')}
                />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  { required: true, message: t('field_required') },
                  { min: 8, message: t('pass_invalid') },
                ]}
                validateTrigger='onBlur'
              >
                <Input.Password
                  size='middle'
                  className='w-100 mt-3'
                  placeholder={t('password')}
                />
              </Form.Item>
              <Form.Item
                name='confirm_password'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('field_required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('register.pass_no_match'))
                      );
                    },
                  }),
                ]}
                validateTrigger='onBlur'
              >
                <Input.Password
                  size='middle'
                  className='w-100 mt-3'
                  placeholder={t('register.confirm_password')}
                />
              </Form.Item>
              <Divider />
              <Row justify='space-between'>
                <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
                  <Form.Item name='gender'>
                    <Select
                      size='middle'
                      className='mt-3'
                      options={[
                        { value: 'Male', label: t('register.Male') },
                        { value: 'Female', label: t('register.Female') },
                      ]}
                      placeholder={t('register.gender')}
                      style={{ height: '45px' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
                  <Form.Item name='dateOfBirth'>
                    <DatePicker
                      inputReadOnly
                      size='middle'
                      className='w-100 mt-3'
                      placeholder={t('register.date_of_birth')}
                      format={'DD.MM.YYYY.'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'space-between'}>
                <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
                  <Form.Item
                    name='height'
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        message: t('register.numeric'),
                      },
                    ]}
                  >
                    <Input
                      className='w-100'
                      suffix={'cm'}
                      placeholder={t('register.height')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
                  <Form.Item
                    name='weight'
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        message: t('register.numeric'),
                      },
                    ]}
                  >
                    <Input
                      className='w-100'
                      suffix={'kg'}
                      placeholder={t('register.weight')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'space-between'}>
                <Col xs={24} lg={7} xxl={7}>
                  <Form.Item name='adress'>
                    <Input
                      size='middle'
                      className='w-100 mt-3'
                      placeholder={t('register.address')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={7} xxl={7}>
                  <Form.Item name='city'>
                    <Input
                      size='middle'
                      className='w-100 mt-3'
                      placeholder={t('register.city')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={7} xxl={7}>
                  <Form.Item name='country'>
                    <Input
                      size='middle'
                      className='w-100 mt-3'
                      placeholder={t('register.country')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name='phone'>
                <Input
                  size='middle'
                  className='w-100 mt-3'
                  placeholder={t('register.phone')}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType='submit'
                  className='primary-button'
                  style={{ width: '100%' }}
                  loading={loading}
                >
                  {t('register.sign_up_title').toUpperCase()}
                </Button>
              </Form.Item>
              <div className='text-center'>
                {t('register.already_account').toUpperCase()} <br />
                <Link to='/login'>
                  {t('register.sign_in_sugg').toUpperCase()}
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};

export default Register;
