import { useCallback, useContext, useEffect, useState } from 'react';
import { GroupStatsDto } from '../../../types/dto.types';
import { AuthContext } from '../../../context/auth-context';
import { Server } from '../../../server/server';
import { Card, Col, Empty, Flex, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PieChartComponent from '../../../components/pie-chart/pie-chart.component';
import Title from '../../../components/title/title.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const GeneralGroupStats = () => {
  const { groupId, user } = useContext(AuthContext);
  const { t } = useTranslation('stats');
  const [groupStats, setGroupStats] = useState<GroupStatsDto>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchGroupStats = useCallback(async () => {
    const response = groupId && (await Server.group.getGroupStats(groupId));
    if (response) {
      setGroupStats(response);
    }
  }, [groupId]);

  useEffect(() => {
    setLoading(true);
    fetchGroupStats();
    setLoading(false);
  }, [fetchGroupStats]);

  return (
    <Card
      className={`card-cta-main group-stats-card${
        groupStats && groupStats?.gamesPlayed > 0 ? '' : '-empty'
      } `}
    >
      {!loading ? (
        <Row
          gutter={[
            0,
            groupStats && groupStats.gamesPlayed > 0
              ? { xs: 56, sm: 56, md: 0, lg: 0, xl: 0 }
              : 56,
          ]}
        >
          <Col span={24}>
            <Title
              main={
                user?.userGroups.find((ug) => ug.groupId === groupId)?.groupName
              }
              desc1={
                groupStats && groupStats?.gamesPlayed > 0
                  ? `${t('general_stats.games_played')}: ${
                      groupStats?.gamesPlayed
                    }`
                  : ''
              }
            />
          </Col>
          <Col span={24} className='mt-2'>
            {groupStats && groupStats.gamesPlayed > 0 ? (
              <Row gutter={[32, 32]}>
                <Col span={24}>
                  <PieChartComponent
                    height={230}
                    data={Object.entries(groupStats.statsByType).map(
                      ([gameType, gameTypeStats]) => {
                        return {
                          name: t(`common:game_types.${gameType}`),
                          value: Number(gameTypeStats.gamesPlayedOfType),
                          fill: `var(--${gameType})`,
                        };
                      }
                    )}
                  />
                </Col>
              </Row>
            ) : (
              <Flex align='center' justify='center'>
                <Empty
                  image={<EyeInvisibleOutlined />}
                  imageStyle={{ fontSize: '42px' }}
                  description={<p>{t('general_stats.no_data')}</p>}
                />
              </Flex>
            )}
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </Card>
  );
};

export default GeneralGroupStats;
