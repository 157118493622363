import { useContext, useRef, useState } from 'react';
import {
  ComparisonFilterDto,
  ComparisonStatsDto,
  GamesWithPageDto,
} from '../../../types/dto.types';
import ComparePlayersFilter from './filter/compare-players-filter.component';
import { Divider, notification } from 'antd';
import { Server } from '../../../server/server';
import { calculateCompareStats } from '../../../utilities/data/calculate-compare-stats';
import { calculateDates } from '../../../utilities/data/calculate-comparison-dates';
import CompareMainInfo from './compare-main-info/compare-main-info.component';
import CompareGame from './compare-game/compare-game.component';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/spinner/spinner.component';
import { AuthContext } from '../../../context/auth-context';

const ComparePlayers = () => {
  const { groupId } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation(['stats', 'games', 'dashboard']);
  const [games, setGames] = useState<GamesWithPageDto>({ games: [], count: 0 });
  const [dates, setDates] = useState<string[]>();
  const compareContainerRef = useRef<HTMLDivElement>(null);

  const [baseStats, setBaseStats] = useState<{
    data: ComparisonStatsDto;
    team1: string[];
    team2: string[];
  }>({
    data: {
      gamesPlayed: 0,
      gamesWon: 0,
      gamesLost: 0,
      gamesDraw: 0,
      mvpCountLeft: 0,
      mvpCountRight: 0,
    },
    team1: [],
    team2: [],
  });

  const gameFetch = async (filterData: ComparisonFilterDto) => {
    setLoading(true);
    if (filterData.team1.length === 0) {
      notification.warning({
        key: `${filterData.team1}`,
        message: t('compare_players.warning'),
        description: t('compare_players.warning_desc'),
      });
      setLoading(false);
      return;
    }

    if (groupId) {
      const gameFilter = {
        groupId: groupId,
        date: filterData.date,
        dateTo: filterData.dateTo,
        gameTypeId: filterData.gameTypeId,
        players: [
          filterData.team1.map((player) => player.id),
          filterData.team2.map((player) => player.id),
        ],
      };

      const gamesResponse = await Server.game.getGamesForComparison(gameFilter);
      const comparisonData =
        gamesResponse &&
        gameFilter.players &&
        calculateCompareStats(gamesResponse, gameFilter.players);

      if (gamesResponse) {
        setGames(gamesResponse);
        setDates(calculateDates(gamesResponse.games));
      }

      if (comparisonData) {
        setBaseStats({
          data: comparisonData,
          team1: filterData.team1.map((player) => player.name),
          team2: filterData.team2.map((player) => player.name),
        });
      }
    }

    if (compareContainerRef.current) {
      compareContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setLoading(false);
  };

  const resetResults = () => {
    setGames({ games: [], count: 0 });
    setBaseStats({
      data: {
        gamesPlayed: 0,
        gamesWon: 0,
        gamesLost: 0,
        gamesDraw: 0,
        mvpCountLeft: 0,
        mvpCountRight: 0,
      },
      team1: [],
      team2: [],
    });
  };

  return (
    <>
      <Divider>{t('compare_players.title').toUpperCase()}</Divider>
      <ComparePlayersFilter gameFetch={gameFetch} resetResults={resetResults} />
      {!loading ? (
        <>
          {baseStats.team1.length > 0 && dates ? (
            <CompareMainInfo baseStats={baseStats} dates={dates} />
          ) : (
            <></>
          )}
          {games.count > 0 ? <CompareGame games={games} /> : <></>}
        </>
      ) : (
        <Spinner />
      )}
      <div ref={compareContainerRef}></div>
    </>
  );
};

export default ComparePlayers;
