import { notification } from 'antd';
import axios from 'axios';
import i18n from '../i18next';

axios.interceptors.request.use((config) => {
  const authToken = window.localStorage.getItem('BearerToken');
  if (authToken != null) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorResponse = error.response;
    if (errorResponse != null && errorResponse.data != null) {
      const { message, statusCode } = errorResponse.data;
      const messageTranslated = i18n.t(message, { ns: 'errors' });

      notification.error({
        key: statusCode,
        message: messageTranslated,
        duration: 10,
      });
    } else if (error.request != null) {
      notification.error({
        key: `${error.request}`,
        message: `Network Connection Error`,
        description: `Server unavailable`,
        duration: 10,
      });
    } else if (error.message != null) {
      notification.error({
        key: `${error.message}`,
        message: `Error`,
        description: `${error.message}`,
        duration: 10,
      });
    }

    return Promise.reject(error);
  }
);
