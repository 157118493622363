import { FC, useState } from 'react';
import { GameDto } from '../../../../types/dto.types';
import { Avatar, Button, Card, Col, Flex, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { GameTypeImages } from '../../../../common/game-type-images';
import LastGameDetails from './last-game-details.component';

interface LastGameProps {
  game: GameDto;
}

const LastGame: FC<LastGameProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(['dashboard', 'betting']);

  return (
    <>
      <Card className={`card-main`}>
        <div className='card-main-info mt-1'>
          <Row className='align-items-center space-between' gutter={[24, 24]}>
            <Col span={12}>
              <div className='d-flex space-between align-items-center'>
                <div className='me-1'>
                  <Avatar
                    src={
                      <img
                        src={
                          GameTypeImages[
                            props.game.type as keyof typeof GameTypeImages
                          ]
                        }
                        alt='avatar'
                      />
                    }
                    size={80}
                  />
                </div>
                <div>
                  <div className='text-small bold text-uppercase'>
                    {t(`common:game_types.${props.game.type}`)}
                  </div>
                  <p className='m-0'>
                    {dayjs(props.game.date).format('DD.MM.YYYY.')}
                  </p>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <Flex align='center' justify='space-evenly'>
                <Avatar
                  style={{ backgroundColor: '#000', fontWeight: 'bold' }}
                  size={40}
                >
                  {props.game.team1Goals}
                </Avatar>
                <h3>-</h3>
                <Avatar
                  style={{
                    backgroundColor: '#fff',
                    color: '#000',
                    fontWeight: 'bold',
                  }}
                  size={40}
                >
                  {props.game.team2Goals}
                </Avatar>
              </Flex>
            </Col>
          </Row>
        </div>
        <div className='card-main-footer mt-1'>
          <div className='text-center'>
            <Button
              className='secondary-button'
              onClick={() => setIsModalOpen(true)}
            >
              {t('common:details')}
            </Button>
            <Modal
              open={isModalOpen}
              footer={null}
              destroyOnClose
              onCancel={() => setIsModalOpen(false)}
            >
              <LastGameDetails game={props.game} />
            </Modal>
          </div>
        </div>
      </Card>
    </>
  );
};

export default LastGame;
