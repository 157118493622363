import { Dispatch, FC, SetStateAction } from 'react';
import { GameDto } from '../../../types/dto.types';
import { TeamEnum } from '../../../types/enums.types';
import { Card, Col, Flex, Row } from 'antd';
import Betting from '../../../components/betting/betting.component';
import Teams from '../../../components/teams/teams.component';
import { dayName } from '../../../utilities/data/day-name';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface PendingGameProps {
  game: GameDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const PendingGame: FC<PendingGameProps> = (props) => {
  const { t } = useTranslation(['dashboard', 'betting']);
  const day = dayName(props.game.date);

  const playersTeam1 = props.game.userGames.filter(
    (player) => player.team === TeamEnum.team1
  );
  const playersTeam2 = props.game.userGames.filter(
    (player) => player.team === TeamEnum.team2
  );

  return (
    <Card
      className={`card-cta-main p-0 dashboard-card mt-1`}
      style={{ width: '100%' }}
    >
      <div>
        {/* INFO HEADER */}
        <Flex vertical>
          <h3 className='m-0 bold text-uppercase text-italic'>
            {t(`common:game_types.${props.game.type}`)}
          </h3>
          <span className='text-small bold text-uppercase'>
            {props.game.groupName}
          </span>
        </Flex>

        {/* DETAILS */}
        <Row className='align-items-center space-between'>
          <Col span={8}>
            <p>
              {props.game.location.address}
              <br />
              <span>@{props.game.location.name}</span>
            </p>
          </Col>
          <Col span={8}>
            <h1 className='m-0 text-center'>
              {dayjs(props.game.date).format('HH:mm')}
            </h1>
          </Col>
          <Col className='text-small text-end' span={8}>
            {dayjs(props.game.date).format('DD.MM.YYYY.')}
            <br />
            <span className='text-small text-uppercase'>
              {t(`common:days.${day}`)}
            </span>
          </Col>
        </Row>
      </div>

      {/* TEAMS */}
      <Teams
        playersTeam1={playersTeam1}
        playersTeam2={playersTeam2}
        game={props.game}
        setReload={props.setReload}
      />

      {/* BETTING */}
      <Betting game={props.game} />
    </Card>
  );
};

export default PendingGame;
