export enum UserRole {
  admin = 'admin',
  user = 'user',
}

export enum GameState {
  WIN = 'WIN',
  LOSS = 'LOSS',
  DRAW = 'DRAW',
}

export enum TeamEnum {
  team1 = 'team1',
  team2 = 'team2',
  none = 'none',
}

export enum PlayerRole {
  player = 'player',
  goalkeeper = 'goalkeeper',
  player1 = 'player1',
  player2 = 'player2',
  player3 = 'player3',
  player4 = 'player4',
  sub1 = 'sub1',
  sub2 = 'sub2',
  sub3 = 'sub3',
  sub4 = 'sub4',
}

export enum GameStatus {
  STATUS_FINISHED = 'Finished',
  STATUS_PENDING = 'Pending',
  STATUS_OPEN_GROUP = 'Open_Group',
  STATUS_OPEN_GLOBAL = 'Open_Global',
}

export enum UserGameAction {
  CREATE = 'create',
  DELETE = 'delete',
}

export enum BiddingChoice {
  FIRST = '1',
  DRAW = 'X',
  SECOND = '2',
}

export enum LockType {
  APP = 'global',
  GROUP = 'group',
}

export enum LockReason {
  PAY = 'did-not-pay',
  SHOW = 'did-not-show',
  RULES = 'rules-violation',
  OTHER = 'other',
}

export enum LanguageEnum {
  EN = 'en',
  HR = 'hr',
}

export enum ThemeEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ApplicationStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum NotificationStatus {
  CREATED = 'CREATED',
  SEEN = 'SEEN',
}

export enum GameTypeCategory {
  FOOTBALL = 'FOOTBALL',
  BASKETBALL = 'BASKETBALL',
  TENNIS = 'TENNIS',
  VOLLEYBALL = 'VOLLEYBALL',
}

export enum SortingOptions {
  TOTAL = 'TOTAL',
  PERCENTAGE = 'PERCENTAGE',
}

export enum Level {
  RECRUIT = 'RECRUIT', // < 5
  CADET = 'CADET', // 5 - 10
  SERGEANT = 'SERGEANT', // 10 - 30
  LIEUTENANT = 'LIEUTENANT', // 30 - 50
  CAPTAIN = 'CAPTAIN', // 50 - 100
  MAJOR = 'MAJOR', // 100 - 200
  COLONEL = 'COLONEL', // 200 - 300
  GENERAL = 'GENERAL', // > 300
}
