import { FC } from 'react';
import { Legend, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';

interface PieChartProps {
  data: any[];
  height?: number;
  animationDuration?: number;
}

const PieChartComponent: FC<PieChartProps> = (props) => {
  return (
    <ResponsiveContainer width='100%' height={props.height ?? 270}>
      <PieChart key={Math.random()}>
        <Pie
          animationDuration={props.animationDuration}
          dataKey='value'
          data={props.data}
          stroke='none'
          label
        ></Pie>
        <Tooltip />
        <Legend align='center' />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
