import {
  BarChartData,
  CreateUserGameDto,
  PlayerGroupStatsDto,
} from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserGameServer {
  private readonly basePath = `${serverUrl}user-game`;

  // UPDATE USER GAME
  async updateUserGame(updatedUserGame: CreateUserGameDto) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/update`,
        responseType: 'json',
        data: updatedUserGame,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  }

  // GET ACTIVITY FOR USER
  async getActivity(
    userId: string,
    groupId?: string
  ): Promise<BarChartData[] | null> {
    const params = new URLSearchParams();
    params.append('userId', userId);

    if (groupId) {
      params.append('groupId', groupId);
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/activity`,
        responseType: 'json',
        params: params,
      });

      return response.data as BarChartData[];
    } catch (err) {
      return null;
    }
  }

  // LEAVE GAME
  async leaveGame(gameId: string) {
    const params = new URLSearchParams();
    params.append('gameId', gameId);

    const response = await axios({
      method: 'DELETE',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // GET PLAYER GROUP STATS
  async getPlayerGroupStats(
    groupId: string,
    userId: string
  ): Promise<PlayerGroupStatsDto | null> {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('groupId', groupId);

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/get-player-group-stats`,
        responseType: 'json',
        params: params,
      });
      return response.data as PlayerGroupStatsDto;
    } catch (error) {
      return null;
    }
  }
}
