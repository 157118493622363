import dayjs from 'dayjs';

export const calculateNotifDate = (date: Date) => {
  const now = dayjs();
  const pastDate = dayjs(date);
  const diffMinutes = now.diff(pastDate, 'minute');
  const diffHours = now.diff(pastDate, 'hour');
  const diffDays = now.diff(pastDate, 'day');

  if (diffMinutes < 60) {
    return `${diffMinutes} min`;
  } else if (diffHours < 24) {
    return `${diffHours} h`;
  } else if (diffDays < 7) {
    return `${diffDays} d`;
  } else {
    return pastDate.format('DD.MM.YYYY HH:mm');
  }
};
