import {
  GameTypeDto,
  LeaderboardDto,
  RateUserDto,
  UserRatingDto,
} from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class RatingServer {
  private readonly basePath = `${serverUrl}user-rating`;

  // RATE USER
  async rateUser(rateUserDto: RateUserDto) {
    const response = await axios({
      method: 'POST',
      url: this.basePath,
      responseType: 'json',
      data: rateUserDto,
    });

    return response.data;
  }

  // GET AVERAGE RATINGS FOR ALL TYPES
  async getRatings(ratedUserId: string) {
    const params = new URLSearchParams();
    params.append('ratedUserId', ratedUserId);

    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // GET AVERAGE RATING FOR A CERTAIN TYPE
  async getOneAverageRating(ratedUserId: string, type: string) {
    const params = new URLSearchParams();
    params.append('type', type);
    params.append('ratedUserId', ratedUserId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/one-average`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // GET ONE RATING
  async getOneRating(
    ratedUserId: string,
    gameTypeId: string
  ): Promise<UserRatingDto> {
    const params = new URLSearchParams();
    params.append('ratedUserId', ratedUserId);
    params.append('gameTypeId', gameTypeId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/one`,
      responseType: 'json',
      params,
    });

    return response.data as UserRatingDto;
  }

  // GET GAME TYPES TO RATE
  async getGameTypesToRate(ratedUserId: string): Promise<GameTypeDto[]> {
    const params = new URLSearchParams();
    params.append('ratedUserId', ratedUserId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/game-types`,
      responseType: 'json',
      params,
    });

    return response.data as GameTypeDto[];
  }

  // GET LEADERBOARD
  async getLeaderboard(gameTypeId: string): Promise<LeaderboardDto[]> {
    const params = new URLSearchParams();
    params.append('gameTypeId', gameTypeId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/leaderboard`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
