import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { DatePicker, Flex, Select } from 'antd';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { GameDto } from '../../../../types/dto.types';
import { SelectOptionsProps } from '../../../../types/props.types';
import { useTranslation } from 'react-i18next';

interface Props {
  game: GameDto;
  locations?: SelectOptionsProps[] | null;
  setDate: Dispatch<SetStateAction<string>>;
  setLocationId: Dispatch<SetStateAction<string>>;
}

const EditDateLocation = ({
  game,
  locations,
  setDate,
  setLocationId,
}: Props) => {
  const { t } = useTranslation(['games', 'players']);
  const [edit, setEdit] = useState<{
    date: boolean;
    location: boolean;
  }>({ date: false, location: false });

  return (
    <Flex
      className='edit-date-location-container'
      justify='space-between'
      gap={8}
    >
      {!edit.date ? (
        <Flex gap={'10px'} align='center'>
          <Flex className={'edit-date-location'} gap={'12px'}>
            <p>{dayjs(game.date).format('DD.MM.YYYY.')}</p>
            <p className='bold'>{dayjs(game.date).format('HH:mm')}</p>
          </Flex>
          <EditOutlined
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setEdit((prevState) => ({ ...prevState, date: true }))
            }
          />
        </Flex>
      ) : (
        <Flex gap={'10px'}>
          <DatePicker
            inputReadOnly
            showTime
            placeholder={t('edit_game.date_and_time')}
            className={'edit-date-location'}
            format={'DD.MM.YYYY HH:mm'}
            onChange={(value) => {
              if (value) {
                setDate(value?.format('YYYY-MM-DD HH:mm:ss.SSS'));
              }
            }}
          />
          <CloseOutlined
            onClick={() =>
              setEdit((prevState) => ({ ...prevState, date: false }))
            }
          />
        </Flex>
      )}
      {!edit.location ? (
        <Flex gap={'10px'} align='center'>
          <Flex className={'edit-date-location'}>
            <p>{game.location.name}</p>
          </Flex>
          <EditOutlined
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setEdit((prevState) => ({
                ...prevState,
                location: true,
              }))
            }
          />
        </Flex>
      ) : (
        <Flex gap={'10px'}>
          {locations && (
            <Select
              placeholder={t('edit_game.location')}
              style={{
                border: '1px solid var(--main-text-color)',
                width: '200px',
                height: '50px',
                borderRadius: '100px',
              }}
              options={locations}
              onChange={(value) => {
                if (value) {
                  setLocationId(value);
                }
              }}
            />
          )}
          <CloseOutlined
            onClick={() =>
              setEdit((prevState) => ({
                ...prevState,
                location: false,
              }))
            }
          />
        </Flex>
      )}
    </Flex>
  );
};

export default EditDateLocation;
