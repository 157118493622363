import { useContext, useEffect, useState } from 'react';
import ProfileHeader from './profile-header/profile-header.component';
import { ProfileDto } from '../../types/dto.types';
import { Server } from '../../server/server';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css/pagination';
import { Flex, Row } from 'antd';
import StatsByType from './stats-by-type/stats-by-type.component';
import GeneralInfo from './general-info/general-info.component';
import Activity from './activity/activity.component';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';
import ButtonComponent from '../../components/buttons/button.component';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Rating from './rating/rating.component';

const Profile = () => {
  const [profile, setProfile] = useState<ProfileDto>();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('profile');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const params = useParams();
  let id: string;
  if (params.id) {
    id = params.id;
  } else if (user) {
    id = user.id;
  } else {
    id = '';
  }

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const response = await Server.user.getProfile(id, false);
      if (response) {
        setProfile(response);
      }
    };

    fetchProfile();
    window.scrollTo(0, 0);
    setIsLoading(false);
  }, [id]);

  return (
    <>
      {profile && (
        <>
          {params.id && (
            <Row>
              <ButtonComponent
                className='secondary-button'
                icon={<ArrowLeftOutlined />}
                text={t('cancel')}
                onClick={() => navigate(-1)}
              />
            </Row>
          )}
          <Flex vertical gap={'12px'}>
            <ProfileHeader
              name={profile.firstName + ' ' + profile.lastName}
              profileImage={profile.profileImage}
              ownProfile={!params.id || params.id === user?.id ? true : false}
              memberSince={profile?.memberSince}
              loading={isLoading}
              gamesPlayed={profile.gamesPlayed}
            />

            <Row gutter={[{ xxl: 16, xl: 16, md: 0, sm: 0, xs: 0 }, 16]}>
              <StatsByType profile={profile} loading={isLoading} />
              <Activity profile={profile} />
            </Row>
            <Row gutter={[{ xxl: 16, xl: 16, md: 0, sm: 0, xs: 0 }, 16]}>
              <Rating
                ownProfile={!params.id || params.id === user?.id ? true : false}
              />
            </Row>
            <Row gutter={[{ xxl: 16, xl: 16, md: 0, sm: 0, xs: 0 }, 16]}>
              {(!params.id || params.id === user?.id) && (
                <GeneralInfo profile={profile} loading={isLoading} />
              )}
            </Row>
          </Flex>
        </>
      )}
    </>
  );
};

export default Profile;
