import { Divider } from 'antd';

import CreateNewPlayer from './create-new-player/create-new-player.component';
import AddExistingPlayer from './add-existing-player/add-existing-player.component';

const NewPlayer = () => {
  return (
    <>
      <CreateNewPlayer />
      <Divider />
      <AddExistingPlayer />
    </>
  );
};

export default NewPlayer;
