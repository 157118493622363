import { StopOutlined } from '@ant-design/icons';
import { Card, Flex, Result } from 'antd';
import { UserLockDto } from '../../types/dto.types';
import { FC, useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface LockedProps {
  userLock: UserLockDto;
}

const Locked: FC<LockedProps> = (props) => {
  const { user, groupId } = useContext(AuthContext);
  const { t } = useTranslation('lock');

  return (
    <Flex justify='center' align='center' style={{ height: '60vh' }}>
      <Card className='card-main'>
        <Result
          icon={<StopOutlined style={{ color: 'var(--red)' }} />}
          title={`${t('title')} ${
            user?.userGroups.find((ug) => ug.groupId === groupId)?.groupName
          }`}
          subTitle={
            <>
              <p>{t('description')}</p>
              <span>{t('expires')}:</span>
              <span className='fs-2'>{` ${dayjs(
                props.userLock.lockExpiration.toString()
              ).format('DD.MM.YYYY.')}. ${t('at')} ${dayjs(
                props.userLock.lockExpiration.toString()
              ).format('HH:mm')}`}</span>
            </>
          }
        />
      </Card>
    </Flex>
  );
};

export default Locked;
