import { Col, Flex, Row } from 'antd';
import GeneralGroupStats from './general-group-stats/general-group-stats.component';
import ComparePlayers from './compare-players/compare-players.component';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import Locked from '../../components/locked/locked.component';
import StatsByTeam from './general-group-stats/stats-by-team/stats-by-team.component';

const Stats = () => {
  const { userLock } = useContext(AuthContext);

  return !userLock ? (
    <Flex vertical>
      <Row gutter={[{ xl: 16, lg: 0, sm: 0, xs: 0 }, 16]}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <GeneralGroupStats />
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <StatsByTeam />
        </Col>
      </Row>
      <ComparePlayers />
    </Flex>
  ) : (
    <Locked userLock={userLock} />
  );
};

export default Stats;
