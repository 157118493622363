import { useTranslation } from 'react-i18next';
import { GameDto, UserDto } from '../../../../types/dto.types';
import {
  Card,
  Avatar,
  Tooltip,
  Skeleton,
  Flex,
  notification,
  Popconfirm,
  Radio,
  Popover,
} from 'antd';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { dayName } from '../../../../utilities/data/day-name';
import {
  EyeOutlined,
  GlobalOutlined,
  HolderOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { GameStatus, PlayerRole } from '../../../../types/enums.types';
import ButtonComponent from '../../../../components/buttons/button.component';
import dayjs from 'dayjs';
import { Server } from '../../../../server/server';
import { GameTypeImages } from '../../../../common/game-type-images';
import { AuthContext } from '../../../../context/auth-context';
import { useNavigate } from 'react-router-dom';
import SeenNotificationReceivers from './seen-notification-receivers/seen-notification-receivers.component';
import OpenGameDetails from './open-game-details/open-game-details.component';

interface OpenGameProps {
  game: GameDto;
  loading: boolean;
  source: 'dashboard' | 'open-games';
  highlight?: boolean;
  switchToMapView?: (game: GameDto) => void;
}

const OpenGame: FC<OpenGameProps> = (props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('open-games');
  const day = dayName(props.game.date);
  const [playerRole, setPlayerRole] = useState<PlayerRole>();
  const [alreadyApplied, setAlreadyApplied] = useState<boolean>(false);
  const [alreadyPlaying, setAlreadyPlaying] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const navigate = useNavigate();
  const [seenNotificationReceivers, setSeenNotificationReceivers] = useState<
    UserDto[]
  >([]);

  const getSeenNotificationReceivers = useCallback(async () => {
    const response = await Server.game.getSeenNotificationReceivers(
      props.game.id
    );

    if (response) {
      setSeenNotificationReceivers(response);
    }
  }, [props.game.id]);

  const checkIfUserApplied = useCallback(async () => {
    const response = await Server.openGameApplication.checkIfUserApplied(
      props.game.id
    );
    setAlreadyApplied(response);
  }, [props.game.id]);

  const checkIfAlreadyPlaying = useCallback(() => {
    if (props.game.userGames.some((ug) => ug.userId === user?.id)) {
      setAlreadyPlaying(true);
    }
  }, [props.game.userGames, user?.id]);

  useEffect(() => {
    getSeenNotificationReceivers();
    checkIfUserApplied();
    checkIfAlreadyPlaying();
    setReload(false);
  }, [
    checkIfAlreadyPlaying,
    checkIfUserApplied,
    getSeenNotificationReceivers,
    reload,
  ]);

  const applyForOpenGame = async () => {
    if (playerRole) {
      try {
        await Server.openGameApplication.createApplication({
          gameId: props.game.id,
          playerRole: playerRole,
        });
        notification.success({ message: t('game_card.apply.success') });
        setReload(true);
      } catch (error) {}
    }
  };

  const deleteApplication = async () => {
    try {
      await Server.openGameApplication.deleteApplication(props.game.id);
      notification.success({
        message: t('game_card.cancel_application.success'),
      });
      setReload(true);
    } catch (error) {}
  };

  const checkApplicationsCloseTo = async () => {
    try {
      const response = await Server.openGameApplication.getApplicationsCloseTo(
        props.game.id
      );
      if (response === 1) {
        notification.warning({
          message: t('game_card.apply.warning'),
          description: t('game_card.apply.warning_one'),
          duration: 20,
        });
      } else if (response > 1) {
        notification.warning({
          message: t('game_card.apply.warning'),
          description: t('game_card.apply.warning_multiple', {
            number: response,
          }),
          duration: 20,
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <Card
        className={`card-main h-100 open-game-card ${
          props.highlight ? 'highlight' : ''
        }`}
        style={
          props.source === 'dashboard'
            ? {
                background: 'var(--cta-main-bg2)',
                border: '1px solid var(--gray)',
                width: '320px',
              }
            : {}
        }
      >
        {!props.loading ? (
          <Flex vertical justify='space-between'>
            <Flex justify='space-between'>
              <Flex vertical>
                <Flex gap={6}>
                  {props.game.status === GameStatus.STATUS_OPEN_GLOBAL ? (
                    <Tooltip title={t('game_card.tips.global')}>
                      <GlobalOutlined />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('game_card.tips.local')}>
                      <TeamOutlined />
                    </Tooltip>
                  )}
                  <div className='text-small bold text-uppercase'>
                    {t(`common:game_types.${props.game.type}`)}
                  </div>
                </Flex>
              </Flex>
              {props.source === 'dashboard' ? (
                <></>
              ) : (
                <Flex gap={12}>
                  {props.game.organizer?.id === user?.id ? (
                    <Popover
                      placement='bottomRight'
                      content={
                        <SeenNotificationReceivers
                          receivers={seenNotificationReceivers}
                        />
                      }
                      trigger={'click'}
                      destroyTooltipOnHide={true}
                    >
                      <EyeOutlined />
                    </Popover>
                  ) : (
                    <Popover
                      placement='bottomRight'
                      content={<OpenGameDetails game={props.game} />}
                      trigger={'click'}
                      destroyTooltipOnHide={true}
                    >
                      <HolderOutlined />
                    </Popover>
                  )}
                </Flex>
              )}
            </Flex>
            <div className='card-main-info mt-2'>
              <Flex justify='space-between' align='center' className='w-100'>
                <Avatar
                  src={
                    <img
                      src={
                        GameTypeImages[
                          props.game.type as keyof typeof GameTypeImages
                        ]
                      }
                      alt='avatar'
                    />
                  }
                  size={props.source === 'open-games' ? 80 : 70}
                />
                <h1 className='m-0'>
                  {dayjs(props.game.date).format('HH:mm')}
                </h1>
                <div className='text-end'>
                  <p className='m-0'>
                    {dayjs(props.game.date).format('DD.MM.YYYY.')}
                  </p>
                  <p className='text-small m-0 text-uppercase'>
                    {t(`common:days.${day}`)}
                  </p>
                </div>
              </Flex>
              <Flex justify='space-between' align='center' className='mt-1'>
                <p className='m-0'>
                  {props.game.location.address}
                  <br />
                  <span className='text-small '>
                    @{props.game.location.name}
                  </span>
                </p>
                <div className='text-end'>
                  <p className='m-0'>{t('game_card.positions_left')}:</p>
                  <div className='green-color bold'>
                    <span>
                      {props.game.openPlayerPositions > 0 ? (
                        <>
                          {props.game.openPlayerPositions}{' '}
                          {props.game.openPlayerPositions > 1
                            ? t('game_card.players')
                            : t('game_card.player')}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                    <span>
                      {props.game.openPlayerPositions > 0 &&
                      props.game.openGoalkeeperPositions > 0 ? (
                        <span>{` + `}</span>
                      ) : (
                        <></>
                      )}
                    </span>
                    <span>
                      {props.game.openGoalkeeperPositions > 0 ? (
                        <>
                          {props.game.openGoalkeeperPositions}{' '}
                          {props.game.openGoalkeeperPositions > 1
                            ? t('game_card.goalkeepers')
                            : t('game_card.goalkeeper')}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                </div>
              </Flex>
            </div>
            <div className='card-main-footer pt-05'>
              <Flex justify='space-between' align='center' className='mt-1'>
                <ButtonComponent
                  className='secondary-button'
                  text={
                    props.source === 'open-games'
                      ? t('game_card.map')
                      : t('game_card.all_ads')
                  }
                  onClick={() => {
                    if (props.source === 'open-games') {
                      props.switchToMapView &&
                        props.switchToMapView(props.game);
                    } else {
                      navigate('/open-games');
                    }
                  }}
                />
                {!alreadyApplied ? (
                  <Popconfirm
                    title={t('game_card.apply.title')}
                    description={
                      <Flex justify='center' className='text-uppercase'>
                        <Radio.Group
                          className='mt-1 mb-1'
                          value={playerRole}
                          onChange={(e) => setPlayerRole(e.target.value)}
                        >
                          <Radio.Button
                            value={PlayerRole.player}
                            disabled={props.game.openPlayerPositions < 1}
                          >
                            {t('game_card.player')}
                          </Radio.Button>
                          <Radio.Button
                            value={PlayerRole.goalkeeper}
                            disabled={props.game.openGoalkeeperPositions < 1}
                          >
                            {t('game_card.goalkeeper')}
                          </Radio.Button>
                        </Radio.Group>
                      </Flex>
                    }
                    onConfirm={applyForOpenGame}
                    okButtonProps={{ disabled: !playerRole }}
                    okText={t('common:confirm')}
                    cancelText={t('common:cancel')}
                    overlayStyle={{
                      backgroundColor: `var(--cta-secondary-bg)`,
                      border: '1px solid var(--gray)',
                      borderRadius: '8px',
                    }}
                  >
                    <ButtonComponent
                      disabled={alreadyPlaying}
                      className='success-button'
                      text={t('game_card.send_request')}
                      onClick={() => {
                        setPlayerRole(undefined);
                        checkApplicationsCloseTo();
                      }}
                    />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title={t('game_card.cancel_application.title')}
                    onConfirm={deleteApplication}
                    okText={t('common:confirm')}
                    cancelText={t('common:cancel')}
                    overlayStyle={{
                      backgroundColor: `var(--cta-secondary-bg)`,
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '8px',
                    }}
                  >
                    <ButtonComponent
                      disabled={alreadyPlaying}
                      className='danger-button'
                      text={t('game_card.cancel_request')}
                    />
                  </Popconfirm>
                )}
              </Flex>
            </div>
          </Flex>
        ) : (
          <Skeleton active />
        )}
      </Card>
    </>
  );
};

export default OpenGame;
