import { Button, Card, Flex, Input, notification } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import { AuthContext } from '../../../context/auth-context';

const ChangeEmail = () => {
  const { t } = useTranslation(['auth', 'header']);
  const [email, setEmail] = useState<string>('');
  const { user, logout } = useContext(AuthContext);

  const changeUserEmail = async () => {
    if (user) {
      try {
        await Server.user.updateEmail({
          id: user.id,
          email: email.toLowerCase().trim(),
        });
        notification.success({
          message: t('auth:set_email.success'),
        });
        logout();
      } catch (error) {}
    }
  };

  return (
    <>
      <Flex justify='end'>
        <Button onClick={logout} className='secondary-button m-05'>
          {t('header:menu.logout')}
        </Button>
      </Flex>
      <Flex justify='center' align='center' className='min-vh-80'>
        <Card className='verify-email-container login-wrap card-main p-0'>
          <div className='bg-dark-img '>
            <Flex vertical align='center'>
              <h3 className='logo-temp text-center m-1 mt-2'>Balunko</h3>
              <p className='text-center p-1'>{t('auth:set_email.title')}</p>
            </Flex>
          </div>
          <Flex justify='center' vertical gap={18} className='w-100 p-1'>
            <Input
              placeholder={t('auth:e_mail')}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Flex justify='end'>
              {' '}
              <Button className='primary-button' onClick={changeUserEmail}>
                {t('common:confirm')}
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </>
  );
};

export default ChangeEmail;
