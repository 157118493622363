import { LockUserDto, UserLockDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserLockServer {
  private readonly basePath = `${serverUrl}user-lock`;

  // GET USER LOCKS
  async getUserLocks(
    userId: string,
    groupId: string
  ): Promise<UserLockDto[] | null> {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('groupId', groupId.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}`,
        responseType: 'json',
        params: params,
      });
      return response.data as UserLockDto[];
    } catch (error) {
      return null;
    }
  }

  // CHECK IF USER IS LOCKED
  async checkIfUserIsLocked(userId: string, groupId: string): Promise<boolean> {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('groupId', groupId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/is-locked`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // LOCK USER
  async lockUser(lockUserDto: LockUserDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}`,
        responseType: 'json',
        data: lockUserDto,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  // UNLOCK USER
  async unlockUser(userId: string, groupId: string) {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('groupId', groupId.toString());

    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}`,
        responseType: 'json',
        params: params,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }
}
