export const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 20;

  return (
    <text
      x={x + width / 2}
      y={y + radius}
      fill='#fff'
      textAnchor='middle'
      dominantBaseline='middle'
      style={{ textShadow: '1px 1px 2px #000' }}
      className='bold'
    >
      {value}
    </text>
  );
};
