import { FC, useContext, useEffect, useState } from 'react';
import { VotingGameDto } from '../../../types/dto.types';
import { TeamEnum } from '../../../types/enums.types';
import { Card, Col, Divider, Flex, Row, Tooltip } from 'antd';
import MvpTeams from '../../../components/teams/mvp-teams.component';

import { calculateVotingClosingTime } from '../../../utilities/data/calculate-voting-closing-time';
import { AuthContext } from '../../../context/auth-context';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface VotingGameProps {
  votingGame: VotingGameDto;
}

const VotingGame: FC<VotingGameProps> = (props) => {
  const { votingGame } = props;
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('dashboard');
  const [ableToVote, setAbleToVote] = useState(false);
  const [choiceId, setChoiceId] = useState<string>('');

  const playersTeam1 = votingGame.game.userGames.filter(
    (player) => player.team === TeamEnum.team1
  );
  const playersTeam2 = votingGame.game.userGames.filter(
    (player) => player.team === TeamEnum.team2
  );

  useEffect(() => {
    const checkIfAbleToVote = () => {
      if (votingGame.game.statusVoting) {
        setAbleToVote(true);
      }

      votingGame.mvpEntries.forEach((entry) => {
        if (entry.voterId === user?.id) {
          setAbleToVote(false);
          setChoiceId(entry.voteForId);
        }
      });
    };

    checkIfAbleToVote();
  }, [user?.id, votingGame]);

  return (
    <Card
      className='card-cta-secondary dashboard-card p-0 mt-1'
      style={{ width: '100%' }}
    >
      <div>
        <div className='d-flex align-items-center space-between'>
          <Flex vertical>
            <Flex gap={6}>
              <span className='text-small bold text-uppercase'>
                {t('voting.title')}
              </span>
              <Tooltip title={t('voting.tooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </Flex>
            <span className='text-small bold text-uppercase'>
              {votingGame.game.groupName}
            </span>
          </Flex>
          <div>
            <span className='text-small bold text-uppercase text-center'>
              {votingGame.game.type}
            </span>
          </div>
        </div>
        <Row className='align-items-center space-between'>
          <Col className='text-small' span={8}>
            <p>
              {votingGame.game.location.address}
              <br />
              <span className='text-small'>
                @{votingGame.game.location.name}
              </span>
            </p>
          </Col>
          <Col className='text-small text-end' span={8}>
            {dayjs(votingGame.game.date).format('DD.MM.YYYY.')}
            <br />
            <span className='bold'>
              {dayjs(votingGame.game.date).format('HH:mm')}
            </span>
          </Col>
        </Row>
      </div>
      <Divider />
      <h4 className='text-uppercase text-center'>
        {t('voting.voting_close')}
        <span className='fs-2'>
          {calculateVotingClosingTime(
            Number(dayjs(votingGame.game.date).format('HH')),
            dayjs(votingGame.game.date).format('mm')
          )}
        </span>
      </h4>
      <MvpTeams
        playersTeam1={playersTeam1}
        playersTeam2={playersTeam2}
        ableToVote={ableToVote}
        setAbleToVote={setAbleToVote}
        game={votingGame.game}
        choiceId={choiceId}
        setChoiceId={setChoiceId}
      />
    </Card>
  );
};

export default VotingGame;
