import { Card, Divider, Flex, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonComponent from '../../../components/buttons/button.component';
import { Server } from '../../../server/server';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/spinner/spinner.component';

const VerifyEmail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();
  const token = params.verifyToken;
  const { t } = useTranslation('auth');

  const handleVerifyEmail = async () => {
    setLoading(true);
    if (token) {
      try {
        await Server.auth.verifyEmail(token);
        notification.success({
          message: t('email_verify.success_msg'),
          description: t('email_verify.success_dsc'),
          duration: 10,
        });
        navigate('/login');
      } catch (error) {
        return;
      }
    } else {
      setLoading(false);
      notification.error({
        message: t('email_verify.error_msg'),
        description: t('email_verify.error_dsc'),
        duration: 10,
      });
      return;
    }
    setLoading(false);
  };

  return !loading ? (
    <Flex justify='center' align='center' className='min-vh-100'>
      <Card className='card-main' style={{ width: '520px' }}>
        <h3 className='logo-temp m-0 text-center'>Balunko</h3>
        <Divider />
        <div className='text-center'>
          <h1>{t('email_verify.confirm')}</h1>
          <p>{t('email_verify.confirm_dsc')}</p>
        </div>
        <ButtonComponent
          text={t('email_verify.button')}
          className='primary-button mt-1 w-100'
          onClick={() => handleVerifyEmail()}
        />
      </Card>
    </Flex>
  ) : (
    <Spinner />
  );
};

export default VerifyEmail;
