import {
  Avatar,
  Button,
  Card,
  Divider,
  Empty,
  Flex,
  List,
  Popconfirm,
  Popover,
  Radio,
  Tooltip,
  notification,
} from 'antd';
import {
  ApplicationDto,
  GameDto,
  OpenGameApplicationDto,
} from '../../../../types/dto.types';
import { useTranslation } from 'react-i18next';
import Initials from '../../../../components/initials/initials.component';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  FlagFilled,
} from '@ant-design/icons';
import { PlayerRole, TeamEnum } from '../../../../types/enums.types';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Server } from '../../../../server/server';
import RatingCard from '../../../../components/rating-card/rating-card.component';

interface Props {
  applications: OpenGameApplicationDto[];
  setReload: Dispatch<SetStateAction<boolean>>;
  game: GameDto;
}

const Applications = (props: Props) => {
  const { t } = useTranslation(['games']);
  const [playerApplications, setPlayerApplications] = useState<
    OpenGameApplicationDto[]
  >([]);
  const [goalkeeperApplications, setGoalkeeperApplications] = useState<
    OpenGameApplicationDto[]
  >([]);
  const [team, setTeam] = useState<TeamEnum>();
  const popoverStyle = useRef({
    backgroundColor: `var(--cta-main-bg3)`,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
  });

  useEffect(() => {
    const playerApps: OpenGameApplicationDto[] = [];
    const goalkeeperApps: OpenGameApplicationDto[] = [];

    props.applications.forEach((application) => {
      if (application.playerRole === PlayerRole.player) {
        playerApps.push(application);
      } else if (application.playerRole === PlayerRole.goalkeeper) {
        goalkeeperApps.push(application);
      }
    });

    setPlayerApplications(playerApps);
    setGoalkeeperApplications(goalkeeperApps);
  }, [props.applications]);

  const approveApplication = async (applicationData: ApplicationDto) => {
    try {
      await Server.openGameApplication.approveApplication(applicationData);
      notification.success({
        message: t('edit_game.applications.success_approve'),
      });
      props.setReload(true);
    } catch (error) {}
  };

  const rejectApplication = async (applicationData: ApplicationDto) => {
    try {
      await Server.openGameApplication.rejectApplication(applicationData);
      notification.success({
        message: t('edit_game.applications.success_reject'),
      });
      props.setReload(true);
    } catch (error) {}
  };

  const renderApplicationList = (
    applications: OpenGameApplicationDto[],
    title: string
  ) => {
    return (
      <List size='small' className='mt-1'>
        <span className='text-uppercase'>{title}</span>
        {applications?.map((application) => (
          <Flex className='mt-1' justify='space-between'>
            <Flex align='center' gap={12}>
              <Popover
                content={
                  <RatingCard
                    userId={application.userId}
                    gameType={props.game.type}
                  />
                }
                trigger={'click'}
                overlayStyle={popoverStyle.current}
                destroyTooltipOnHide={true}
              >
                <Flex align='center' gap={8}>
                  {application.profileImage ? (
                    <Avatar src={application.profileImage} size={32} />
                  ) : (
                    <Initials
                      firstName={application.applicantName.split(' ')[0]}
                      lastName={application.applicantName
                        .split(' ')
                        .slice(1)
                        .join(' ')}
                      size={32}
                    />
                  )}
                  <span>{application.applicantName}</span>
                </Flex>
              </Popover>
              {application.applicationsCloseTo > 0 ? (
                <Tooltip
                  title={
                    application.applicationsCloseTo === 1
                      ? t('edit_game.applications.warning_one')
                      : t('edit_game.applications.warning_multiple', {
                          number: application.applicationsCloseTo,
                        })
                  }
                >
                  <FlagFilled className='warning_flag' />
                </Tooltip>
              ) : (
                <></>
              )}
            </Flex>
            <Flex gap={8}>
              <Popconfirm
                title={t('edit_game.applications.approve_title')}
                description={
                  <Flex justify='center'>
                    <Radio.Group
                      className='mt-1 mb-1'
                      value={team}
                      onChange={(e) => setTeam(e.target.value)}
                    >
                      <Radio.Button value={TeamEnum.team1}>
                        {t('common:team1')}
                      </Radio.Button>
                      <Radio.Button value={TeamEnum.team2}>
                        {t('common:team2')}
                      </Radio.Button>
                    </Radio.Group>
                  </Flex>
                }
                onConfirm={() =>
                  approveApplication({
                    userId: application.userId,
                    gameId: application.gameId,
                    playerRole: application.playerRole,
                    team: team,
                  })
                }
                okText={t('common:confirm')}
                okButtonProps={{ disabled: !team }}
                cancelText={t('common:cancel')}
                overlayStyle={popoverStyle.current}
              >
                <Button
                  className='primary-button'
                  onClick={() => setTeam(undefined)}
                >
                  <CheckOutlined />
                </Button>
              </Popconfirm>
              <Popconfirm
                title={t('edit_game.applications.reject_title')}
                onConfirm={() =>
                  rejectApplication({
                    userId: application.userId,
                    gameId: application.gameId,
                    playerRole: application.playerRole,
                  })
                }
                okText={t('common:confirm')}
                cancelText={t('common:cancel')}
                overlayStyle={popoverStyle.current}
              >
                <Button className='danger-button'>
                  <CloseOutlined />
                </Button>
              </Popconfirm>
            </Flex>
          </Flex>
        ))}
      </List>
    );
  };

  return (
    <Card className={`card-main edit-open-card`}>
      <Divider>{t('edit_game.requests').toUpperCase()}</Divider>
      {props.applications.length > 0 ? (
        <div className='scrollable-220'>
          {playerApplications.length > 0 ? (
            renderApplicationList(playerApplications, t('edit_game.players'))
          ) : (
            <></>
          )}
          {goalkeeperApplications.length > 0 ? (
            renderApplicationList(
              goalkeeperApplications,
              t('edit_game.goalkeepers')
            )
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Empty
          image={<EyeInvisibleOutlined />}
          imageStyle={{ fontSize: '36px' }}
          description={t('edit_game.no_requests')}
        />
      )}
    </Card>
  );
};

export default Applications;
