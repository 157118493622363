import { FC } from 'react';
import { GamesWithPageDto } from '../../../../types/dto.types';
import { Collapse, Divider } from 'antd';
import GameHeader from '../../../games-panel/games/game-header/game-header.component';
import GameBody from '../../../games-panel/games/game-body/game-body.component';
import { useTranslation } from 'react-i18next';

interface CompareGameProps {
  games: GamesWithPageDto;
}

const CompareGame: FC<CompareGameProps> = (props) => {
  const { t } = useTranslation(['stats', 'dashboard', 'betting']);
  return (
    <>
      <Divider>{t('compare_players.games.chosen_games')}</Divider>
      <div className='scrollable-600 mt-1'>
        <Collapse
          accordion
          style={{ width: '100%' }}
          items={props.games.games.map((game) => ({
            key: game.id,
            label: <GameHeader game={game} />,
            children: <GameBody game={game} />,
            className: 'collapse-main',
          }))}
        />
      </div>
    </>
  );
};

export default CompareGame;
