import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'swiper/css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppRouter from './router/app.router';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './context/auth-context';
import { ThemeEnum } from './types/enums.types';
import { lightConfig } from './context/antd-config/light-config';
import { darkConfig } from './context/antd-config/dark-config';
import { Locale } from 'antd/es/locale';
import en from 'antd/locale/en_GB';
import hr from 'antd/locale/hr_HR';
import 'dayjs/locale/hr';

function App() {
  const { theme, language } = useContext(AuthContext);
  const [locale, setLocale] = useState<Locale>(hr);

  useEffect(() => {
    document.documentElement.setAttribute('theme', theme);
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute(
        'content',
        `${theme === ThemeEnum.DARK ? '#0d111b' : '#f6f6f6'}`
      );
    }

    if (language === 'hr') {
      setLocale(hr);
    } else {
      setLocale(en);
    }
  }, [language, theme]);

  return (
    <div className='App'>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
        <ConfigProvider
          locale={locale}
          theme={theme === ThemeEnum.LIGHT ? lightConfig : darkConfig}
        >
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>{' '}
        </ConfigProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
