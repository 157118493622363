import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/auth/login/login.component';
import Register from '../pages/auth/register/register.component';
import Invite from '../pages/auth/invite/invite.component';
import { useContext } from 'react';
import VerifyEmail from '../pages/auth/verify-email/verify-email.component';
import { AuthContext } from '../context/auth-context';
import SetNewPassword from '../pages/auth/set-password/set-password.component';

const LoginRouter = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/invitation/:token' element={<Invite />} />
      <Route path='/set-new-password/:token' element={<SetNewPassword />} />
      <Route path='/register' element={<Register />} />
      <Route path='/verify-email/:verifyToken' element={<VerifyEmail />} />
      {!isLoggedIn && <Route path='*' element={<Navigate to='/login' />} />}
    </Routes>
  );
};

export default LoginRouter;
