import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';
import { Switch } from 'antd';
import { ThemeEnum } from '../../types/enums.types';
import { Server } from '../../server/server';

const ThemeSwitcher = () => {
  const { t } = useTranslation('profile');
  const { user, theme, refreshUser } = useContext(AuthContext);

  const changeTheme = async (selectedTheme: ThemeEnum) => {
    if (theme === selectedTheme) {
      return;
    }
    if (user) {
      try {
        await Server.userSettings.changeTheme(
          user?.userSettings.id,
          selectedTheme
        );
        const metaThemeColor = document.querySelector(
          'meta[name="theme-color"]'
        );
        if (metaThemeColor) {
          metaThemeColor.setAttribute(
            'content',
            `${theme === ThemeEnum.DARK ? '#0d111b' : '#f6f6f6'}`
          );
        }
      } catch (e) {
      } finally {
        refreshUser();
      }
    }
  };

  return (
    <Switch
      checkedChildren={t('theme.light')}
      unCheckedChildren={t('theme.dark')}
      defaultChecked={theme === ThemeEnum.LIGHT}
      onChange={(checked) => {
        checked ? changeTheme(ThemeEnum.LIGHT) : changeTheme(ThemeEnum.DARK);
      }}
    />
  );
};

export default ThemeSwitcher;
