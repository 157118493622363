import { Tabs, TabsProps } from 'antd';
import Players from './players/players.component';
import NewPlayer from './new-player/new-player.component';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';
import Locked from '../../components/locked/locked.component';

const PlayersPanel = () => {
  const { isGroupOwner, userLock } = useContext(AuthContext);
  const { t } = useTranslation('players');
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.players').toUpperCase(),
      children: <Players />,
    },
    {
      key: '2',
      label: t('tabs.new_player').toUpperCase(),
      children: <NewPlayer />,
    },
  ];
  return (
    <div>
      {isGroupOwner ? (
        <>
          {!userLock ? (
            <Tabs
              defaultActiveKey='1'
              items={tabItems}
              destroyInactiveTabPane={true}
            ></Tabs>
          ) : (
            <Locked userLock={userLock} />
          )}
        </>
      ) : (
        <>{!userLock ? <Players /> : <Locked userLock={userLock} />}</>
      )}
    </div>
  );
};

export default PlayersPanel;
