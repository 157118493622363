import { DownOutlined, TeamOutlined } from '@ant-design/icons';
import { Dropdown, Flex, MenuProps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { SelectOptionsProps } from '../../types/props.types';
import { AuthContext } from '../../context/auth-context';

const GroupSelect = () => {
  const { user, chooseGroup, groupId } = useContext(AuthContext);
  const [groupsOptions, setGroupsOptions] = useState<SelectOptionsProps[]>([]);

  useEffect(() => {
    const options = user?.userGroups.map((userGroup) => {
      return { value: userGroup.groupId, label: userGroup.groupName };
    });
    options && setGroupsOptions(options);
  }, [user?.userGroups]);

  const groupItems: MenuProps['items'] = groupsOptions.map((option) => {
    return {
      key: option.value,
      label: option.label,
      onClick: () => {
        chooseGroup(option.value);
      },
    };
  });

  return user && user?.userGroups.length > 1 ? (
    <Dropdown
      menu={{
        items: groupItems,
        selectable: true,
        defaultSelectedKeys: [`${groupId}`],
      }}
      placement='bottomLeft'
      trigger={['click']}
      destroyPopupOnHide
    >
      <Flex className={`header-group-select m-0`} gap={8}>
        <DownOutlined style={{ fontSize: '10px' }} className='m-0 p-0' />
        <span className='header-group-name m-0 p-0'>
          {user?.userGroups.find((ug) => ug.groupId === groupId)?.groupName}
        </span>
      </Flex>
    </Dropdown>
  ) : (
    <Flex className={`header-group-select m-0`} gap={8}>
      <TeamOutlined style={{ fontSize: '14px' }} className='m-0 p-0' />
      <span className='header-group-name m-0 p-0'>
        {user?.userGroups.find((ug) => ug.groupId === groupId)?.groupName}
      </span>
    </Flex>
  );
};

export default GroupSelect;
