import {
  BarChartData,
  CreateNewGameDto,
  EditGameDto,
  GameDto,
  GamesCompareFiltersDto,
  GamesWithPageDto,
  GetGamesDto,
  GetGamesWithFilters,
  UserDto,
  VotingGameDto,
} from '../../types/dto.types';
import { GameState, GameStatus, TeamEnum } from '../../types/enums.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class GameServer {
  private readonly basePath = `${serverUrl}game`;

  // GET ALL GAMES
  async getAllGames(
    getAllGames: GetGamesDto
  ): Promise<{ games: GameDto[]; count: number } | null> {
    const params = new URLSearchParams();
    params.append('status', getAllGames.status.toString());
    params.append('take', getAllGames.pagination.take.toString());
    params.append('offset', getAllGames.pagination.offset.toString());

    if (getAllGames.relations) {
      params.append('relations', getAllGames.relations.toString());
    }
    if (getAllGames.groupId) {
      params.append('groupId', getAllGames.groupId.toString());
    }

    try {
      const response = await axios({
        method: 'GET',
        url: this.basePath,
        responseType: 'json',
        params: params,
      });

      return response.data as { games: GameDto[]; count: number };
    } catch (error) {
      return null;
    }
  }

  // GET GAME BY ID
  async getGameById(id: string): Promise<GameDto> {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/game-by-id`,
      responseType: 'json',
      params: params,
    });

    return response.data as GameDto;
  }

  // GET GAMES WITH FILTERS (DATE, TYPE, PLAYERS)
  async getGamesWithFilter(
    filters: GetGamesWithFilters
  ): Promise<GamesWithPageDto | null> {
    const params = new URLSearchParams();
    params.append('take', filters.pagination.take.toString());
    params.append('offset', filters.pagination.offset.toString());
    if (filters.groupId) {
      params.append('groupId', filters.groupId.toString());
    }
    if (filters.playerRelation) {
      params.append('playerRelation', filters.playerRelation.toString());
    }
    if (filters.playerIds && filters.playerIds[0]) {
      params.append('playerIds[]', filters.playerIds[0].toString());
      if (filters.playerIds[1]) {
        params.append('playerIds[]', filters.playerIds[1].toString());
      }
    }
    if (filters.date) {
      params.append('date', filters.date.toString());
    }
    if (filters.dateTo) {
      params.append('dateTo', filters.dateTo.toString());
    }
    if (filters.gameTypeId) {
      params.append('gameTypeId', filters.gameTypeId.toString());
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/game-filters`,
        responseType: 'json',
        params: params,
      });

      return response.data as GamesWithPageDto;
    } catch (error) {
      return null;
    }
  }

  // CREATE GAME
  async createGame(newGame: CreateNewGameDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: this.basePath,
        responseType: 'json',
        data: newGame,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }

  // EDIT GAME
  async editGame(editedGame: EditGameDto) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/edit-game`,
        responseType: 'json',
        data: editedGame,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }

  // DELETE GAME
  async deleteGame(id: string) {
    const params = new URLSearchParams();
    params.append('id', id.toString());
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${this.basePath}/delete`,
        responseType: 'json',
        params: params,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }
  // CANCEL GAME
  async cancelGame(id: string) {
    const params = new URLSearchParams();
    params.append('id', id.toString());
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${this.basePath}/cancel`,
        responseType: 'json',
        params: params,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }

  // GET GAMES FOR COMPARISON
  async getGamesForComparison(
    filters: GamesCompareFiltersDto
  ): Promise<GamesWithPageDto | null> {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/comparison`,
        responseType: 'json',
        data: filters,
      });

      return response.data as GamesWithPageDto;
    } catch (error) {
      return null;
    }
  }

  // GET PENDING GAMES
  async getPendingGames(getPendingGamesDto: {
    groupId?: string;
    userId?: string;
  }): Promise<GameDto[] | null> {
    const params = new URLSearchParams();
    getPendingGamesDto.groupId &&
      params.append('groupId', getPendingGamesDto.groupId.toString());
    getPendingGamesDto.userId &&
      params.append('userId', getPendingGamesDto.userId.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/pending`,
        responseType: 'json',
        params: params,
      });

      return response.data as GameDto[];
    } catch (error) {
      return null;
    }
  }

  // GET LAST GAMES
  async getLastGames(getLastGamesDto: {
    groupId?: string;
    userId?: string;
  }): Promise<GameDto[] | null> {
    const params = new URLSearchParams();
    getLastGamesDto.groupId &&
      params.append('groupId', getLastGamesDto.groupId.toString());
    getLastGamesDto.userId &&
      params.append('userId', getLastGamesDto.userId.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/last`,
        responseType: 'json',
        params: params,
      });

      return response.data as GameDto[];
    } catch (error) {
      return null;
    }
  }

  // GET VOTING GAMES
  async getVotingGames(getVotingGamesDto: {
    groupId?: string;
    userId?: string;
  }): Promise<VotingGameDto[] | null> {
    const params = new URLSearchParams();
    getVotingGamesDto.groupId &&
      params.append('groupId', getVotingGamesDto.groupId.toString());
    getVotingGamesDto.userId &&
      params.append('userId', getVotingGamesDto.userId.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/voting`,
        responseType: 'json',
        params: params,
      });

      return response.data as VotingGameDto[];
    } catch (error) {
      return null;
    }
  }

  // GET PUBLISHED GAMES
  async getOpenGames(getOpenGamesDto: {
    status?: GameStatus;
    groupId?: string;
    gameTypeId?: string;
    date?: string;
    userId?: string;
  }): Promise<GameDto[] | null> {
    const params = new URLSearchParams();

    getOpenGamesDto.status &&
      params.append('status', getOpenGamesDto.status.toString());
    getOpenGamesDto.groupId &&
      params.append('groupId', getOpenGamesDto.groupId.toString());
    getOpenGamesDto.gameTypeId &&
      params.append('gameTypeId', getOpenGamesDto.gameTypeId.toString());
    getOpenGamesDto.date &&
      params.append('date', getOpenGamesDto.date.toString());
    getOpenGamesDto.userId &&
      params.append('userId', getOpenGamesDto.userId.toString());

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/open`,
        responseType: 'json',
        params: params,
      });
      return response.data as GameDto[];
    } catch (error) {
      return null;
    }
  }

  // GET ACTIVITY FOR GROUP
  async getActivityForGroup(
    groupId: string,
    team: TeamEnum,
    result: GameState | 'ALL',
    gameTypeId: string
  ): Promise<BarChartData[] | null> {
    const params = new URLSearchParams();
    params.append('groupId', groupId);
    params.append('team', team.toString());
    params.append('result', result.toString());

    if (gameTypeId) {
      params.append('gameTypeId', gameTypeId);
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/group-activity`,
        responseType: 'json',
        params: params,
      });

      return response.data as BarChartData[];
    } catch (err) {
      return null;
    }
  }

  // GET SEEN NOTIFICATION RECEIVERS
  async getSeenNotificationReceivers(openGameId: string) {
    const params = new URLSearchParams();
    params.append('openGameId', openGameId);

    try {
      const response = await axios({
        method: 'GET',
        url: `${this.basePath}/seen-notification-receivers`,
        responseType: 'json',
        params,
      });

      return response.data as UserDto[];
    } catch (error) {
      return null;
    }
  }
}
