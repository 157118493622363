import { Avatar, Col, Dropdown, Flex, MenuProps, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import GroupSelect from '../group-select/group-select.component';
import { useTranslation } from 'react-i18next';
import Notifications from './notifications/notifications.component';
import { BookOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

const HeaderComponent = () => {
  const { logout, user, groupId } = useContext(AuthContext);
  const { t } = useTranslation('header');

  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <UserOutlined style={{ fontSize: '16px' }} />,
      label: (
        <Link className='text-uppercase bold ' to='/profile'>
          {t('menu.profile')}
        </Link>
      ),
    },
    {
      key: '2',
      icon: <BookOutlined style={{ fontSize: '16px' }} />,
      label: (
        <Link className='text-uppercase bold' to='/group-rules'>
          {t('menu.group_rules')}
        </Link>
      ),
      disabled: !groupId,
    },
    {
      key: '3',
      icon: <LogoutOutlined style={{ color: 'red', fontSize: '16px' }} />,
      label: (
        <div className='text-uppercase bold' style={{ color: 'var(--red)' }}>
          {t('menu.logout')}
        </div>
      ),
      onClick: () => {
        logout();
      },
    },
  ];

  return (
    <Header>
      <Row justify='space-between' align='middle'>
        <Col span={8}>{groupId ? <GroupSelect /> : <></>}</Col>
        <Col span={8}>
          <Flex justify='center'>
            <Link to='/dashboard'>
              <div className={`logo-temp m-0`}>Balunko</div>
            </Link>
          </Flex>
        </Col>
        <Col span={8}>
          <Flex justify='end' align='center' gap={12}>
            <Notifications />
            <Dropdown menu={{ items }} trigger={['click']} destroyPopupOnHide>
              <Avatar
                className='header-avatar'
                src={
                  user?.profileImage ?? (
                    <span>{`${user?.firstName.charAt(0)}${user?.lastName.charAt(
                      0
                    )}`}</span>
                  )
                }
                size={32}
              />
            </Dropdown>
          </Flex>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderComponent;
