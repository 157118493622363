import { useEffect, useState } from 'react';
import Teams from '../../../../components/teams/teams.component';
import { GameDto, UserGameDto } from '../../../../types/dto.types';
import { TeamEnum } from '../../../../types/enums.types';
import BettingResults from '../../../../components/betting/betting-results.component';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface Props {
  game: GameDto;
}

const LastGameDetails = (props: Props) => {
  const [players, setPlayers] = useState<{
    playersTeam1: UserGameDto[];
    playersTeam2: UserGameDto[];
  }>({ playersTeam1: [], playersTeam2: [] });
  const { t } = useTranslation();

  useEffect(() => {
    const playersTeam1 = props.game.userGames.filter(
      (player) => player.team === TeamEnum.team1
    );
    const playersTeam2 = props.game.userGames.filter(
      (player) => player.team === TeamEnum.team2
    );

    setPlayers({ playersTeam1: playersTeam1, playersTeam2: playersTeam2 });
  }, [props.game.userGames]);

  return (
    <>
      <div>
        <h2 className='m-0 text-uppercase'>
          {t(`common:game_types.${props.game.type}`)}
        </h2>
        <p className='m-0'>{dayjs(props.game.date).format('DD.MM.YYYY.')}</p>
        <div className='text-small '>{props.game.groupName}</div>
      </div>
      <Teams
        playersTeam1={players.playersTeam1}
        playersTeam2={players.playersTeam2}
        team1Goals={props.game.team1Goals}
        team2Goals={props.game.team2Goals}
      />
      <Divider />
      <BettingResults game={props.game} />
    </>
  );
};

export default LastGameDetails;
