import { FC, useEffect, useState } from 'react';
import { Server } from '../../../server/server';
import { GameDto, UserDto } from '../../../types/dto.types';
import { Card, Col, Empty, Flex, Skeleton } from 'antd';
import PendingGame from './pending-game.component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/title/title.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';

interface PendingGamesProps {
  user: UserDto;
}

const PendingGames: FC<PendingGamesProps> = (props) => {
  const { user } = props;
  const { t } = useTranslation('dashboard');
  const [pendingGames, setPendingGames] = useState<GameDto[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const getPendingGames = async () => {
      setLoading(true);
      const pendingResponse = await Server.game.getPendingGames({
        userId: user.id,
      });
      if (pendingResponse) {
        setPendingGames(pendingResponse);
      }
      setLoading(false);
    };

    getPendingGames();
    setReload(false);
  }, [reload, user]);

  return !loading ? (
    <>
      {pendingGames && pendingGames?.length > 0 ? (
        <Col span={24}>
          <Title main={t('pending.pending_games')} />
          <Swiper
            spaceBetween={16}
            effect={'cards'}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            slidesPerView={1}
            pagination={{ clickable: true, type: 'fraction', el: '.pend-pag' }}
            modules={[Pagination, Autoplay]}
          >
            {pendingGames.map((pendingGame) => (
              <SwiperSlide key={pendingGame.id}>
                <PendingGame game={pendingGame} setReload={setReload} />
              </SwiperSlide>
            ))}
            <div
              className='pend-pag text-center'
              style={{ paddingTop: '6px' }}
            ></div>
          </Swiper>
        </Col>
      ) : (
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Title main={t('pending.pending_games')} />
          <Card className='card-cta-main empty-dashboard-card p-0 mt-1'>
            <Flex
              vertical
              justify='center'
              align='center'
              style={{
                height: '230px',
                width: '100%',
              }}
            >
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '42px' }}
                description={t('pending.no_pending_user')}
              />
            </Flex>
          </Card>
        </Col>
      )}
    </>
  ) : (
    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
      <Skeleton.Input active size='small' />
      <Card className='card-cta-main empty-dashboard-card p-0 mt-1'>
        <Skeleton active />
      </Card>
    </Col>
  );
};

export default PendingGames;
