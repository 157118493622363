import { FC, useContext, useEffect, useState } from 'react';
import { GroupDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { Card, Flex, Skeleton } from 'antd';
import ReactQuill from 'react-quill';
import { modules } from '../../../constants/variables/quill-modules.constant';
import ButtonComponent from '../../../components/buttons/button.component';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from '../../../context/auth-context';
import { useTranslation } from 'react-i18next';

interface EditGroupRulesProps {
  saveNewRules: (group: GroupDto) => Promise<void>;
}

const EditGroupRules: FC<EditGroupRulesProps> = (props) => {
  const [group, setGroup] = useState<GroupDto>();
  const { t } = useTranslation('group-rules');
  const [isLoading, setIsLoading] = useState(true);
  const { groupId } = useContext(AuthContext);

  useEffect(() => {
    const fetchGroup = async () => {
      if (groupId) {
        setIsLoading(true);
        const groupResponse = await Server.group.getGroupById(groupId);
        if (groupResponse) {
          setGroup(groupResponse);
        }
        setIsLoading(false);
      }
    };
    fetchGroup();
  }, [groupId]);

  return !isLoading ? (
    <Card className={`card-main group-rules-card`}>
      <Flex vertical>
        <ReactQuill
          theme='snow'
          modules={modules}
          value={group?.groupRules}
          onChange={(e) =>
            setGroup((prevState) => ({ ...prevState!, groupRules: e }))
          }
        />
        <ButtonComponent
          text={t('submit')}
          onClick={() => {
            if (group) props.saveNewRules(group);
          }}
          style={{ alignSelf: 'flex-end' }}
          className='primary-button mt-1'
        />
      </Flex>
    </Card>
  ) : (
    <Card className={`card-main group-rules-card`}>
      <Skeleton active />
      <Skeleton active />
    </Card>
  );
};

export default EditGroupRules;
