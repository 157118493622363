import { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import LastGame from './last-game/last-game.component';
import { Pagination, Autoplay } from 'swiper/modules';
import { GameDto, UserDto } from '../../../types/dto.types';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import { Empty, Flex, Skeleton } from 'antd';
import Title from '../../../components/title/title.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';

interface LastGamesSwiperProps {
  user: UserDto;
}

const LastGamesSwiper: FC<LastGamesSwiperProps> = (props) => {
  const { user } = props;
  const [lastGames, setLastGames] = useState<GameDto[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation('dashboard');

  useEffect(() => {
    const getLastGames = async () => {
      setLoading(true);
      const lastResponse = await Server.game.getLastGames({
        userId: user.id,
      });
      if (lastResponse) {
        setLastGames(lastResponse);
      }
      setLoading(false);
    };

    getLastGames();
  }, [user]);

  return !loading ? (
    <>
      <Title main={t('last_games.title')} />
      {lastGames && lastGames.length > 0 ? (
        <>
          <Swiper
            className='mt-1'
            spaceBetween={50}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              950: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              1340: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
              1450: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
              1920: {
                slidesPerView: 4,
                spaceBetween: 16,
              },
            }}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            slidesPerView={4}
          >
            {lastGames?.map((lastGame) => (
              <SwiperSlide key={lastGame.id} style={{ paddingBottom: '28px' }}>
                <LastGame key={lastGame.id} game={lastGame} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <Flex
          vertical
          justify='center'
          align='center'
          style={{ height: '200px', width: '100%' }}
        >
          <Empty
            image={<EyeInvisibleOutlined />}
            imageStyle={{ fontSize: '42px' }}
            description={t('last_games.no_last_user')}
          />
        </Flex>
      )}
    </>
  ) : (
    <Skeleton active />
  );
};

export default LastGamesSwiper;
