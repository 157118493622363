import axios from 'axios';
import { serverUrl } from '../server.constants';
import { NotificationDto } from '../../types/dto.types';
import { NotificationService } from '../../utilities/services/notification.service';

export class NotificationServer {
  private readonly basePath = `${serverUrl}notification`;

  async subscribe() {
    try {
      await NotificationService.requestPermission();

      let subscription = await NotificationService.getSubscription();

      if (!subscription) {
        subscription = await NotificationService.subscribe();
      }

      await axios({
        method: 'POST',
        url: `${this.basePath}/subscribe`,
        responseType: 'json',
        data: subscription,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async unsubscribe() {
    try {
      const subscription = await NotificationService.unsubscribe();
      if (subscription) {
        await axios({
          method: 'DELETE',
          url: `${this.basePath}/unsubscribe`,
          responseType: 'json',
          data: subscription,
        });
      }
    } catch (error) {}
  }

  async getNotificationsForUser(): Promise<NotificationDto[]> {
    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
    });

    return response.data;
  }

  async seeNotifications() {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/see`,
      responseType: 'json',
    });

    return response.data;
  }
}
