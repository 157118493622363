import { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { SelectOptionsProps } from '../../types/props.types';
import { Select } from 'antd';
import { TeamEnum } from '../../types/enums.types';

interface SearchSelectProps {
  placeholder: string;
  options: SelectOptionsProps[];
  style?: CSSProperties;
  team?: TeamEnum;
  value?: any;
  handlePlayerSelect?: (selectedUserId: string, team: TeamEnum) => void;
  setSelectedPlayerId?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
}

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const SearchSelect: FC<SearchSelectProps> = (props) => {
  return (
    <Select
      showSearch
      className='w-100'
      placeholder={props.placeholder}
      options={props.options}
      filterOption={filterOption}
      style={props.style}
      value={props.value}
      disabled={props.disabled}
      onChange={(selectedUserId: string) => {
        if (props.handlePlayerSelect) {
          if (props.team) {
            props.handlePlayerSelect(selectedUserId, props.team);
          }
        } else if (props.setSelectedPlayerId) {
          props.setSelectedPlayerId(selectedUserId);
        }
      }}
    />
  );
};

export default SearchSelect;
