import { Tabs, TabsProps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import Games from './games/games.component';
import NewGame from './new-game/new-game.component';
import { useTranslation } from 'react-i18next';
import EditGames from './edit-games/edit-games.component';
import { AuthContext } from '../../context/auth-context';
import Locked from '../../components/locked/locked.component';
import { useSearchParams } from 'react-router-dom';

const GamesPanel = () => {
  const { isGroupOwner, userLock } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState<string>('1');
  const { t } = useTranslation('games');

  useEffect(() => {
    if (searchParams.get('gameId')) {
      setActiveKey('3');
    }
  }, [searchParams]);

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.games').toUpperCase(),
      children: <Games />,
    },
    {
      key: '2',
      label: t('tabs.new_game').toUpperCase(),
      children: <NewGame />,
    },
    {
      key: '3',
      label: t('tabs.edit_game').toUpperCase(),
      children: <EditGames />,
    },
  ];

  return (
    <div>
      {isGroupOwner ? (
        <>
          {!userLock ? (
            <Tabs
              activeKey={activeKey}
              items={tabItems}
              destroyInactiveTabPane={true}
              onTabClick={(key) => {
                setActiveKey(key);
              }}
            ></Tabs>
          ) : (
            <Locked userLock={userLock} />
          )}
        </>
      ) : (
        <>{!userLock ? <Games /> : <Locked userLock={userLock} />}</>
      )}
    </div>
  );
};

export default GamesPanel;
