import { useContext } from 'react';
import { Col, Row } from 'antd';
import NewestOpenGames from './newest-open-games/newest-open-games.component';
import StatsOverview from './stats-overview/stats-overview.component';
import LastGamesSwiper from './last-games/last-games-swiper.component';
import { AuthContext } from '../../context/auth-context';
import VotingGames from './voting-games/voting-games-component';
import PendingGames from './pending-games/pending-games-component';
import Leaderboard from './leaderboard/leaderboard.component';

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {user && (
        <>
          <Row>
            <VotingGames user={user} />
          </Row>
          <Row gutter={[0, 16]} className='mt-1'>
            <PendingGames user={user} />
            <Col span={24}>
              <NewestOpenGames />
            </Col>
          </Row>
          <Row
            gutter={[{ xs: 0, sm: 0, md: 0, lg: 16, xl: 16 }, 16]}
            className='mt-1'
          >
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
              <StatsOverview user={user} />
            </Col>

            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
              <Leaderboard />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col span={24}>
              <LastGamesSwiper user={user} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Dashboard;
