import { Divider, Empty, Flex, List } from 'antd';
import EditPendingGame from './edit-pending/edit-pending-game.component';
import { useContext, useEffect, useState } from 'react';
import { GameDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { GameStatus } from '../../../types/enums.types';
import { AuthContext } from '../../../context/auth-context';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/title/title.component';
import EditGameHeader from './edit-game-header/edit-game-header.component';
import EditOpenGame from './edit-open/edit-open-game.component';
import Spinner from '../../../components/spinner/spinner.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

const EditGames = () => {
  const { groupId, user } = useContext(AuthContext);
  const { t } = useTranslation('games');
  const [searchParams] = useSearchParams();
  const [openGlobalGames, setOpenGlobalGames] = useState<GameDto[]>([]);
  const [openGroupGames, setOpenGroupGames] = useState<GameDto[]>([]);
  const [pendingGames, setPendingGames] = useState<GameDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [editingGame, setEditingGame] = useState<{
    id: string;
    status: GameStatus;
  }>({ id: '', status: GameStatus.STATUS_PENDING });

  useEffect(() => {
    const gameId = searchParams.get('gameId');
    const status = searchParams.get('status') as GameStatus;

    setLoading(true);
    const fetchGames = async () => {
      if (groupId) {
        const openGroupResponse = await Server.game.getOpenGames({
          status: GameStatus.STATUS_OPEN_GROUP,
          groupId: groupId,
        });
        if (openGroupResponse) {
          setOpenGroupGames(openGroupResponse);
        }

        const openGlobalResponse = await Server.game.getOpenGames({
          status: GameStatus.STATUS_OPEN_GLOBAL,
          groupId: groupId,
        });
        if (openGlobalResponse) {
          setOpenGlobalGames(openGlobalResponse);
        }

        const pendingResponse = await Server.game.getPendingGames({
          groupId: groupId,
        });
        if (pendingResponse) {
          setPendingGames(pendingResponse);
        }
      }

      setLoading(false);
    };

    fetchGames();
    if (gameId && status) {
      setEditingGame({ id: gameId, status: status });
      return;
    }
    setReload(false);
  }, [groupId, reload, searchParams]);

  const renderEditGames = () => {
    if (!editingGame.id) {
      return (
        <>
          {openGroupGames.length > 0 || openGlobalGames.length > 0 ? (
            <>
              <Title
                main={t('edit_game.open_title')}
                desc1={
                  user?.userGroups.find((ug) => ug.groupId === groupId)
                    ?.groupName
                }
              />
              <List
                split
                className={'edit-game-list'}
                dataSource={[...openGlobalGames, ...openGroupGames]}
                renderItem={(item, index) => (
                  <EditGameHeader
                    game={item}
                    key={index}
                    className={
                      index ===
                      openGlobalGames.length + openGroupGames.length - 1
                        ? 'edit-game-list-item-last'
                        : 'edit-game-list-item'
                    }
                    setEditingGame={setEditingGame}
                  />
                )}
              />
              <Divider />
            </>
          ) : (
            <></>
          )}
          {pendingGames.length > 0 ? (
            <>
              <Title
                main={t('edit_game.pending_title')}
                desc1={
                  user?.userGroups.find((ug) => ug.groupId === groupId)
                    ?.groupName
                }
              />
              <List
                split
                className={'edit-game-list'}
                dataSource={pendingGames}
                renderItem={(item, index) => (
                  <EditGameHeader
                    game={item}
                    key={index}
                    className={
                      index === pendingGames.length - 1
                        ? 'edit-game-list-item-last'
                        : 'edit-game-list-item'
                    }
                    setEditingGame={setEditingGame}
                  />
                )}
              />
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      if (editingGame.status === GameStatus.STATUS_PENDING) {
        return (
          <EditPendingGame
            gameId={editingGame.id}
            setReload={setReload}
            setEditingGame={setEditingGame}
          />
        );
      } else {
        return (
          <EditOpenGame
            gameId={editingGame.id}
            reload={reload}
            setReload={setReload}
            setEditingGame={setEditingGame}
          />
        );
      }
    }
  };

  const renderEditGamePage = () => {
    if (
      openGroupGames.length > 0 ||
      openGlobalGames.length > 0 ||
      pendingGames.length > 0
    ) {
      return renderEditGames();
    } else {
      return (
        <Flex
          vertical
          justify='center'
          align='center'
          style={{ height: '400px', width: '100%' }}
        >
          {' '}
          <Empty
            image={<EyeInvisibleOutlined />}
            imageStyle={{ fontSize: '42px' }}
            description={t('edit_game.no_data')}
          />
        </Flex>
      );
    }
  };

  return !loading ? renderEditGamePage() : <Spinner />;
};

export default EditGames;
