import { Rate } from 'antd';
import { useEffect, useState } from 'react';
import { RatingsByTypeDto } from '../../types/dto.types';
import { Server } from '../../server/server';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: string;
  gameType: string;
}

const RatingCard = (props: Props) => {
  const [ratingsByType, setRatingsByType] = useState<RatingsByTypeDto>({});
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAverageRating = async () => {
      const response = await Server.rating.getOneAverageRating(
        props.userId,
        props.gameType
      );
      setRatingsByType(response);
    };

    fetchAverageRating();
  }, [props]);

  return (
    <div>
      <p className='text-uppercase text-center'>
        {t(`common:game_types.${props.gameType}`)}
      </p>
      <Rate
        disabled
        count={10}
        className='avg-rate-component'
        value={Number(ratingsByType[props.gameType])}
      />
    </div>
  );
};

export default RatingCard;
