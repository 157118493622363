import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { GameDto, UserGameDto, VoteMvpDto } from '../../types/dto.types';
import { Avatar, Col, Flex, List, Popconfirm, Row, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';
import { Server } from '../../server/server';
import { CheckCircleOutlined } from '@ant-design/icons';
import Initials from '../initials/initials.component';

interface MvpTeamsProps {
  playersTeam1: UserGameDto[];
  playersTeam2: UserGameDto[];
  ableToVote: boolean;
  setAbleToVote: Dispatch<SetStateAction<boolean>>;
  game: GameDto;
  choiceId: string;
  setChoiceId: Dispatch<SetStateAction<string>>;
}

const MvpTeams: FC<MvpTeamsProps> = (props) => {
  const { t } = useTranslation('dashboard');
  const { user } = useContext(AuthContext);

  const vote = async (voteForId: string) => {
    if (user) {
      const voteMvp: VoteMvpDto = {
        gameId: props.game.id,
        voterId: user?.id,
        voteForId: voteForId,
      };

      try {
        await Server.voteMvp.createVoteMvp(voteMvp);
        notification.success({
          message: t('voting.vote_success'),
        });
        props.setAbleToVote(false);
        props.setChoiceId(voteForId);
      } catch (error) {}
    }
  };

  return (
    <>
      <Row justify='space-between'>
        <h3>{t('common:team1')}</h3>
        <h3 className='text-end'>{t('common:team2')}</h3>
      </Row>
      <Row
        gutter={{ xs: 24, sm: 24, md: 16, xl: 16, xxl: 16 }}
        align='top'
        className='scrollable-200'
        style={{ border: 'none', borderRadius: '0' }}
      >
        <Col span={12}>
          <List
            dataSource={props.playersTeam1}
            renderItem={(item, index) => (
              <List.Item className='justify-start'>
                {item.profileImage ? (
                  <Avatar
                    src={item.profileImage}
                    className='me-s-1'
                    size={32}
                  />
                ) : (
                  <Initials
                    firstName={item.firstName}
                    lastName={item.lastName}
                    size={32}
                    className='me-s-1'
                  />
                )}
                <Popconfirm
                  title={
                    t('voting.vote_confirm') +
                    `${item.firstName} ${item.lastName}?`
                  }
                  overlayStyle={{
                    backgroundColor: `var(--cta-secondary-bg)`,
                    border: '1px solid var(--gray)',
                    borderRadius: '8px',
                  }}
                  okText={t('common:confirm')}
                  cancelText={t('common:cancel')}
                  onConfirm={() => vote(item.userId)}
                  disabled={!props.ableToVote || user?.id === item.userId}
                >
                  <Flex
                    align='center'
                    gap={4}
                    className={user?.id === item.userId ? 'gray-color' : ''}
                    style={{ cursor: 'pointer' }}
                  >
                    <div>{item.firstName}</div>
                    <div className='first-letter'>{item.lastName}</div>

                    {props.choiceId === item.userId ? (
                      <CheckCircleOutlined
                        style={{ fontSize: '18px', color: 'gold' }}
                      />
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Popconfirm>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <List
            dataSource={props.playersTeam2}
            renderItem={(item, index) => (
              <List.Item className='justify-end'>
                <Popconfirm
                  title={
                    t('voting.vote_confirm') +
                    `${item.firstName} ${item.lastName}?`
                  }
                  overlayStyle={{
                    backgroundColor: `var(--cta-secondary-bg)`,
                    border: '1px solid var(--gray)',
                    borderRadius: '8px',
                  }}
                  okText={t('common:confirm')}
                  cancelText={t('common:cancel')}
                  onConfirm={() => vote(item.userId)}
                  disabled={!props.ableToVote || user?.id === item.userId}
                >
                  <Flex
                    align='center'
                    gap={4}
                    className={
                      user?.id === item.userId ? 'me-s-1 gray-color' : 'me-s-1'
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {props.choiceId === item.userId ? (
                      <CheckCircleOutlined
                        style={{ fontSize: '18px', color: 'gold' }}
                      />
                    ) : (
                      <></>
                    )}
                    <div>{item.firstName}</div>
                    <div className='first-letter'>{item.lastName}</div>
                  </Flex>
                </Popconfirm>
                {item.profileImage ? (
                  <Avatar src={item.profileImage} size={32} />
                ) : (
                  <Initials
                    firstName={item.firstName}
                    lastName={item.lastName}
                    size={32}
                  />
                )}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default MvpTeams;
