import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { CSSProperties, FC } from 'react';

interface ButtonProps {
  className?: string;
  size?: SizeType;
  text?: string;
  icon?: JSX.Element;
  htmlType?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLOrSVGElement>;
  style?: CSSProperties | undefined;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonComponent: FC<ButtonProps> = (props) => {
  return (
    <Button
      className={props.className}
      size={props.size}
      icon={props.icon}
      htmlType={props.htmlType}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      loading={props.loading}
    >
      {props.text}
    </Button>
  );
};

export default ButtonComponent;
