import { GamesWithPageDto } from '../../types/dto.types';
import { GameState } from '../../types/enums.types';

export const calculateCompareStats = (
  data: GamesWithPageDto,
  players: string[][]
) => {
  const games = data.games;
  let teamLeft = players[0];
  let teamRight = players[1];

  const results = {
    gamesPlayed: data.count,
    gamesWon: 0,
    gamesLost: 0,
    gamesDraw: 0,
    mvpCountLeft: 0,
    mvpCountRight: 0,
  };

  for (const game of games) {
    if (
      game.userGames.find((ug) => ug.userId === teamLeft[0])?.state ===
      GameState.WIN
    ) {
      results.gamesWon++;
    } else if (
      game.userGames.find((ug) => ug.userId === teamLeft[0])?.state ===
      GameState.LOSS
    ) {
      results.gamesLost++;
    } else if (
      game.userGames.find((ug) => ug.userId === teamLeft[0])?.state ===
      GameState.DRAW
    ) {
      results.gamesDraw++;
    }

    if (teamLeft.length && teamLeft.includes(game.gameMVP)) {
      results.mvpCountLeft++;
    } else if (teamRight.length && teamRight.includes(game.gameMVP)) {
      results.mvpCountRight++;
    }
  }

  return results;
};
