import { useContext, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  notification,
} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../../context/auth-context';
import { Server } from '../../../server/server';

const Login = () => {
  const { login, loginWithGoogle } = useContext(AuthContext);
  const { t } = useTranslation('auth');
  const [email, setEmail] = useState<string>('');
  const [resetPassEmail, setResetPassEmail] = useState<string>('');
  const [isResetPassEmailValid, setIsResetPassEmailValid] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isForgotModalOpen, setIsForgotModalOpen] = useState<boolean>(false);

  const handleFormValuesChange = (changedValues: any) => {
    if ('email' in changedValues) {
      setEmail(changedValues.email.toLowerCase().trim());
    }
    if ('password' in changedValues) {
      setPassword(changedValues.password);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    await login(email, password);
    setLoading(false);
  };

  const googleLogin = async (credentialResponse: CredentialResponse) => {
    setLoading(true);
    await loginWithGoogle(credentialResponse.credential!);
    setLoading(false);
  };

  const handleSendResetPasswordLink = async () => {
    try {
      if (resetPassEmail && validateEmail(resetPassEmail)) {
        await Server.auth.sendResetPasswordLink(resetPassEmail);

        notification.success({ message: t('forgot_password.sent_success') });
        setIsForgotModalOpen(false);
        setResetPassEmail('');
      }
    } catch (error) {}
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      <Flex justify='center' align='center' className='min-vh-100'>
        <Card className='container login-wrap card-main p-0'>
          <Row gutter={{ xs: 0, sm: 0, md: 16 }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              className='bg-dark-img d-flex align-items-center space-around p-1'
            >
              <div>
                <h3 className='logo-temp m-0'>Balunko</h3>
                <p className='text-center'>Pronađi termin i igraj.</p>
                <p className='text-small'>balunko.com</p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} className='py-lg-5'>
              <Form
                name='login'
                className='login-form p-1'
                onFinish={() => handleLogin()}
                onValuesChange={handleFormValuesChange}
              >
                <h1 className='m-0 bold text-center'>
                  {t('sign_in.sign_in_title')}
                </h1>
                <div className='mt-1'>
                  <Form.Item
                    name='email'
                    rules={[
                      { required: true, message: t('field_required') },
                      { type: 'email', message: t('email_invalid') },
                    ]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      size='large'
                      className='login-input mt-3'
                      prefix={
                        <div className='login-input-icon input-icon'>
                          <MailOutlined />
                        </div>
                      }
                      placeholder={t('e_mail')}
                    />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    rules={[
                      { required: true, message: t('field_required') },
                      { min: 8, message: t('pass_invalid') },
                    ]}
                    validateTrigger='onBlur'
                    className='mb-0'
                  >
                    <Input.Password
                      size='large'
                      className='login-input mt-1'
                      prefix={
                        <div className='login-input-icon input-icon'>
                          <LockOutlined className='site-form-item-icon' />
                        </div>
                      }
                      placeholder={t('password')}
                    />
                  </Form.Item>
                  <Flex justify='end' className='m-05'>
                    <span
                      className='forgot-password-link'
                      onClick={() => setIsForgotModalOpen(true)}
                    >
                      {t('forgot_password.title')}
                    </span>
                  </Flex>
                </div>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    className='primary-button'
                    style={{ width: '100%' }}
                    loading={loading}
                  >
                    {t('sign_in.sign_in_title').toUpperCase()}
                  </Button>
                </Form.Item>
                <div className='text-center'>
                  {t('sign_in.no_account').toUpperCase()}
                  <br />
                  <Link to='/register'>
                    {t('sign_in.sign_up_sugg').toUpperCase()}
                  </Link>
                </div>
                <Divider>{t('or').toUpperCase()}</Divider>
                <div className='d-flex space-around'>
                  <div>
                    <GoogleLogin onSuccess={googleLogin} />
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Card>
      </Flex>
      <Modal
        open={isForgotModalOpen}
        onCancel={() => setIsForgotModalOpen(false)}
        okText={t('common:confirm')}
        cancelText={t('common:cancel')}
        onOk={handleSendResetPasswordLink}
        okButtonProps={{ disabled: !isResetPassEmailValid }}
      >
        <h4>{t('forgot_password.instructions')}</h4>
        <Form>
          <Form.Item
            validateStatus={
              isResetPassEmailValid || !resetPassEmail ? '' : 'error'
            }
            help={
              isResetPassEmailValid || !resetPassEmail ? '' : t('email_invalid')
            }
          >
            <Input
              size='large'
              className='login-input mt-3'
              prefix={
                <div className='login-input-icon input-icon'>
                  <MailOutlined />
                </div>
              }
              placeholder={t('e_mail')}
              onChange={(e) => {
                setResetPassEmail(e.target.value.toLowerCase().trim());
                setIsResetPassEmailValid(validateEmail(e.target.value));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Login;
