import {
  ApplicationDto,
  CreateApplicationDto,
  OpenGameApplicationDto,
} from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class OpenGameApplicationServer {
  private readonly basePath = `${serverUrl}open-game-application`;

  // GET ALL APPLICATIONS
  async getAllApplications(gameId: string): Promise<OpenGameApplicationDto[]> {
    const params = new URLSearchParams();
    params.append('gameId', gameId);

    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // GET NUMBER OF APPLICATIONS CLOSE TO THE GAME
  async getApplicationsCloseTo(gameId: string): Promise<number> {
    const params = new URLSearchParams();
    params.set('gameId', gameId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/close-to`,
      responseType: 'json',
      params,
    });

    return Number(response.data);
  }

  // CHECK IF USER ALREADY APPLIED
  async checkIfUserApplied(gameId: string): Promise<boolean> {
    const params = new URLSearchParams();
    params.append('gameId', gameId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/did-apply`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // CREATE APPLICATION
  async createApplication(createApplicationDto: CreateApplicationDto) {
    const response = await axios({
      method: 'POST',
      url: this.basePath,
      responseType: 'json',
      data: createApplicationDto,
    });

    return response.data;
  }

  // APPROVE APPLICATION
  async approveApplication(applicationDto: ApplicationDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/approve`,
      responseType: 'json',
      data: applicationDto,
    });

    return response.data;
  }

  // REJECT APPLICATION
  async rejectApplication(applicationDto: ApplicationDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/reject`,
      responseType: 'json',
      data: applicationDto,
    });

    return response.data;
  }

  // DELETE APPLICATION
  async deleteApplication(gameId: string) {
    const params = new URLSearchParams();
    params.append('gameId', gameId);

    const response = await axios({
      method: 'DELETE',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
