import { Avatar, Flex, Popover } from 'antd';
import { GameTypeImages } from '../../../../common/game-type-images';
import { GameDto } from '../../../../types/dto.types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import OpenGame from '../../../open-games-panel/open-games/open-game/open-game.component';
import { dayName } from '../../../../utilities/data/day-name';
import ButtonComponent from '../../../../components/buttons/button.component';

interface Props {
  index: number;
  openGame: GameDto;
}

const NewestOpenGame = (props: Props) => {
  const { t } = useTranslation(['dashboard', 'open-games']);
  const { openGame } = props;

  const item = {
    type: openGame.type,
    groupName: openGame.groupName,
    groupId: openGame.groupId,
    status: openGame.status,
    date: dayjs(openGame.date).format('DD.MM.YYYY.'),
    hour: dayjs(openGame.date).format('HH:mm'),
    location: openGame.location,
    availableSpots: openGame.openPlayerPositions,
    specialSpots: openGame.openGoalkeeperPositions,
  };

  return (
    <Flex key={props.index} justify='space-between' align='start'>
      <Flex gap={18} align='center'>
        <Avatar
          size={52}
          src={
            <img
              src={GameTypeImages[item.type as keyof typeof GameTypeImages]}
              alt='avatar'
            />
          }
        />
        <div>
          <h4 className='m-0 text-uppercase text-italic'>
            {t(`common:game_types.${item.type}`)}
          </h4>
          <Flex
            className={
              item.availableSpots > 0 && item.specialSpots > 0 ? 'nog-day' : ''
            }
          >
            <p className='m-0'>
              {t(`common:days.${dayName(openGame.date)}`)},&nbsp;
            </p>
            <p className='m-0'>{item.date}</p>
          </Flex>
          <h3 className='m-0 bold'>{item.hour}</h3>
        </div>
      </Flex>
      <Flex vertical gap={12}>
        <Flex
          justify='space-between'
          align='end'
          className={
            item.availableSpots > 0 && item.specialSpots > 0
              ? 'nog-available'
              : ''
          }
        >
          <p className='m-0'>{t('open.available_spots')}:&nbsp;</p>
          <Flex gap={4} className='green-color bold'>
            {item.availableSpots > 0 ? (
              <p className='m-0'>
                {item.availableSpots}{' '}
                {item.availableSpots > 1 ? t('open.players') : t('open.player')}
              </p>
            ) : (
              <></>
            )}
            {item.availableSpots > 0 && item.specialSpots > 0 ? (
              <p className='m-0'>+</p>
            ) : (
              <></>
            )}
            {item.specialSpots > 0 ? (
              <p className='m-0'>
                {item.specialSpots}{' '}
                {item.specialSpots < 2
                  ? t('open.goalkeeper')
                  : t('open.goalkeepers')}
              </p>
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
        <div className='text-end'>
          <Popover
            placement='bottomLeft'
            destroyTooltipOnHide
            key={props.index}
            trigger={'click'}
            content={
              <OpenGame
                game={props.openGame}
                loading={false}
                source={'dashboard'}
              />
            }
          >
            <ButtonComponent
              className='secondary-button'
              text={t('common:details')}
            />
          </Popover>
        </div>
      </Flex>
    </Flex>
  );
};

export default NewestOpenGame;
