import { useCallback, useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Server } from '../../server/server';
import { Card, Flex, Skeleton, Tooltip } from 'antd';
import EditGroupRules from './edit-group-rules/edit-group-rules.component';
import { AuthContext } from '../../context/auth-context';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { GroupDto } from '../../types/dto.types';
import { useTranslation } from 'react-i18next';

const GroupRules = () => {
  const { groupId, isGroupOwner } = useContext(AuthContext);
  const { t } = useTranslation('group-rules');
  const [rules, setRules] = useState<ReactQuill.Value>('');
  const [updatedRules, setUpdatedRules] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);

  const saveNewRules = useCallback(
    async (group: GroupDto) => {
      setIsLoading(true);

      if (groupId) {
        await Server.group.updateGroup({
          groupId: groupId,
          groupRules: group.groupRules,
        });
      }
      setUpdatedRules(group.groupRules);
      setEdit(false);
      setIsLoading(false);
    },
    [groupId, setIsLoading]
  );

  useEffect(() => {
    const fetchGroupRules = async () => {
      if (groupId) {
        setIsLoading(true);
        try {
          const group = await Server.group.getGroupById(groupId);
          if (group?.groupRules) {
            setRules(group.groupRules);
          } else {
            setRules('');
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchGroupRules();
  }, [groupId, updatedRules]);

  return !isLoading ? (
    <Flex vertical justify='center' align='center'>
      <Flex align='center' justify='center' gap={'12px'}>
        <h1>{t('title').toUpperCase()}</h1>
        {isGroupOwner ? (
          edit ? (
            <Tooltip title={t('tooltips.cancel')}>
              <CloseOutlined onClick={() => setEdit(false)} />
            </Tooltip>
          ) : (
            <Tooltip title={t('tooltips.edit')}>
              <EditOutlined onClick={() => setEdit(true)} />
            </Tooltip>
          )
        ) : (
          <></>
        )}
      </Flex>
      {edit ? (
        <EditGroupRules saveNewRules={saveNewRules} />
      ) : (
        <Card className={`card-main group-rules-card`}>
          <Flex justify='center'>
            <div
              className='container'
              dangerouslySetInnerHTML={{ __html: rules.toString() }}
            ></div>
          </Flex>
        </Card>
      )}
    </Flex>
  ) : (
    <Card className={`card-main group-rules-card`}>
      <Skeleton active />
      <Skeleton active />
    </Card>
  );
};

export default GroupRules;
