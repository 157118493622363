import {
  EyeInvisibleOutlined,
  QuestionCircleOutlined,
  StarFilled,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Flex,
  List,
  Rate,
  Select,
  Tooltip,
  notification,
} from 'antd';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GameTypeDto } from '../../../../types/dto.types';
import { Server } from '../../../../server/server';
import { AuthContext } from '../../../../context/auth-context';
import { useParams } from 'react-router-dom';

interface Props {
  ownProfile: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const RateMenu = ({ ownProfile, setReload }: Props) => {
  const { t } = useTranslation('profile');
  const { user } = useContext(AuthContext);
  const descriptions = useRef<string[]>([
    t('rating.level.rookie'),
    t('rating.level.rookie'),
    t('rating.level.recreational'),
    t('rating.level.recreational'),
    t('rating.level.recreational'),
    t('rating.level.recreational'),
    t('rating.level.athlete'),
    t('rating.level.athlete'),
    t('rating.level.athlete'),
    t('rating.level.athlete'),
  ]);
  const [value, setValue] = useState(0);
  const [selectedGameType, setSelectedGameType] = useState<{
    type: string;
    id: string;
  }>();
  const [gameTypes, setGameTypes] = useState<GameTypeDto[]>([]);
  const params = useParams();
  const userId = useRef<string>(params.id ?? '');

  useEffect(() => {
    const fetchGameTypes = async () => {
      if (user) {
        const response = await Server.rating.getGameTypesToRate(
          ownProfile ? user.id : userId.current
        );
        setGameTypes(response);
      }
    };

    fetchGameTypes();
  }, [ownProfile, user]);

  const handleGameTypeChange = async (value: string) => {
    const selectedOption = gameTypes.find((option) => option.id === value);
    if (selectedOption && user) {
      setSelectedGameType({
        type: selectedOption?.type,
        id: selectedOption?.id,
      });

      const existingRating = await Server.rating.getOneRating(
        ownProfile ? user.id : userId.current,
        selectedOption.id
      );
      setValue(existingRating.rating);
    }
  };

  const handleRatingSubmit = async () => {
    if (user && selectedGameType) {
      try {
        await Server.rating.rateUser({
          ratedUserId: ownProfile ? user.id : userId.current,
          gameTypeId: selectedGameType.id,
          rating: value,
        });
        setReload(true);
        notification.success({
          message: t('rating.success', {
            value: value,
            type: t(`common:game_types.${selectedGameType.type}`),
          }),
        });
      } catch (error) {}
    }
  };

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Flex gap={12}>
        <h3 className='m-0'>{t('rating.title')}</h3>
        <Tooltip
          placement='right'
          title={ownProfile ? t('rating.self_tooltip') : t('rating.tooltip')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Flex>
      <List size='small' split={false}>
        <List.Item>
          <Flex gap={12}>
            <div>
              1 - 2 <StarFilled />
            </div>
            {t('rating.level.rookie')}
          </Flex>
        </List.Item>
        <List.Item>
          <Flex gap={12}>
            <div>
              3 - 6 <StarFilled />
            </div>
            {t('rating.level.recreational')}
          </Flex>
        </List.Item>
        <List.Item>
          <Flex gap={12}>
            <div>
              7 - 10 <StarFilled />
            </div>
            {t('rating.level.athlete')}
          </Flex>
        </List.Item>
      </List>
      <Select
        placeholder={t('rating.choose_type')}
        className='w-100 mt-1'
        options={gameTypes.map((gameType) => {
          return {
            label: t(`common:game_types.${gameType.type}`),
            value: gameType.id,
          };
        })}
        onChange={handleGameTypeChange}
        notFoundContent={
          <div style={{ paddingBottom: '24px' }}>
            <Empty
              image={<EyeInvisibleOutlined />}
              imageStyle={{ fontSize: '36px' }}
              description={t('rating.no_data')}
            />
          </div>
        }
      />
      <Rate
        disabled={!selectedGameType?.id}
        tooltips={descriptions.current}
        onChange={setValue}
        value={value}
        count={10}
        className='rate-component w-100 text-center mt-1'
      />{' '}
      {value ? (
        <h1 className='text-small text-uppercase text-center'>
          {t(`common:game_types.${selectedGameType?.type}`)}:{' '}
          {descriptions.current[value - 1]}
        </h1>
      ) : (
        ''
      )}
      <Flex justify='flex-end'>
        <Tooltip
          title={value < 1 || !selectedGameType?.id ? t('rating.disabled') : ''}
        >
          <div>
            <Button
              disabled={value < 1 || !selectedGameType?.id}
              className='primary-button mt-1'
              onClick={handleRatingSubmit}
            >
              {t('rating.submit')}
            </Button>
          </div>
        </Tooltip>
      </Flex>
    </Col>
  );
};

export default RateMenu;
