import { Card, Descriptions, Flex } from 'antd';
import { GameDto, OrganizerStatsDto } from '../../../../../types/dto.types';

import { useTranslation } from 'react-i18next';
import Title from '../../../../../components/title/title.component';
import { useCallback, useEffect, useState } from 'react';
import { Server } from '../../../../../server/server';

interface Props {
  game: GameDto;
}

const OpenGameDetails = ({ game }: Props) => {
  const { t } = useTranslation('open-games');
  const [organizerStats, setOrganizerStats] = useState<OrganizerStatsDto>();
  const [totalScore, setTotalScore] = useState<number>(0);

  const getOrganizerStats = useCallback(async () => {
    if (game.organizer) {
      const response = await Server.user.getOrganizerStats(game.organizer.id);
      if (response) {
        setOrganizerStats(response);
        const totalScore =
          (1 - response.gamesCancelled / response.gamesOrganized) * 100;
        setTotalScore(totalScore);
      }
    }
  }, [game.organizer]);

  useEffect(() => {
    getOrganizerStats();
  }, [getOrganizerStats]);

  const getScoreColorClassName = (score: number) => {
    if (score < 50) {
      return 'red-color';
    } else if (score >= 50 && score < 70) {
      return 'yellow-color';
    } else return 'green-color';
  };

  return (
    <Card
      className={`card-main p-0`}
      style={{
        background: 'var(--cta-main-bg2)',
        border: '1px solid var(--gray)',
        width: '320px',
      }}
    >
      {organizerStats ? (
        <>
          <Flex justify='space-between'>
            <Title
              main={t('game_card.organizer')}
              desc1={`${game.organizer?.firstName} ${game.organizer?.lastName}`}
            />
            <h1
              className={`${getScoreColorClassName(totalScore)} m-0`}
            >{`${totalScore.toFixed(0)} %`}</h1>
          </Flex>
          <Descriptions column={1} bordered size='small' className='mt-1'>
            <Descriptions.Item label={t('game_card.played')}>
              {organizerStats.gamesPlayed}
            </Descriptions.Item>
            <Descriptions.Item label={t('game_card.cancelled')}>
              {organizerStats.gamesCancelled}
            </Descriptions.Item>
            <Descriptions.Item label={t('game_card.organized')}>
              {organizerStats.gamesOrganized}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default OpenGameDetails;
