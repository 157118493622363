import { Card, Col, Empty, Flex, Row, Select, Skeleton } from 'antd';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FC, useEffect, useState } from 'react';
import { ProfileDto, StatsOverviewData } from '../../../types/dto.types';
import { useTranslation } from 'react-i18next';
import PieChartComponent from '../../../components/pie-chart/pie-chart.component';
import Title from '../../../components/title/title.component';
import { EyeInvisibleOutlined, TeamOutlined } from '@ant-design/icons';
import { Server } from '../../../server/server';
import { countGames } from '../../../utilities/data/count-games';

interface StatsByTypeProps {
  profile: ProfileDto;
  loading: boolean;
}

const gameTypeColors = {
  gamesWonOfType: 'var(--green)',
  gamesLostOfType: 'var(--red)',
  gamesDrawOfType: 'var(--gray)',
};

const StatsByType: FC<StatsByTypeProps> = (props) => {
  const { t } = useTranslation('profile');
  const [statsOverview, setStatsOverview] = useState<StatsOverviewData>();
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  useEffect(() => {
    const getUserStats = async () => {
      const stats = await Server.user.getUserStatsByType(
        props.profile.userId,
        selectedGroupId
      );
      setStatsOverview(stats?.gameStats);
    };
    getUserStats();
  }, [props.profile.userId, selectedGroupId]);

  return (
    <Col xs={24} sm={24} md={24} lg={12}>
      <Card className={`card-main h-100`}>
        {!props.loading && statsOverview ? (
          <>
            <Flex vertical gap={32}>
              <Row justify='space-between' gutter={[0, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Title
                    main={t('pie_chart.title')}
                    desc1={`${t('pie_chart.total')}: ${countGames(
                      statsOverview
                    )}`}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Flex align='center' gap={8}>
                    <TeamOutlined />
                    <Select
                      value={selectedGroupId}
                      className='w-100'
                      options={[
                        { value: '', label: t('pie_chart.all_groups') },
                        ...props.profile.groups.map((group) => {
                          return {
                            value: group.groupId,
                            label: group.group.name,
                          };
                        }),
                      ]}
                      onChange={(value) => setSelectedGroupId(value)}
                    />
                  </Flex>
                </Col>
              </Row>
              <div>
                {Object.entries(statsOverview).length > 0 ? (
                  <Swiper
                    className='mt-2'
                    spaceBetween={50}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true,
                      pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                      },
                      1024: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                      },
                      1450: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                      },
                    }}
                    modules={[Autoplay]}
                    slidesPerView={2}
                  >
                    {Object.entries(statsOverview).map(
                      ([gameType, gameTypeStats]) => {
                        const data = (
                          Object.keys(gameTypeStats) as Array<
                            keyof typeof gameTypeStats
                          >
                        )
                          .filter((x) => x !== 'gamesPlayedOfType')
                          .map((y) => {
                            return {
                              name: t(`pie_chart.${y}`),
                              value: gameTypeStats[y],
                              fill: gameTypeColors[
                                y as keyof typeof gameTypeColors
                              ],
                            };
                          });
                        return (
                          <SwiperSlide
                            key={gameType}
                            style={{ paddingBottom: '6px' }}
                          >
                            <div className='text-small bold text-uppercase text-center'>
                              {t(`common:game_types.${gameType}`)} (
                              {gameTypeStats.gamesPlayedOfType})
                            </div>
                            <PieChartComponent
                              data={data}
                              animationDuration={900}
                            />
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                ) : (
                  <Flex align='center' justify='center'>
                    <Empty
                      style={{ marginTop: '64px', marginBottom: '64px' }}
                      image={<EyeInvisibleOutlined />}
                      imageStyle={{ fontSize: '42px' }}
                      description={<p>{t('pie_chart.no_data')}</p>}
                    />
                  </Flex>
                )}
              </div>
            </Flex>
          </>
        ) : (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        )}
      </Card>
    </Col>
  );
};

export default StatsByType;
