import { UserGroupDataDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserGroupServer {
  private readonly basePath = `${serverUrl}user-group`;

  // CREATE USER GROUP
  async createUserGroup(newGroup: UserGroupDataDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: this.basePath,
        responseType: 'json',
        data: newGroup,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  }

  // CHANGE ROLE IN USER GROUP
  async changeRole(updatedGroup: UserGroupDataDto) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.basePath}/change-role`,
        responseType: 'json',
        data: updatedGroup,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  }

  // REMOVE USER FROM USER GROUP
  async removeUserFromGroup(userId: string, groupId: string) {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('groupId', groupId.toString());

    try {
      const response = await axios({
        method: 'DELETE',
        url: `${this.basePath}/remove-user-from-group`,
        responseType: 'json',
        params: params,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  }
}
