import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Server } from '../../../../server/server';
import { DatePicker, Divider, Flex, Select, Tooltip } from 'antd';
import { SelectOptionsProps } from '../../../../types/props.types';
import SearchSelect from '../../../../components/search-select/search-select.component';
import { useTranslation } from 'react-i18next';
import { TeamEnum } from '../../../../types/enums.types';
import ButtonComponent from '../../../../components/buttons/button.component';
import {
  CalendarOutlined,
  DribbbleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import SelectGameType from '../../../../components/select-game-type/select-game-type.component';

interface GamesFilterProps {
  filter: {
    date: string;
    dateTo: string;
    gameTypeId: string;
    playerIds: string[];
    playerRelation: string;
  };
  setFilter: Dispatch<
    SetStateAction<{
      date: string;
      dateTo: string;
      gameTypeId: string;
      playerIds: string[];
      playerRelation: string;
    }>
  >;
  groupId: string;
}

const GamesFilter: FC<GamesFilterProps> = (props) => {
  const { t } = useTranslation('games');
  const { filter, setFilter, groupId } = props;
  const [playerOptions, setPlayerOptions] = useState<SelectOptionsProps[]>([]);

  useEffect(() => {
    const fetchGameTypes = async () => {
      const usersResponse = await Server.user.getAllUsers({
        groupId: groupId,
      });
      const userOptions = usersResponse?.users.map((user) => ({
        value: user.id,
        label: user.firstName + ' ' + user.lastName,
      }));

      if (userOptions) {
        setPlayerOptions(userOptions);
      }
    };

    fetchGameTypes();
  }, [groupId, t]);

  const handlePlayerSelect = (selectedUserId: string, team: TeamEnum) => {
    if (team === TeamEnum.team1) {
      setFilter((prevState) => ({
        ...prevState,
        playerIds: [selectedUserId, prevState.playerIds[1]],
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        playerIds: [prevState.playerIds[0], selectedUserId],
      }));
    }
  };

  const resetFilter = () => {
    setFilter({
      date: '',
      dateTo: '',
      gameTypeId: '',
      playerIds: [],
      playerRelation: '',
    });
  };

  return (
    <>
      <Flex justify='space-around' className='game-filter-container'>
        <div className='mt-1 game-filter-item'>
          <Divider>{t('filter.date_and_type').toUpperCase()}</Divider>
          <Flex gap={'8px'}>
            <CalendarOutlined style={{ fontSize: '18px' }} />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              format={'DD.MM.YYYY.'}
              placeholder={t('common:from')}
              value={filter.date ? dayjs(filter.date, 'DD-MM-YYYY') : null}
              className='w-100'
              onChange={(date) => {
                if (date) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    date: date.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              format={'DD.MM.YYYY.'}
              placeholder={t('common:to')}
              value={filter.dateTo ? dayjs(filter.dateTo, 'DD-MM-YYYY') : null}
              className='w-100'
              onChange={(dateTo) => {
                if (dateTo) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    dateTo: dateTo.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
          </Flex>
          <Flex gap={'8px'} className='mt-1'>
            <DribbbleOutlined style={{ fontSize: '18px' }} />
            <SelectGameType
              value={filter.gameTypeId ? filter.gameTypeId : null}
              className='w-100'
              onChange={(value) => {
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  gameTypeId: value,
                }));
              }}
              style={{ width: '100%' }}
            />
          </Flex>
        </div>

        <div className='mt-1 game-filter-item'>
          <Divider>
            {t('filter.players').toUpperCase()}{' '}
            <Tooltip title={t('filter.tooltips.players')}>
              <InfoCircleOutlined
                style={{ color: 'var(--gray)', cursor: 'pointer' }}
              />
            </Tooltip>
          </Divider>
          <Flex justify='space-between'>
            <Flex vertical style={{ width: '45%' }}>
              <SearchSelect
                placeholder={t('filter.player1')}
                value={filter.playerIds[0] ? filter.playerIds[0] : null}
                options={playerOptions}
                team={TeamEnum.team1}
                handlePlayerSelect={handlePlayerSelect}
              />
            </Flex>
            <Flex vertical style={{ width: '45%' }}>
              <Tooltip
                title={
                  filter.playerIds.length === 0
                    ? t('filter.tooltips.disabled_p2')
                    : ''
                }
              >
                <>
                  <SearchSelect
                    placeholder={t('filter.player2')}
                    value={filter.playerIds[1] ? filter.playerIds[1] : null}
                    options={playerOptions}
                    team={TeamEnum.team2}
                    handlePlayerSelect={handlePlayerSelect}
                    disabled={filter.playerIds.length === 0}
                  />
                </>
              </Tooltip>
            </Flex>
          </Flex>
          <Tooltip
            title={
              !filter.playerIds[0] || !filter.playerIds[1]
                ? t('filter.tooltips.disabled_rel')
                : ''
            }
          >
            <>
              <Select
                placeholder={t('filter.relation.label')}
                value={filter.playerRelation ? filter.playerRelation : null}
                className='w-100 mt-1'
                options={[
                  { value: '=', label: t('filter.relation.together') },
                  { value: '<>', label: t('filter.relation.against') },
                ]}
                onChange={(selectedOption) => {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    playerRelation: selectedOption,
                  }));
                }}
                disabled={!filter.playerIds[0] || !filter.playerIds[1]}
              />
            </>
          </Tooltip>
        </div>
      </Flex>
      <Flex justify='end' className='mt-2' gap={'12px'}>
        <ButtonComponent
          className='secondary-button'
          text={t('common:reset_filter')}
          onClick={resetFilter}
        />
      </Flex>
      <Divider />
    </>
  );
};

export default GamesFilter;
