import dayjs from 'dayjs';

export const dayName = (date: string) => {
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const currentDate = dayjs();
  const inputDate = dayjs(date);

  const isTomorrow = inputDate.isSame(currentDate.add(1, 'day'), 'day');

  if (isTomorrow) {
    return 'tomorrow';
  } else {
    const dayIndex = inputDate.day();
    return daysOfWeek[dayIndex];
  }
};
