import { Card, Col, Row } from 'antd';

import RateMenu from './rate-menu/rate-menu.component';
import AverageRatings from './avg-ratings/avg-ratings.component';
import { useContext, useEffect, useRef, useState } from 'react';
import { RatingsByTypeDto } from '../../../types/dto.types';
import { AuthContext } from '../../../context/auth-context';
import { Server } from '../../../server/server';
import { useParams } from 'react-router-dom';

interface Props {
  ownProfile: boolean;
}

const Rating = ({ ownProfile }: Props) => {
  const { user } = useContext(AuthContext);
  const [ratingsByType, setRatingsByType] = useState<RatingsByTypeDto>({});
  const [reload, setReload] = useState<boolean>(false);
  const params = useParams();
  const userId = useRef<string>(params.id ?? '');

  useEffect(() => {
    const fetchRatings = async () => {
      if (user) {
        const response = await Server.rating.getRatings(
          ownProfile ? user.id : userId.current
        );
        setRatingsByType(response);
      }
    };

    fetchRatings();
    setReload(false);
  }, [user, reload, ownProfile]);

  return (
    <Col span={24}>
      <Card className={`card-cta-secondary h-100 p-0`}>
        <Row gutter={[{ xl: 16, lg: 16, md: 16, sm: 0, xs: 0 }, 24]}>
          <RateMenu ownProfile={ownProfile} setReload={setReload} />
          <AverageRatings ratingsByType={ratingsByType} />
        </Row>
      </Card>
    </Col>
  );
};

export default Rating;
