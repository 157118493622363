import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GameDto } from '../../../types/dto.types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import OpenGamePopup from './open-game-popup/open-game-popup.component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { AuthContext } from '../../../context/auth-context';
import { ThemeEnum } from '../../../types/enums.types';

interface Props {
  openGames: GameDto[];
  gameLocation?: {
    lat: number;
    lng: number;
  };
}

const OpenGameMap = ({ openGames, gameLocation }: Props) => {
  const { theme } = useContext(AuthContext);
  const [clusters, setClusters] = useState<
    { position: L.LatLngExpression; games: GameDto[] }[]
  >([]);
  const zoom = useRef<number>(gameLocation ? 17 : 13);
  const position = useRef<L.LatLngExpression>(
    gameLocation ?? {
      lat: 43.5081,
      lng: 16.4489,
    }
  );

  const calculateClusters = useCallback(() => {
    const clustersMap = new Map<string, GameDto[]>();
    openGames.forEach((game) => {
      const key = `${game.location.latitude}_${game.location.longitude}`;
      const cluster = clustersMap.get(key) || [];
      clustersMap.set(key, [...cluster, game]);
    });
    const newClusters: { position: L.LatLngExpression; games: GameDto[] }[] =
      [];
    clustersMap.forEach((games, key) => {
      const [lat, lng] = key.split('_').map(parseFloat);
      newClusters.push({ position: [lat, lng], games });
    });
    setClusters(newClusters);
  }, [openGames]);

  useEffect(() => {
    calculateClusters();
  }, [calculateClusters]);

  return (
    <div>
      <MapContainer
        className='mt-1'
        center={position.current}
        zoom={zoom.current}
        scrollWheelZoom={true}
        style={{ height: '80vh', borderRadius: '18px' }}
      >
        <TileLayer
          url={`https://tiles.stadiamaps.com/tiles/alidade_smooth${
            theme === ThemeEnum.LIGHT ? '' : '_dark'
          }/{z}/{x}/{y}{r}.png`}
          maxZoom={100}
        />
        {clusters.map((cluster, index) => {
          return (
            <Marker
              key={index}
              position={cluster.position}
              icon={L.divIcon({
                html: `<button class='marker-cluster'>${cluster.games.length}</button>`,
                iconSize: [0, 0],
                iconAnchor: [-12, 16],
                popupAnchor: [0, 12],
              })}
            >
              <Popup
                key={index}
                minWidth={160}
                maxWidth={160}
                className='open-game-popup'
                autoPanPadding={[50, 50]}
              >
                <Swiper
                  key={index}
                  spaceBetween={24}
                  effect={'cards'}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                  }}
                  slidesPerView={1}
                  modules={[Autoplay, Pagination]}
                  pagination={{
                    type: 'fraction',
                    clickable: true,
                    el: '.og-swiper-pag',
                  }}
                >
                  {cluster.games.map((game, i) => (
                    <SwiperSlide key={i}>
                      <OpenGamePopup key={i} game={game} />
                    </SwiperSlide>
                  ))}
                  <div
                    className='og-swiper-pag text-end'
                    style={{ paddingTop: '12px' }}
                  ></div>
                </Swiper>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default OpenGameMap;
