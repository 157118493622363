import { Tabs, TabsProps } from 'antd';
import OpenGameMap from './open-game-map/open-game-map.component';
import OpenGames from './open-games/open-games.component';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GameDto } from '../../types/dto.types';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { Server } from '../../server/server';
import { GameStatus } from '../../types/enums.types';
import dayjs from 'dayjs';
import { setFilterPath } from '../../utilities/data/set-filter-path';
import Filter from '../../components/filter/filter.component';
import OpenGameFilter from './open-game-filter/open-game-filter.component';

const OpenGamePanel = () => {
  const { t } = useTranslation('open-games');
  const { groupId } = useContext(AuthContext);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openGames, setOpenGames] = useState<GameDto[]>([]);
  const [activeKey, setActiveKey] = useState<string>('1');
  const [gameLocation, setGameLocation] = useState<{
    lat: number;
    lng: number;
  }>();
  const [filter, setFilter] = useState<{
    date: string;
    gameTypeId: string;
    groupView: boolean;
    selectedGroup: string;
    gameId?: string;
  }>({
    date: searchParams.get('date') ?? '',
    gameTypeId: searchParams.get('gameTypeId') ?? '',
    groupView: searchParams.get('groupView')
      ? Boolean(searchParams.get('groupView'))
      : location?.state?.groupView ?? false,
    selectedGroup:
      searchParams.get('selectedGroup') ?? location?.state?.groupId ?? '',
    gameId: searchParams.get('gameId') ?? '',
  });
  const [filterCount, setFilterCount] = useState<number>(0);

  const countFilters = useCallback(() => {
    let count = 0;
    if (filter.date) {
      count++;
    }
    if (filter.gameTypeId) {
      count++;
    }
    if (filter.groupView) {
      count++;
    }

    setFilterCount(count);
  }, [filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const fetchOpenGames = async () => {
      if (groupId) {
        const response = await Server.game.getOpenGames({
          status: !filter.groupView ? GameStatus.STATUS_OPEN_GLOBAL : undefined,
          groupId: filter.groupView ? filter.selectedGroup : undefined,
          gameTypeId: filter.gameTypeId ?? undefined,
          date: filter.date
            ? dayjs(filter.date, 'DD-MM-YYYY').toISOString()
            : undefined,
        });
        if (response) {
          setOpenGames(response);
        }
        setLoading(false);
      } else {
        const response = await Server.game.getOpenGames({
          status: GameStatus.STATUS_OPEN_GLOBAL,
          gameTypeId: filter.gameTypeId ?? undefined,
          date: filter.date
            ? dayjs(filter.date, 'DD-MM-YYYY').toISOString()
            : undefined,
        });
        if (response) {
          setOpenGames(response);
        }
        setLoading(false);
      }
    };
    fetchOpenGames();
    countFilters();
    setSearchParams(setFilterPath(filter));
  }, [countFilters, filter, groupId, setOpenGames, setSearchParams]);

  const switchToMapView = (game: GameDto) => {
    setGameLocation({
      lat: game.location.latitude,
      lng: game.location.longitude,
    });
    setActiveKey('2');
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.ads').toUpperCase(),
      children: (
        <>
          <Filter
            children={<OpenGameFilter filter={filter} setFilter={setFilter} />}
            tip={t('filter.tooltip')}
            defaultOpen={false}
            count={filterCount}
          />
          <OpenGames
            openGames={openGames}
            loading={loading}
            filter={filter}
            switchToMapView={switchToMapView}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('tabs.map_view').toUpperCase(),
      children: (
        <>
          <Filter
            children={<OpenGameFilter filter={filter} setFilter={setFilter} />}
            tip={t('filter.tooltip')}
            defaultOpen={false}
            count={filterCount}
          />
          <OpenGameMap openGames={openGames} gameLocation={gameLocation} />
        </>
      ),
    },
  ];

  return (
    <Tabs
      activeKey={activeKey}
      items={tabItems}
      destroyInactiveTabPane={true}
      onTabClick={(key) => {
        setActiveKey(key);
        setGameLocation(undefined);
      }}
    />
  );
};

export default OpenGamePanel;
