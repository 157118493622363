import { Divider, Flex, InputNumber } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  setGameResult: Dispatch<
    SetStateAction<{
      team1: number | undefined;
      team2: number | undefined;
    }>
  >;
}

const EnterGameResult = ({ setGameResult }: Props) => {
  const { t } = useTranslation(['games']);

  return (
    <>
      <Divider>{t('edit_game.result')}</Divider>
      <Flex justify='space-around'>
        <div>
          <p>{t('common:team1')}:</p>
          <InputNumber
            min={0}
            onChange={(value) => {
              if (value || value === 0) {
                setGameResult((prevState) => ({
                  ...prevState,
                  team1: value,
                }));
              } else {
                setGameResult((prevState) => ({
                  ...prevState,
                  team1: undefined,
                }));
              }
            }}
          />
        </div>
        <div>
          <p>{t('common:team2')}:</p>
          <InputNumber
            min={0}
            onChange={(value) => {
              if (value || value === 0) {
                setGameResult((prevState) => ({
                  ...prevState,
                  team2: value,
                }));
              } else {
                setGameResult((prevState) => ({
                  ...prevState,
                  team2: undefined,
                }));
              }
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default EnterGameResult;
