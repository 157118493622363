import { GameDto } from '../../../types/dto.types';

import { Col, Empty, Flex, Row } from 'antd';
import OpenGame from './open-game/open-game.component';

import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/spinner/spinner.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

interface Props {
  openGames: GameDto[];
  loading: boolean;
  filter: {
    date: string;
    gameTypeId: string;
    groupView: boolean;
    selectedGroup: string;
  };
  switchToMapView: (game: GameDto) => void;
}

const OpenGames = ({ openGames, switchToMapView, loading }: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('open-games');

  return !loading ? (
    <>
      <Row gutter={[0, 16]} className='mt-1'>
        <Col span={24}>
          {openGames.length > 0 ? (
            <Row gutter={[{ xxl: 16, lg: 16, md: 0, sm: 0, xs: 0 }, 16]}>
              {openGames.map((openGame) => (
                <Col key={openGame.id} xs={24} sm={24} md={24} xl={8} xxl={6}>
                  <OpenGame
                    key={openGame.id}
                    game={openGame}
                    loading={loading}
                    highlight={searchParams.get('gameId') === openGame.id}
                    source={'open-games'}
                    switchToMapView={switchToMapView}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Flex justify='center' align='center' style={{ minHeight: '30vh' }}>
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '42px' }}
                description={t('no_data')}
              />
            </Flex>
          )}
        </Col>
      </Row>
    </>
  ) : (
    <Spinner />
  );
};

export default OpenGames;
