import { Context, createContext, useCallback, useMemo, useState } from 'react';
import { UserDto, UserLockDto } from '../types/dto.types';
import { Server } from '../server/server';
import { LanguageEnum, ThemeEnum, UserRole } from '../types/enums.types';
import i18n from '../i18next';

interface AuthState {
  isLoggedIn: boolean;
  groupId: string | null;
  user: UserDto | null;
  isGroupOwner: boolean;
  isLoading: boolean;
  userLock: UserLockDto | null;
  theme: ThemeEnum;
  language: LanguageEnum;
}

interface AuthMethods {
  login: (email: string, password: string) => Promise<void>;
  loginWithGoogle: (token: string) => Promise<any>;
  logout: () => void;
  refreshUser: () => Promise<void>;
  checkIfLoggedIn: () => Promise<void>;
  chooseGroup: (id: string) => void;
}

const AuthContextPartial: Context<Partial<AuthState & AuthMethods>> =
  createContext({});

export const AuthContext = AuthContextPartial as Context<
  AuthState & AuthMethods
>;

export const AuthProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<string | null>(null);
  const [user, setUser] = useState<UserDto | null>(null);
  const [isGroupOwner, setIsGroupOwner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userLock, setUserLock] = useState<UserLockDto | null>(null);
  const [theme, setTheme] = useState<ThemeEnum>(ThemeEnum.LIGHT);
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.HR);

  const fetchUser = useCallback(async () => {
    const loggedUser = await Server.user.getUser();
    if (loggedUser != null) {
      setIsLoggedIn(true);
      setUser(loggedUser);
      setTheme(loggedUser.userSettings.theme);
      setLanguage(loggedUser.userSettings.language);

      i18n.changeLanguage(loggedUser.userSettings.language);

      let selectedGroupId = localStorage.getItem('selectedGroupId');
      if (loggedUser.userGroups.length > 0) {
        // SET GROUP AND CHECK IF USER IS GROUP OWNER
        if (selectedGroupId) {
          setGroupId(selectedGroupId);
        } else {
          selectedGroupId = loggedUser.userGroups[0].groupId;
          setGroupId(selectedGroupId);
        }

        if (
          loggedUser.userGroups.find((ug) => ug.groupId === selectedGroupId)
            ?.role === UserRole.admin
        ) {
          setIsGroupOwner(true);
        } else {
          setIsGroupOwner(false);
        }

        // CHECK IF USER IS LOCKED FROM GROUP
        if (loggedUser.userLocks?.length > 0) {
          const activeLock = loggedUser.userLocks.find(
            (lock) =>
              lock.groupId === loggedUser.userGroups[0].groupId &&
              new Date(lock.lockExpiration) >= new Date()
          );

          if (activeLock) {
            setUserLock(activeLock);
          } else {
            setUserLock(null);
          }
        } else {
          setUserLock(null);
        }
      }
    }
    setIsLoading(false);
  }, []);

  const authState = useMemo(
    () => ({
      isLoggedIn,
      user,
      groupId,
      isGroupOwner,
      isLoading,
      userLock,
      theme,
      language,

      login: async (email: string, password: string) => {
        const response = await Server.auth.login(email, password);
        if (response) {
          await fetchUser();
          await Server.notification.subscribe();
        }
      },

      loginWithGoogle: async (token: string) => {
        const response = await Server.auth.loginWithGoogle(token);
        if (response) {
          await fetchUser();
          await Server.notification.subscribe();
        }
      },

      logout: async () => {
        await Server.notification.unsubscribe();
        localStorage.removeItem('BearerToken');
        localStorage.removeItem('selectedGroupId');
        setIsLoggedIn(false);
        setGroupId(null);
        setUser(null);
      },

      refreshUser: async () => {
        await fetchUser();
      },

      checkIfLoggedIn: async () => {
        if (!isLoggedIn && localStorage.getItem('BearerToken') != null) {
          await fetchUser();
        }
        setIsLoading(false);
      },

      chooseGroup: (id: string) => {
        setGroupId(id);
        localStorage.setItem('selectedGroupId', id);
        const userGroup = user?.userGroups.find(
          (userGroup) => userGroup.groupId === id
        );
        if (userGroup?.role === UserRole.admin) {
          setIsGroupOwner(true);
        } else {
          setIsGroupOwner(false);
        }

        if (user && user.userLocks?.length > 0) {
          const activeLock = user.userLocks.find(
            (lock) =>
              lock.groupId === id && new Date(lock.lockExpiration) >= new Date()
          );

          if (activeLock) {
            setUserLock(activeLock);
          } else {
            setUserLock(null);
          }
        } else {
          setUserLock(null);
        }
      },
    }),
    [
      isLoggedIn,
      user,
      groupId,
      isGroupOwner,
      isLoading,
      userLock,
      fetchUser,
      theme,
      language,
    ]
  );

  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
};
