import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { UserDto } from '../../../../types/dto.types';
import { AuthContext } from '../../../../context/auth-context';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Flex, Form, Input, Modal, notification } from 'antd';
import { Server } from '../../../../server/server';

interface EditCredentialsProps {
  user: UserDto;
  isCredModalOpen: boolean;
  setIsCredModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditCredentials: FC<EditCredentialsProps> = (props) => {
  const { refreshUser } = useContext(AuthContext);
  const { user, isCredModalOpen, setIsCredModalOpen } = props;
  const { t } = useTranslation('profile');
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(user?.email);
  const [updatePasswordData, setUpdatePasswordData] = useState<{
    oldPassword: string;
    newPassword: string;
  }>({ oldPassword: '', newPassword: '' });

  const handlePasswordChange = (changedValues: any) => {
    if (changedValues.oldPassword) {
      setUpdatePasswordData((prev) => ({
        ...prev,
        oldPassword: changedValues.oldPassword,
      }));
    }
    if (changedValues.newPassword) {
      setUpdatePasswordData((prev) => ({
        ...prev,
        newPassword: changedValues.newPassword,
      }));
    }
  };

  const handleEmailChange = (changedValues: any) => {
    setEmail(changedValues.email.toLowerCase().trim());
  };

  const submitNewEmail = async () => {
    try {
      setLoading(true);
      const response = await Server.user.updateEmail({
        id: user.id,
        email: email.toLowerCase().trim(),
      });
      if (!response) {
        notification.success({ message: t('credentials.email_success') });
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
    setLoading(false);
    setIsCredModalOpen(false);
    refreshUser();
  };

  const submitNewPassword = async () => {
    try {
      setLoading(true);
      const response = await Server.user.updatePassword({
        id: user.id,
        ...updatePasswordData,
      });

      if (!response) {
        notification.success({ message: t('credentials.password_success') });
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
    setLoading(false);
    setIsCredModalOpen(false);
    refreshUser();
  };

  return (
    <Modal
      confirmLoading={loading}
      open={isCredModalOpen}
      footer={false}
      onCancel={() => {
        setIsCredModalOpen(false);
      }}
    >
      <Divider>{t('credentials.set_new_email')}</Divider>
      <Form
        name='change-email'
        className='change-cred-form'
        onValuesChange={handleEmailChange}
        onFinish={submitNewEmail}
        layout='vertical'
        initialValues={{ email }}
      >
        <Form.Item
          name='email'
          label={t('credentials.email')}
          rules={[{ type: 'email', message: t('credentials.email_invalid') }]}
          validateTrigger='onBlur'
        >
          <Input size='middle' className='mt-3' />
        </Form.Item>
        <Flex justify='end'>
          <Form.Item>
            <Button
              htmlType='submit'
              className='primary-button'
              loading={loading}
            >
              {t('submit').toUpperCase()}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
      <Divider>{t('credentials.set_new_password')}</Divider>
      <Form
        name='change-password'
        className='change-cred-form'
        onValuesChange={handlePasswordChange}
        onFinish={submitNewPassword}
        layout='vertical'
      >
        <Form.Item
          name='oldPassword'
          label={t('credentials.old_password')}
          rules={[{ min: 8, message: t('credentials.pass_invalid') }]}
          validateTrigger='onBlur'
        >
          <Input.Password size='middle' className='mt-3' />
        </Form.Item>
        <Form.Item
          name='newPassword'
          label={t('credentials.password')}
          rules={[{ min: 8, message: t('credentials.pass_invalid') }]}
          validateTrigger='onBlur'
        >
          <Input.Password size='middle' className='mt-3' />
        </Form.Item>
        <Form.Item
          name='confirm_password'
          label={t('credentials.confirm_password')}
          dependencies={['password']}
          rules={[
            {
              required: updatePasswordData.newPassword ? true : false,
              message: t('credentials.field_required'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('credentials.pass_no_match'))
                );
              },
            }),
          ]}
          validateTrigger='onBlur'
        >
          <Input.Password size='middle' className='mt-3' />
        </Form.Item>
        <Flex justify='end'>
          <Form.Item>
            <Button
              htmlType='submit'
              className='primary-button'
              loading={loading}
            >
              {t('submit').toUpperCase()}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};

export default EditCredentials;
